import { useEffect } from 'react';
import { Navigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { EditMassMessageView } from '../../../components/massMessage/EditMassMessageView';
import { FullPageLoading } from '../../../components/views/FullPageLoading';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import {
  MassMessageSourceTypeEnum,
  useMassMessageForm,
} from '../../../hooks/message/useMassMessageForm';
import { useAdminArtist } from '../../../hooks/useAdminArtist';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useOwnerOnlyAccess } from '../../../hooks/useOwnerOnlyAcess';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { useWindow } from '../../../hooks/useWindow';
import { LoginStatus } from '../../../types/authTypes';

export function CreateMassMessagePage() {
  const { artistHandle } = useArtistHandle();
  const { loginStatus } = useAuthContext();

  const [searchParams] = useSearchParams();

  const sourceId = searchParams.get('sourceId');
  const sourceType = searchParams.get('sourceType');
  const passedMessage = searchParams.get('m');

  const prefilledMessage = !!passedMessage ? decodeURIComponent(passedMessage) : null;

  const { isLg } = useWindow();

  const adminArtist = useAdminArtist({ artistHandle });

  const { clearErrors, clearFields, setField } = useMassMessageForm({
    vaultId: adminArtist?.artistMainVaultId,
  });

  useEffect(() => {
    if (prefilledMessage) {
      setField('message', prefilledMessage);
    }
  }, [prefilledMessage, setField]);

  useEffect(() => {
    if (sourceId == null || sourceType == null) {
      return;
    }

    const sourceTypeParsed = MassMessageSourceTypeEnum.safeParse(sourceType);

    if (sourceTypeParsed.success) {
      setField('sourceId', sourceId);
      setField('sourceType', sourceTypeParsed.data);
    }
  }, [sourceId, sourceType, setField]);

  useOwnerOnlyAccess();

  useVaultTheme();

  useEffect(() => {
    return () => {
      if (isLg) {
        clearErrors();
        clearFields();
      }
    };
  }, [clearErrors, clearFields, isLg]);

  if (loginStatus === LoginStatus.LOADING) {
    return <FullPageLoading withVaultTheme />;
  }

  if (!adminArtist || !artistHandle || loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} replace />;
  }

  return (
    <EditMassMessageView
      artistHandle={artistHandle}
      mainVaultId={adminArtist.artistMainVaultId}
      artistId={adminArtist.artistId}
      artistProfileImageUrl={adminArtist.artistProfileImage?.artistSmallProfileImageUrl ?? null}
      artistName={adminArtist.artistName}
      isEdit={false}
    />
  );
}
