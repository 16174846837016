import { gql } from '@soundxyz/gql-string';
import { fetchQuery, useQuery } from '../../graphql/client';
import type { ArtistMembershipReceiptTypenames } from '../../graphql/generated';
import {
  getFragment,
  LastMembershipReceiptDocument,
  MembershipReceiptFragmentDoc,
} from '../../graphql/generated';
import { AppleMusicConnectState } from '../appleMusic/useAppleMusicAuth';
import { SpotifyConnectState } from '../spotify/useSpotifyAuth';

gql(/* GraphQL */ `
  query LastMembershipReceipt(
    $artistHandle: String
    $receiptType: ArtistMembershipReceiptTypenames
    $releaseCampaignId: UUID
    $rsvpEventId: UUID
    $spotifyAuthCode: String
    $appleMusicUserToken: String
  ) {
    lastMembershipReceipt(
      artistHandle: $artistHandle
      receiptType: $receiptType
      releaseCampaignId: $releaseCampaignId
      rsvpEventId: $rsvpEventId
      spotifyAuthCode: $spotifyAuthCode
      appleMusicUserToken: $appleMusicUserToken
    ) {
      id
      __typename
      createdAt
      serialNumber
      ...MembershipReceipt
      ... on ArtistMembershipPlayStreamReceipt {
        releaseCampaign {
          id
          title
        }
      }
      ... on ArtistMembershipPresaveReceipt {
        releaseCampaign {
          id
          title
        }
      }
      ... on ArtistMembershipRsvpEventReceipt {
        rsvpEvent {
          id
          title
        }
      }
    }
  }
`);

type LastMembershipReceiptInputProps = {
  artistHandle: string | undefined;
  receiptType: ArtistMembershipReceiptTypenames | undefined;
  releaseCampaignId: string | undefined;
  rsvpEventId: string | undefined;
  source: 'apple' | 'spotify' | null;
  enabled: boolean;
  staleTime?: number;
};
export function useLastMembershipReceipt({
  artistHandle,
  receiptType,
  releaseCampaignId,
  rsvpEventId,
  source,
  enabled,
  staleTime = 0,
}: LastMembershipReceiptInputProps) {
  const spotifyAuth = SpotifyConnectState.useStore();
  const appleMusicAuth = AppleMusicConnectState.useStore();

  const { data, isLoading } = useQuery(LastMembershipReceiptDocument, {
    variables: {
      artistHandle,
      releaseCampaignId,
      rsvpEventId,
      receiptType,
      ...(source === 'spotify' && { spotifyAuthCode: spotifyAuth.value?.code }),
      ...(source === 'apple' && { appleMusicUserToken: appleMusicAuth.value?.userToken }),
    },
    enabled: enabled && ((!!releaseCampaignId && !!source) || !!rsvpEventId),
    staleTime,
  });

  const lastMembershipReceipt = getFragment(
    MembershipReceiptFragmentDoc,
    data?.data.lastMembershipReceipt,
  );

  const receiptId = lastMembershipReceipt?.id;

  return {
    data,
    isLoading,
    receiptId,
  };
}

export const queryLastMembershipReceipt = async ({
  artistHandle,
  receiptType,
  releaseCampaignId,
  rsvpEventId,
  source,
  staleTime = 0,
}: Omit<LastMembershipReceiptInputProps, 'enabled'>) => {
  const spotifyAuth = SpotifyConnectState.state.value?.code;
  const appleMusicAuth = AppleMusicConnectState.state.value?.userToken;

  const { data } = await fetchQuery(LastMembershipReceiptDocument, {
    variables: {
      artistHandle,
      releaseCampaignId,
      rsvpEventId,
      receiptType,
      ...(source === 'spotify' && { spotifyAuthCode: spotifyAuth }),
      ...(source === 'apple' && { appleMusicUserToken: appleMusicAuth }),
    },
    staleTime,
  });

  const receiptId = data.lastMembershipReceipt?.id;

  return {
    data,
    receiptId,
  };
};
