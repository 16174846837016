import { type FC, type SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';
import TextareaAutosize from 'react-textarea-autosize';
import type { VirtuosoHandle } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faGif } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPlusCircle } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faTrash } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPaperPlaneTop } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import type { Maybe } from '@soundxyz/utils';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { AudioAttachment } from '../../contexts/AudioRecordContext';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { ImagesAttachment } from '../../contexts/ImagesMessageAttachmentContext';
import { useToast } from '../../contexts/ToastContext';
import { VideoAttachment } from '../../contexts/VideoMessageAttachmentContext';
import { useQuery } from '../../graphql/client';
import type { TierTypename } from '../../graphql/generated';
import {
  GetBanStatusDocument,
  getFragment,
  MediaType,
  ReplyToMessageFragmentDoc,
} from '../../graphql/generated';
import { useSubscriberBanStatus } from '../../hooks/message/useSubscriberBanStatus';
import { useAudioRecorder } from '../../hooks/useAudioRecorder';
import { useBetterGate } from '../../hooks/useFeatureGate';
import { useSendMessage } from '../../hooks/useSendMessage';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useStopwatch } from '../../hooks/useStopwatch';
import type { TierFeatures } from '../../hooks/useTierFeatures';
import {
  ActiveArtistMessageChannel,
  setReplyToMessage,
  useReplyToMessage,
} from '../../hooks/useVaultMessageChannel';
import { WindowState } from '../../hooks/useWindow';
import { AttachTrackContentTitles } from '../../store/trackTitles';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { getFromList } from '../../utils/arrayUtils';
import { RecordButton } from '../audioRecorder/RecordButton';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { StyledInputContainer } from '../input/StyledInputContainer';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { ProfileImage } from '../user/ProfileImage';
import { RecordingWaveform } from '../waveform/RecordingWaveform';
import { MinimizedMessageAttachment } from './MinimizedMessageAttachment';

gql(/* GraphQL */ `
  mutation SendMessage($input: MutationCreateMessageInput!, $asArtistId: UUID) {
    createMessage(input: $input) {
      __typename
      ... on MutationCreateMessageSuccess {
        data {
          id
          ...messageBubble
        }
      }
      ... on Error {
        message
      }
    }
  }

  query GetBanStatus($artistId: UUID!) {
    getBanStatus(input: { artistId: $artistId })
  }
`);

const MAX_MESSAGE_LENGTH = 2000;

type Props = {
  messageChannel: { id: string };
  activeSubscriptionTier: TierTypename | null;
  isOwner: boolean;
  asArtist: {
    id: string;
    linkValue: string;
    name: string;
    profileImage: { id: string; artistSmallProfileImageUrl: string | null };
  } | null;
  gifButtonClick: () => void;
  vaultId?: string;
  artist: { id: string; name: string; linkValue: string };
  activeSubscriptionFeatures: TierFeatures;
  virtuosoRef: React.RefObject<VirtuosoHandle>;
  recipientId: Maybe<string>;
  hasUserLeftChannel: boolean | undefined;
};

const MessageTextInput: FC<Props> = ({
  vaultId,
  activeSubscriptionTier,
  asArtist,
  messageChannel,
  isOwner,
  gifButtonClick,
  artist,
  activeSubscriptionFeatures,
  virtuosoRef,
  recipientId,
  hasUserLeftChannel,
}) => {
  const { pathname } = useLocation();
  const htmlElRef = useRef<HTMLTextAreaElement>(null);
  const replyToMessageFrag = useReplyToMessage();
  const [searchParams] = useSearchParams();
  const [content, setContent] = useState('');
  const [attachedTrack, setAttachedTrack] = useState<string | null>(searchParams.get('track'));
  const { openToast } = useToast();
  const { openBottomsheet } = useBottomsheetContainer();
  const [shouldFocus, setShouldFocus] = useState(false);

  const replyToMessage = getFragment(ReplyToMessageFragmentDoc, replyToMessageFrag);
  const {
    video: messageVideo,
    isUploading: isUploadingVideo,
    clearVideo,
  } = useSnapshot(VideoAttachment);
  const {
    images: messageImages,
    isUploading: isUploadingImages,
    clearImages,
  } = useSnapshot(ImagesAttachment);
  const { startRecording, stopRecording } = useAudioRecorder(artist.id);

  const {
    recording: messageRecording,
    isRecording: isRecordingAudio,
    isUploading: isUploadingAudio,
    isProcessingAudioFile,
    clearRecording,
  } = useSnapshot(AudioAttachment);

  useEffect(() => {
    // Focus the input if the user is replying to a message
    if (replyToMessage) setShouldFocus(true);
  }, [replyToMessage]);

  const swipeableHandlers = useSwipeable({
    onSwipedDown: () => {
      htmlElRef.current?.blur();
    },
    onSwipedUp: () => {
      htmlElRef.current?.blur();
    },
    onTouchStartOrOnMouseDown: ({ event }) => {
      event.stopPropagation();
    },
  });

  const { sendMessage, isLoading: isSendingMessage } = useSendMessage({
    asArtist,
    messageChannelId: messageChannel.id,
    activeSubscriptionTier,
  });

  useEffect(() => {
    if (!isSendingMessage && shouldFocus && htmlElRef.current != null && WindowState.isDesktop) {
      htmlElRef.current?.focus();
      setShouldFocus(false);
    }
  }, [isSendingMessage, shouldFocus]);

  useEffect(() => {
    ActiveArtistMessageChannel.onNewMessage = ({ isOptimistic }) => {
      if (isOptimistic) {
        virtuosoRef.current?.scrollToIndex({ index: 0 });
      }
    };

    return () => {
      ActiveArtistMessageChannel.onNewMessage = null;
    };
  }, [virtuosoRef]);

  const { data } = useQuery(GetBanStatusDocument, {
    variables: !!artist.id && { artistId: artist.id },
    staleTime: 0,
  });

  const { loggedInUser } = useAuthContext();

  const adminArtist = useMemo(() => {
    return getFromList(
      loggedInUser?.adminArtists,
      adminArtist => adminArtist.artistId === artist.id && adminArtist,
    );
  }, [loggedInUser?.adminArtists, artist.id]);

  const hasImageAttachmentAccess =
    isOwner ||
    loggedInUser?.isOfficialVaultUser ||
    activeSubscriptionFeatures?.enabledFeatures.ChatSendImageAttachments === true;
  const hasVideoAttachmentAccess =
    isOwner ||
    loggedInUser?.isOfficialVaultUser ||
    activeSubscriptionFeatures?.enabledFeatures.ChatSendVideoAttachments === true;
  const hasAudioAttachmentAccess =
    isOwner ||
    loggedInUser?.isOfficialVaultUser ||
    activeSubscriptionFeatures?.enabledFeatures.ChatSendAudioAttachments === true;

  const createMediaAttachment = (
    mediaId: string | null | undefined,
    cdnUrl: string | null | undefined,
    mediaType: MediaType,
  ) => {
    if (mediaId && cdnUrl) {
      return {
        media: {
          id: mediaId,
          mediaType: mediaType,
          url: cdnUrl,
        },
      };
    }
    return null;
  };

  const onClick = useStableCallback(async (e?: SyntheticEvent) => {
    if (content.trim().length > MAX_MESSAGE_LENGTH) {
      openToast({
        text: `Messages cannot be more than ${MAX_MESSAGE_LENGTH} characters.`,
        variant: 'error',
      });
      return;
    }
    if (!messageChannel || !loggedInUser || isSendingMessage) return;
    e?.preventDefault();
    e?.stopPropagation();

    const messageAttachments = [
      ...messageImages
        .map(image => createMediaAttachment(image.mediaId, image.cdnUrl, MediaType.Image))
        .filter(Boolean),
      createMediaAttachment(messageVideo?.mediaId, messageVideo?.cdnUrl, MediaType.Video),
      createMediaAttachment(
        messageRecording?.mediaId,
        messageRecording?.cdnUrl,
        MediaType.Recording,
      ),
    ].filter(Boolean);

    sendMessage({
      content: content.trim(),
      vaultContent:
        attachedTrack != null
          ? [
              {
                id: attachedTrack,
                title: AttachTrackContentTitles.trackTitlesById[attachedTrack] || null,
              },
            ]
          : undefined,
      replyToMessage: replyToMessage,
      messageAttachments: messageAttachments,
      activeSubscriptionTier,
    });
    clearVideo();
    clearImages();
    clearRecording();
    setShouldFocus(true);
    setReplyToMessage(null);
    setContent('');
    setAttachedTrack(null);
  });

  const { data: artistViewBanned } = useSubscriberBanStatus({
    userId: recipientId,
    artistId: artist.id,
    enabled: isOwner,
  });

  // Don't add loading state to the input if the user is banned because
  // it results in a janky experience for most users
  const isBanned = isOwner ? !!artistViewBanned : !!data?.data?.getBanStatus;
  const isAudioRecording = isRecordingAudio || messageRecording;
  const KILLSWITCH_CHAT = useBetterGate('KILLSWITCH_CHAT') === 'enabled';

  const isButtonDisabled = () => {
    const hasContentOrTrack = content.trim().length > 0 || attachedTrack != null;
    const hasMediaAttachments = messageImages.length > 0 || messageVideo || messageRecording;
    return (
      isBanned ||
      isUploadingImages ||
      isUploadingVideo ||
      isUploadingAudio ||
      isRecordingAudio ||
      (!hasContentOrTrack && !hasMediaAttachments)
    );
  };

  const isRecordButtonAvailable =
    !KILLSWITCH_CHAT &&
    !isBanned &&
    vaultId != null &&
    !messageRecording &&
    content.length === 0 &&
    messageImages.length === 0 &&
    attachedTrack == null &&
    (isOwner || loggedInUser?.isOfficialVaultUser || hasAudioAttachmentAccess);

  const renderLeftButton = () => {
    if (isRecordingAudio) return null;

    return messageRecording ? (
      <Button
        label=""
        iconOnly
        leadingIcon={faTrash}
        leadingIconClassName="!text-base-m"
        className="mr-[8px] h-[40px] w-[40px] shrink-0 items-center justify-center rounded-full bg-vault_text/10 font-black text-vault_text"
        onClick={clearRecording}
        event={{
          type: EVENTS.REMOVE_ATTACH_AUDIO,
          properties: null,
        }}
      />
    ) : (
      <ProfileImage
        className="mr-[8px] h-[40px] w-[40px]"
        profileImageUrl={
          asArtist && isOwner
            ? asArtist?.profileImage?.artistSmallProfileImageUrl ||
              loggedInUser?.avatar.userSmallProfileImageUrl ||
              loggedInUser?.avatar.cdnUrl
            : loggedInUser?.avatar.userSmallProfileImageUrl || loggedInUser?.avatar.cdnUrl
        }
        onClick={undefined}
      />
    );
  };

  const handleStartRecording = () => {
    clearImages();
    clearVideo();
    setContent('');
    setAttachedTrack(null);
    startRecording();
  };

  const handleStopRecording = async () => {
    await stopRecording();
    clearImages();
    clearVideo();
    setContent('');
    setAttachedTrack(null);
  };

  useEffect(() => {
    // Cleanup function runs on unmount
    return () => {
      clearVideo();
      clearImages();
      clearRecording();
      setReplyToMessage(null);
      setAttachedTrack(null);
    };
  }, [clearImages, clearRecording, clearVideo]);

  const onRemoveBanClick = () => {
    if (!recipientId) return;
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.BAN_USER,
      shared: {
        withVaultTheme: true,
      },
      banUserBottomsheetProps: {
        artistId: artist.id,
        userId: recipientId,
        withVaultTheme: true,
      },
    });
  };

  if (!!hasUserLeftChannel) {
    return (
      <View
        className={twMerge(
          'box-border flex w-full flex-col items-center justify-center p-4',
          'border-0 border-t border-solid border-vault_text/5 bg-transparent',
        )}
      >
        <Text className="p-2 text-center text-[18px]/[22px] text-vault_text">
          User left your vault
        </Text>
        <Text className="p-2 text-center text-[16px]/[20px] text-vault_text/50">
          You cannot send new messages
        </Text>
      </View>
    );
  }

  if (isBanned) {
    return (
      <View
        className={twMerge(
          'box-border flex w-full flex-col items-center justify-center p-4',
          'border-0 border-t border-solid border-vault_text/5 bg-transparent',
        )}
      >
        <View
          className="p-2 text-center !text-base-s text-vault_text/50 md2:!text-base-m"
          role="status"
          aria-live="polite"
        >
          {isOwner ? (
            <>
              This user is currently banned.&nbsp;
              <button
                onClick={onRemoveBanClick}
                className="cursor-pointer appearance-none border-none bg-transparent text-destructive300 outline-none"
                aria-label="Remove ban from user"
                role="button"
              >
                Remove Ban
              </button>
            </>
          ) : (
            'You cannot send new messages'
          )}
        </View>
      </View>
    );
  }

  return (
    <View
      className={twMerge(
        'box-border flex w-full flex-col items-center justify-center p-4',
        attachedTrack != null && 'border-0 border-t-[1px] border-solid',
        'border-0 border-t border-solid border-vault_text/5 bg-transparent',
      )}
      onTouchDown={() => htmlElRef.current?.blur()}
    >
      {KILLSWITCH_CHAT && (
        <View className="p-2 !text-base-s text-vault_text/50">
          Sorry, chat is currently unavailable. Please try again later.
        </View>
      )}
      {attachedTrack != null && (
        <MinimizedMessageAttachment
          trackId={attachedTrack}
          onClose={() => {
            attachedTrack != null &&
              trackEvent({
                type: EVENTS.REMOVE_ATTACH_TRACK,
                properties: { trackId: attachedTrack },
                pathname,
              });
            setAttachedTrack(null);
          }}
        />
      )}
      <View className="flex w-full flex-row items-end justify-center">
        {!KILLSWITCH_CHAT && !isBanned && vaultId != null && (
          <>
            {renderLeftButton()}

            <StyledInputContainer
              className={twMerge(
                'mb-0 min-h-6 max-w-[calc(100%-80px)] flex-1 items-end overflow-hidden bg-vault_text/10 px-[16px] py-[8px]',
                content.includes(`\n`) || content.length > 24 ? 'rounded-xl' : 'rounded-full',
              )}
              swipeableHandlers={swipeableHandlers}
            >
              {isAudioRecording ? (
                <>
                  {messageRecording ? (
                    <RecordingWaveform
                      height={20}
                      audioUrl={messageRecording.cdnUrl || messageRecording.objectUrl}
                      peaks={messageRecording.peaks}
                      duration={messageRecording.duration}
                      isLoading={isUploadingAudio || isProcessingAudioFile}
                      variant="recording"
                    />
                  ) : (
                    <RecordingIndicator />
                  )}
                </>
              ) : (
                <>
                  <TextareaAutosize
                    className={twMerge(
                      'm-0 mb-[2px] h-5 flex-1 select-text resize-none border-none bg-transparent font-base !text-base-l font-normal focus:border-none focus:outline-none',
                      'text-vault_text placeholder:text-vault_text/50',
                    )}
                    value={content}
                    onChange={e => setContent(e.target.value)}
                    disabled={isBanned || isSendingMessage || KILLSWITCH_CHAT}
                    placeholder="Message"
                    maxRows={20}
                    ref={htmlElRef}
                    onKeyDown={e => {
                      if (isBrowser && e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        if (!isBanned && (content.trim().length > 0 || attachedTrack != null)) {
                          onClick();
                        }
                      }
                    }}
                  />
                  <View className="flex flex-row items-center gap-3">
                    {isRecordButtonAvailable && (
                      <RecordButton
                        className="ml-[8px] items-center justify-center"
                        filled={false}
                        isRecording={isRecordingAudio}
                        onStartRecording={handleStartRecording}
                        onStopRecording={handleStopRecording}
                      />
                    )}

                    {content.length == 0 && (
                      <Button
                        label=""
                        iconOnly
                        leadingIcon={faGif}
                        leadingIconClassName="text-[20px]"
                        className="h-6 w-6 text-vault_text"
                        onClick={gifButtonClick}
                      />
                    )}

                    <Button
                      label=""
                      iconOnly
                      leadingIcon={faPlusCircle}
                      leadingIconClassName="text-[22px]"
                      className="shrink-0 items-center justify-center font-black text-vault_text"
                      onClick={() => {
                        if (
                          isOwner ||
                          loggedInUser?.isOfficialVaultUser ||
                          hasImageAttachmentAccess ||
                          hasVideoAttachmentAccess
                        ) {
                          openBottomsheet({
                            type: BOTTOMSHEET_TYPES.ARTIST_MEDIA_ATTACHMENT,
                            shared: {
                              withVaultTheme: true,
                            },
                            artistMediaAttachmentBottomsheetProps: {
                              onAttachTrack: track => setAttachedTrack(track),
                              vaultId,
                              artist,
                              alreadyAttachedTrack: attachedTrack,
                              hasImageAttachmentAccess,
                              hasVideoAttachmentAccess,
                            },
                          });
                        } else {
                          openBottomsheet({
                            type: BOTTOMSHEET_TYPES.ADD_TRACK_ATTACHMENT,
                            addTrackAttachmentBottomsheetProps: {
                              onAttachTrack: track => setAttachedTrack(track),
                              vaultId,
                              alreadyAttachedTrack: attachedTrack,
                              artist,
                            },
                          });
                        }
                      }}
                      event={{
                        type: EVENTS.OPEN_BOTTOMSHEET,
                        properties: {
                          bottomsheetType:
                            isOwner || loggedInUser?.isOfficialVaultUser
                              ? BOTTOMSHEET_TYPES.ARTIST_MEDIA_ATTACHMENT
                              : BOTTOMSHEET_TYPES.ADD_TRACK_ATTACHMENT,
                          vaultId,
                          artistId: artist.id,
                        },
                      }}
                    />
                  </View>
                </>
              )}
            </StyledInputContainer>
          </>
        )}

        {isRecordingAudio && (
          <RecordButton
            className="ml-[8px] h-[40px] w-[40px] items-center justify-center rounded-full bg-vault_text/10 !text-base-l"
            isRecording={isRecordingAudio}
            onStartRecording={handleStartRecording}
            onStopRecording={handleStopRecording}
          />
        )}

        {!isButtonDisabled() && (
          <View className="ml-3 flex h-[40px] w-[32px] items-center justify-end">
            <Button
              onTouchDown={e => e?.stopPropagation()}
              label=""
              iconOnly
              leadingIcon={faPaperPlaneTop}
              className={twMerge(
                'h-[36px] w-[36px] shrink-0 items-center justify-center rounded-full p-0 text-[16px]/[20px] font-black',
                (isBanned || isSendingMessage) && 'bg-transparent',
              )}
              type="primary-themed"
              disabledClassName="hidden"
              onClick={onClick}
              disabled={isButtonDisabled()}
              loading={isSendingMessage}
              event={{
                type: EVENTS.SEND_MESSAGE,
                properties: {
                  channelId: messageChannel.id,
                  attachmentIds: attachedTrack != null ? [attachedTrack] : [],
                  contentLength: content.length,
                  isVaultArtist: adminArtist?.artistId === artist.id,
                  containsMessageRecording: messageRecording != null,
                  isReply: replyToMessage != null,
                  imageAttachmentsCount: messageImages.length,
                },
              }}
              hideIconWhenLoading
            />
          </View>
        )}
      </View>
    </View>
  );
};

const RecordingIndicator = () => {
  const { minutes, seconds } = useStopwatch({ autoStart: true });

  return (
    <View className="m-0 mb-0.5 flex h-5 flex-1 animate-pulse flex-row items-center justify-between gap-2">
      <View className="flex items-center gap-2">
        <View className="relative h-3 w-3 rounded-full bg-delete" />
        <Text className="!text-base-m text-vault_text/50">Recording...</Text>
      </View>

      <View>
        <Text className="!text-base-s tabular-nums text-vault_text/50">
          {minutes} : {seconds.toString().padStart(2, '0')}
        </Text>
      </View>
    </View>
  );
};

const SkeletonMessageTextInput = () => {
  return (
    <View
      className={twMerge(
        'box-border flex w-full flex-row p-4',
        'border-0 border-t border-solid border-vault_text/5 bg-vault_background md2:bg-transparent',
      )}
    >
      <LoadingSkeleton className="mr-2 h-10 w-10 rounded-full bg-vault_text/10" />
      <LoadingSkeleton className="flex min-h-6 flex-1 flex-row rounded-xl bg-vault_text/10 py-2 pl-4" />
    </View>
  );
};

export { MessageTextInput, SkeletonMessageTextInput };
