import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faChevronLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faMessages } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { PRIVACY_POLICY_URL, TOS_URL } from '../../constants/urlConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { constructQueryParams } from '../../utils/stringUtils';
import { Button } from '../buttons/Button';
import { View } from '../common/View';
import { AuthCardUI } from '../rsvp/RsvpDropView';
import { SignInForm, VerifyForm } from '../views/VaultLandingView';

export const JoinFreeLockedContent = ({
  view,
  setView,
  vaultId,
  linkValue,
  artistName,
  memberCardImage,
  className,
}: {
  view: 'join' | 'verify';
  setView: React.Dispatch<React.SetStateAction<'join' | 'verify'>>;
  vaultId: string | undefined;
  linkValue: string | undefined;
  artistName: string | undefined;
  memberCardImage?: string | null;
  className?: string;
}) => {
  const { loggedInUser, loginStatus } = useAuthContext();
  const { openBottomsheet } = useBottomsheetContainer();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const smsCampaignResponseShortcode = searchParams.get('c');
  const { isDesktop } = useWindow();

  const onJoinFreeClick = useCallback(async () => {
    if (!vaultId || !linkValue) return;

    if (loginStatus === LoginStatus.LOGGED_IN) {
      if (!isDesktop && !loggedInUser?.username) {
        return;
      }
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
        membershipConfirmationBottomsheetProps: {
          vaultId,
          isLoading: false,
          artistHandle: linkValue,
          artistName,
          imageUrl: memberCardImage,
          loggedInUserUsername: loggedInUser?.username,
          loginStatus,
          inviteCode: null,
          smsCampaignResponseShortcode,
          sourceReleaseCampaignId: null,
        },
        shared: {
          hideCloseBottomsheetButton: false,
          preventSwipeToDismiss: false,
          preventOutsideAutoClose: true,
          hidePulleyBar: true,
          withVaultTheme: true,
          isUnclosable: true,
        },
      });
    } else {
      const queryParams = constructQueryParams({
        artistHandle: linkValue,
        openBottomSheet: 'freeTierModal',
        c: smsCampaignResponseShortcode,
      });

      navigate(`${ROUTES.SIGN_IN}${queryParams ? `?${queryParams}` : ''}`);
      return;
    }
  }, [
    vaultId,
    linkValue,
    loginStatus,
    isDesktop,
    openBottomsheet,
    artistName,
    memberCardImage,
    loggedInUser?.username,
    smsCampaignResponseShortcode,
    navigate,
  ]);

  return (
    <div className={twMerge('flex overflow-hidden bg-vault_background ', className)}>
      <div className="flex w-full min-w-[320px] max-w-[340px] flex-col gap-5 bg-vault_text/10 p-5 md2:min-w-[360px]  md2:p-5">
        <div className="box-border flex w-full flex-row px-2">
          {view === 'verify' && (
            <Button
              label=""
              iconOnly
              leadingIcon={faChevronLeft}
              className="text-[20px] text-vault_text"
              onClick={() => setView('join')}
            />
          )}

          <View className="flex w-full flex-col items-center justify-center gap-4">
            <FontAwesomeIcon icon={faMessages} className="text-[40px]" />
            <p className="mx-auto text-center text-title-m font-medium md2:text-title-l">
              {view === 'join' ? 'Unlock messaging' : 'Verify'}
            </p>
          </View>

          {view === 'verify' && <div className="w-5" />}
        </div>

        {view === 'verify' && (
          <VerifyForm className="pb-0" codeInputOnly useVaultTheme noBottomSheet={!isDesktop} />
        )}

        {view === 'join' && (
          <>
            {loginStatus === LoginStatus.LOGGED_IN && loggedInUser ? (
              <AuthCardUI
                profileImageUrl={
                  loggedInUser.avatar.userSmallProfileImageUrl || loggedInUser.avatar.cdnUrl
                }
                username={loggedInUser.username}
                onClick={onJoinFreeClick}
                buttonLabel="Join"
                useVaultTheme
              />
            ) : (
              <>
                <SignInForm
                  dominantColor={null}
                  setView={setView}
                  useVaultTheme
                  inputBorderColorClassName="border-vault_text/15"
                  inputTextColorClassName="text-vault_text"
                  placeholderTextColorClassName="placeholder:text-vault_text/50"
                  selectBgColorClassName="bg-vault_background/90"
                  selectTextColorClassName="text-vault_text"
                />
              </>
            )}
          </>
        )}

        {view === 'join' && loginStatus === LoginStatus.LOGGED_OUT && (
          <span className="mx-auto w-full max-w-[200px] text-center font-base text-[12px]/[16px] font-normal text-vault_text/70">
            By signing up, you agree to the{' '}
            <a
              href={TOS_URL}
              target="_blank"
              className="text-vault_accent no-underline hover:cursor-pointer"
            >
              Terms
            </a>{' '}
            &{' '}
            <a
              href={PRIVACY_POLICY_URL}
              target="_blank"
              className="text-vault_accent no-underline hover:cursor-pointer"
            >
              Privacy Policy
            </a>
            .
          </span>
        )}
      </div>
    </div>
  );
};
