import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Virtuoso } from 'react-virtuoso';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import {
  ERROR_TYPE,
  FEATURES,
  NOTIFICATIONS_ERROR_ACTIONS,
  NOTIFICATIONS_INFO_ACTIONS,
} from '@soundxyz/vault-logs-utils/constants';
import { BOTTOMSHEET_TYPES } from '../../../constants/bottomsheetConstants';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { useToast } from '../../../contexts/ToastContext';
import { useInfiniteQuery, useMutation } from '../../../graphql/client';
import { RefetchOnComplete } from '../../../graphql/effects';
import type { OverrideNotificationRowFragmentDoc } from '../../../graphql/generated';
import {
  ArtistMessageChannelNotificationOverridesDocument,
  ArtistUserSettingsFeature,
  type FragmentType,
  MessageChannelInvolvementFilter,
  RemoveAllMessageChannelOverridesDocument,
  RemoveMessageChannelOverrideDocument,
} from '../../../graphql/generated';
import { useLogError } from '../../../hooks/logger/useLogError';
import { useLogInfo } from '../../../hooks/logger/useLogInfo';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { Button } from '../../buttons/Button';
import { ErrorView } from '../../error/ErrorView';
import { OverrideNotificationRow } from './OverridesRow';

gql(/* GraphQL */ `
  query ArtistMessageChannelNotificationOverrides(
    $artistHandle: String!
    $after: String
    $limit: Int
    $asArtistId: UUID
  ) {
    artistMessageChannelInvolvements(
      artistHandle: $artistHandle
      after: $after
      first: $limit
      asArtistId: $asArtistId
      filterType: WITH_NOTIFICATION_OVERRIDES
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          messageChannel {
            id
            details {
              titleText
            }

            ...OverrideNotificationRow
          }
        }
      }
    }
  }

  mutation RemoveAllMessageChannelOverrides(
    $input: MutationRemoveAllMessageChannelParticipantUserSettingsInput!
  ) {
    removeAllMessageChannelParticipantUserSettings(input: $input)
  }
`);

RefetchOnComplete({
  trigger: [RemoveMessageChannelOverrideDocument, RemoveAllMessageChannelOverridesDocument],
  refetch: [ArtistMessageChannelNotificationOverridesDocument],
});

const LIMIT = 15;

const feature = FEATURES.NOTIFICATIONS;
export const ArtistOverridesList = ({
  artistHandle,
  notificationType,
  asArtistId,
}: {
  artistHandle: string;
  notificationType: ArtistUserSettingsFeature;
  asArtistId?: string;
}) => {
  const { openToast } = useToast();
  const { openBottomsheet } = useBottomsheetContainer();
  const logError = useLogError();
  const logInfo = useLogInfo();
  const {
    orderedList: messageChannelOverrides,
    loadMoreNextPage,
    hasNextPage,
    isLoadingNewPage,
    isInitialLoading,
    isError,
    refetch,
  } = useInfiniteQuery(ArtistMessageChannelNotificationOverridesDocument, {
    staleTime: 0,
    filterQueryKey: {
      artistHandle,
      notificationType,
      asArtistId,
      filterType: MessageChannelInvolvementFilter.WithNotificationOverrides,
    },
    getNextPageParam({ data: { artistMessageChannelInvolvements } }) {
      return (
        artistMessageChannelInvolvements.pageInfo.hasNextPage && {
          after: artistMessageChannelInvolvements.pageInfo.endCursor,
        }
      );
    },
    variables({ pageParam }) {
      return {
        artistHandle,
        after: pageParam?.after,
        asArtistId,
        limit: LIMIT,
      };
    },
    list({ artistMessageChannelInvolvements }) {
      return artistMessageChannelInvolvements.edges
        .map(({ node }) => node.messageChannel)
        .filter(({ details }) => !!details);
    },
    uniq: ({ id }) => id,
    enabled: notificationType === ArtistUserSettingsFeature.DmFromUser,
    keepPreviousData: false,
  });

  const { mutateAsync: removeAllMessageChannelOverrides, isLoading: removingAllOverrides } =
    useMutation(RemoveAllMessageChannelOverridesDocument, {});

  const onRemoveAll = useStableCallback(async () => {
    if (!asArtistId) {
      return;
    }

    try {
      const deleted = await removeAllMessageChannelOverrides({
        input: { artistId: asArtistId },
      });

      if (!deleted.data.removeAllMessageChannelParticipantUserSettings) {
        logError({
          error: new Error('Failed to remove all overrides'),
          level: 'warning',
          errorType: ERROR_TYPE.MUTATION_ERROR,
          message: 'Failed to remove all overrides',
          toast: 'Failed to remove all overrides',
          openToast,
          indexedTags: {
            asArtistId,
            feature,
          },
          action: NOTIFICATIONS_ERROR_ACTIONS.NOTIFICATION_SETTINGS_ERROR,
          feature,
        });
        return;
      } else {
        logInfo({
          action: NOTIFICATIONS_INFO_ACTIONS.UPDATE_NOTIFICATION_SETTINGS,
          message: 'All overrides removed',
          feature,
          data: {
            artistId: asArtistId,
          },
        });
        return;
      }
    } catch (error) {
      logError({
        error: new Error('Failed to remove all overrides'),
        level: 'warning',
        errorType: ERROR_TYPE.UNKNOWN,
        message: 'Failed to remove all overrides',
        toast: 'Failed to remove all overrides',
        openToast,
        indexedTags: {
          asArtistId,
          feature,
        },
        action: NOTIFICATIONS_ERROR_ACTIONS.NOTIFICATION_SETTINGS_ERROR,
        feature,
      });
    }
  });

  const onRemoveAllPress = useStableCallback(() => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.CONFIRMATION,
      confirmationBottomsheetProps: {
        title: 'Remove all overrides?',
        subText: 'Are you sure you want to remove all overrides? This action is not reversible',
        confirmButtonText: 'Remove all',
        cancelButtonText: 'Cancel',
        onConfirm: onRemoveAll,
      },
    });
  });
  const renderItem = useStableCallback(
    (
      _i: number,
      rowData: FragmentType<OverrideNotificationRowFragmentDoc> & {
        id: string;
      },
    ) => {
      return (
        <div className="flex w-full items-center overflow-hidden py-2.5">
          <OverrideNotificationRow rowData={rowData} asArtistId={asArtistId} />
        </div>
      );
    },
  );

  const Header = useStableCallback(() => (
    <div className="flex items-center justify-between space-x-3">
      <div className="flex flex-col space-y-1">
        <h2 className="font-title text-title-m font-medium text-white">Overrides</h2>
        <p className="max-w-[320px] font-base text-base-m text-white/50">
          Settings for the below chats will override your default preferences.
        </p>
      </div>
      <Button
        label={removingAllOverrides ? 'Removing...' : 'Remove all'}
        disabled={removingAllOverrides}
        onClick={onRemoveAllPress}
        type="secondary"
        className="h-fit w-fit whitespace-nowrap rounded-full border-none bg-base700 px-3 py-2 font-base text-base-m font-medium leading-snug text-destructive300 outline-none md2:text-base-l"
      />
    </div>
  ));

  const memoizedFooter = useStableCallback(() =>
    hasNextPage && isLoadingNewPage ? (
      <div className="flex w-full items-center justify-center py-5">
        <FontAwesomeIcon
          icon={faSpinner}
          size="2xl"
          className="ml-2 inline-block animate-spin rounded-full font-medium text-vault_text/50"
        />
      </div>
    ) : (
      <div className="h-4" />
    ),
  );

  const EmptyState = useStableCallback(() => {
    if (isInitialLoading) {
      return (
        <div className="flex h-full w-full flex-col ">
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={`artist-notif-${index}`} className="py-2.5">
              <div className="flex h-[56px] w-full animate-pulse items-center justify-between gap-3 rounded-md bg-base800/80" />
            </div>
          ))}
        </div>
      );
    }

    if (isError) {
      return (
        <div className="flex h-[calc(100vh-400px)] w-full flex-col items-center justify-center gap-6 ">
          <ErrorView
            className="flex-grow"
            onRetryClick={() => {
              refetch();
            }}
            loggingType="artist_overrides_list"
            withVaultTheme={false}
          />
        </div>
      );
    }

    return null;
  });

  if (!messageChannelOverrides || messageChannelOverrides.length === 0) return null;

  return (
    <>
      <hr className="mb-4 h-[1px] w-full border-0 bg-base700" />
      <section className="w-full space-y-3">
        <Virtuoso
          className="no-scrollbar "
          data={messageChannelOverrides}
          endReached={loadMoreNextPage}
          computeItemKey={(_, data) => data.id}
          itemContent={renderItem}
          increaseViewportBy={700}
          useWindowScroll
          components={{ Header, Footer: memoizedFooter, EmptyPlaceholder: EmptyState }}
        />
      </section>
    </>
  );
};
