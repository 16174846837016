import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faChevronRight } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import {
  ArtistNotificationRowFragmentDoc,
  type FragmentType,
  getFragment,
} from '../../../graphql/generated';
import { ArtistProfileImage } from '../../artist/ArtistProfileImage';

gql(/* GraphQL */ `
  fragment ArtistNotificationRow on VaultSubscription {
    id
    artist {
      id
      linkValue
      name
      profileImage {
        id
        url: imageOptimizedUrl(input: { width: 100, height: 100 })
      }
    }
  }
`);

export const ArtistNotificationRow = ({
  artistData,
  isManagedVault = false,
}: {
  artistData: FragmentType<ArtistNotificationRowFragmentDoc>;
  isManagedVault?: boolean;
}) => {
  const {
    artist: { name, profileImage, linkValue },
  } = getFragment(ArtistNotificationRowFragmentDoc, artistData);

  return (
    <Link
      className="flex h-[60px] max-h-[60px] w-full cursor-pointer appearance-none items-center justify-between gap-2 border-none bg-base800  px-4 no-underline transition-all duration-200 ease-in-out hover:bg-base800/80 focus:outline-none  focus:ring-2 focus:ring-base800/80 focus-visible:border-none focus-visible:outline-none"
      to={`/settings/artist-notification/${linkValue}`}
      aria-label={`View notification for ${name}`}
    >
      <div className="flex items-center gap-2.5">
        <ArtistProfileImage profileImageUrl={profileImage?.url} className="h-7 w-7" />
        <div className="flex max-w-[200px] flex-col space-y-1">
          <span className="overflow-hidden overflow-ellipsis whitespace-nowrap font-title text-title-s font-normal text-white">
            {name}
          </span>
          {isManagedVault && (
            <span className="font-base text-base-s font-normal text-white/40">Managed Vault</span>
          )}
        </div>
      </div>
      <FontAwesomeIcon icon={faChevronRight} className="h-3.5 w-3.5 text-white/40" fontSize={14} />
    </Link>
  );
};
