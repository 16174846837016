import { type FC, useMemo } from 'react';
import Picker, { type EmojiClickData, Theme } from 'emoji-picker-react';
import { motion } from 'framer-motion';
import { keyBy } from 'lodash-es';
import { useLocation, useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faCopy, faReply, faTrashCan } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { convertEmojiToKeyword } from '../../constants/emojis';
import { THUMBTACK } from '../../constants/imageConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { MessageReactionTypeInput } from '../../graphql/generated';
import { makeFragmentData, ReplyToMessageFragmentDoc } from '../../graphql/generated';
import { type FragmentType, getFragment, MessageBubbleFragmentDoc } from '../../graphql/generated';
import { useMessageActions } from '../../hooks/message/useMessageActions';
import { useCopy } from '../../hooks/useCopy';
import { useStableCallback } from '../../hooks/useStableCallback';
import { setReplyToMessage, useVaultMessageChannel } from '../../hooks/useVaultMessageChannel';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { Button } from '../buttons/Button';
import { View } from '../common/View';
import { MessageBubbleInner } from './MessageBubble';

type ElevatedMessageBubbleProps = {
  messageFrag: FragmentType<MessageBubbleFragmentDoc> & { id: string };
  areSubscriptionTierBadgesVisible: boolean;
  isOwner: boolean;
  isAuthor: boolean;
  artistProfileImageUrl: string | null | undefined;
  artistLinkValue: string | undefined;
  parentType: 'default' | 'see_details';
  isVaultArtist: boolean;
  isGroupChat: boolean;
};

export const ElevatedMessageBubble: FC<ElevatedMessageBubbleProps> = ({
  messageFrag,
  areSubscriptionTierBadgesVisible,
  isOwner,
  isAuthor,
  artistProfileImageUrl,
  artistLinkValue,
  parentType,
  isVaultArtist,
  isGroupChat,
}) => {
  const { pathname } = useLocation();

  const { closeOverlay, isAnimationComplete } = useOverlayContainer();
  const { loggedInUser } = useAuthContext();
  const navigate = useNavigate();

  const adminArtist = useMemo(() => {
    if (!artistLinkValue || !isOwner) return undefined;

    return loggedInUser?.adminArtists?.find(adminArtist =>
      adminArtist.artistLinks.includes(artistLinkValue.toLowerCase()),
    );
  }, [artistLinkValue, isOwner, loggedInUser?.adminArtists]);

  const {
    deleteMessage,
    isDeleting,
    pinMessage,
    isPinning,
    removePinnedMessage,
    isRemovingPin,
    createMessageReaction,
    deleteMessageReaction,
  } = useMessageActions({ messageId: messageFrag.id });

  const message = getFragment(MessageBubbleFragmentDoc, messageFrag);
  const { id, pinnedPriority, myReactions, createdAt, content } = message;

  const { reactionUpdate, removeMessage } = useVaultMessageChannel();

  const { copy } = useCopy({
    text: content,
    successMessage: 'Message copied!',
  });

  const onMessageCopy = useStableCallback(async () => {
    await copy();
    closeOverlay();
  });

  const onDeleteClick = useStableCallback(async () => {
    async function execute() {
      const { revert } = removeMessage({ messageId: id });

      await deleteMessage({
        onSuccess: () => {
          closeOverlay();
        },
        onError: () => {
          revert();
        },
        toast: {
          text: (
            <p>
              This message could not be deleted at this time.{' '}
              <span
                className="cursor-pointer !text-base-m font-semibold underline"
                onClick={() => {
                  trackEvent({
                    type: EVENTS.RETRY,
                    properties: { type: 'delete_message', component: 'toast' },
                    pathname,
                  });

                  execute();
                }}
              >
                Try again.
              </span>
            </p>
          ),
          variant: 'error',
        },
      });
    }

    execute();
    closeOverlay();
  });

  const onPinClick = useStableCallback(() => {
    if (pinnedPriority == null) {
      pinMessage({
        onSuccess: () => {
          closeOverlay();
        },
      });
    } else {
      removePinnedMessage({
        onSuccess: () => {
          closeOverlay();
        },
      });
    }
  });

  const myReactionsObj = keyBy(myReactions, v => v.type + v.emojiKeyword);

  const onMessageReactionClick = useStableCallback(async (reaction: EmojiClickData) => {
    const emojiKeyword = convertEmojiToKeyword(reaction.emoji);
    const userId = loggedInUser?.id;
    if (!userId) return;

    const execute = async () => {
      if (myReactionsObj[MessageReactionTypeInput.Emoji + emojiKeyword] == null) {
        const { revert } = reactionUpdate({
          created: true,
          id,
          reactionType: MessageReactionTypeInput.Emoji,
          userId,
          reactionTotalCount: null,
          isArtistReaction: isOwner,
          asArtistId: adminArtist?.artistId || null,
          emojiKeyword,
        });

        await createMessageReaction({
          input: {
            messageId: message.id,
            reactionType: MessageReactionTypeInput.Emoji,
            asArtistId: adminArtist?.artistId || null,
            emojiKeyword,
          },
          onSuccess: () => {
            closeOverlay();
          },
          onError: () => {
            revert();
          },
          toast: {
            text: (
              <p>
                This message could not be reacted to at this time.{' '}
                <span
                  className="cursor-pointer !text-base-m font-semibold underline"
                  onClick={() => {
                    trackEvent({
                      type: EVENTS.RETRY,
                      properties: { type: 'create_reaction', component: 'toast' },
                      pathname,
                    });

                    execute();
                  }}
                >
                  Try again.
                </span>
              </p>
            ),
            variant: 'error',
          },
        });
      } else {
        const { revert } = reactionUpdate({
          created: false,
          id,
          reactionType: 'EMOJI',
          userId,
          reactionTotalCount: null,
          isArtistReaction: isOwner,
          asArtistId: adminArtist?.artistId || null,
          emojiKeyword,
        });
        await deleteMessageReaction({
          input: {
            messageId: message.id,
            reactionType: MessageReactionTypeInput.Emoji,
            asArtistId: adminArtist?.artistId || null,
            emojiKeyword,
          },
          onSuccess: () => {
            closeOverlay();
          },
          onError: () => {
            revert();
          },
          toast: {
            text: (
              <p>
                This message reaction could not be removed at this time.{' '}
                <span
                  className="cursor-pointer !text-base-m font-semibold underline"
                  onClick={() => {
                    trackEvent({
                      type: EVENTS.RETRY,
                      properties: { type: 'delete_reaction', component: 'toast' },
                      pathname,
                    });

                    execute();
                  }}
                >
                  Try again.
                </span>
              </p>
            ),
            variant: 'error',
          },
        });
      }
    };

    execute();
  });

  const items = [
    {
      label: 'Reply',
      icon: faReply,
      className: 'text-white',
      action: () => {
        setReplyToMessage(
          makeFragmentData(
            {
              id: message.id,
              content: message.content,
              createdAt: message.createdAt,
              vaultContent: message.vaultContent,
              messageAttachments: message.messageAttachments,
              activeSubscriptionTier: message.activeSubscriptionTier,
              creator: message.creator,
            },
            ReplyToMessageFragmentDoc,
          ),
        );
        closeOverlay();
        if (parentType === 'see_details') {
          navigate(-1);
        }
      },
      event: {
        type: EVENTS.SET_REPLY_TO_MESSAGE,
        properties: {
          messageId: id,
          type: 'elevated',
        },
      },
    },
    message.content && {
      label: 'Copy',
      icon: faCopy,
      className: 'text-white',
      action: () => onMessageCopy(),
      event: {
        type: EVENTS.COPY_MESSAGE,
        properties: { messageId: id },
      },
    },
    isOwner &&
      isGroupChat && {
        label: pinnedPriority == null ? 'Pin' : 'Unpin',
        image: THUMBTACK,
        className: 'text-white',
        isLoading: isPinning || isRemovingPin,
        action: onPinClick,
        event: {
          type: pinnedPriority == null ? EVENTS.PIN_MESSAGE : EVENTS.UNPIN_MESSAGE,
          properties: { messageId: id },
        },
      },
    (isAuthor || isOwner || loggedInUser?.isAdmin) && {
      label: 'Delete',
      icon: faTrashCan,
      className: 'text-delete',
      isLoading: isDeleting,
      action: onDeleteClick,
      event: {
        type: EVENTS.DELETE_MESSAGE,
        properties: {
          messageId: id,
          timeActive: Math.abs(Date.now() - new Date(createdAt).getTime()),
        },
      },
    },
  ].filter(Boolean);
  const translateY = 48 * items.length + 4;

  const animationVariants = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <>
      {isAnimationComplete && (
        <motion.div
          variants={animationVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.2 }}
          className={twMerge(
            isAuthor ? 'right-0 mx-4' : 'left-0',
            'absolute z-above2 flex -translate-y-14',
          )}
        >
          <Picker
            theme={Theme.DARK}
            style={{
              border: 'none',
            }}
            skinTonesDisabled
            reactionsDefaultOpen
            onReactionClick={onMessageReactionClick}
            onEmojiClick={onMessageReactionClick}
            emojiVersion="11.0"
            reactions={['2764-fe0f', '1f44d', '1f525', '1f923']} // Emoji universal code for heart, thumbs up, fire, and laughing emoji
            width={300}
            autoFocusSearch={false}
          />
        </motion.div>
      )}

      <View className="flex max-w-[67vw] select-none flex-col items-end md:max-w-[420px]">
        <MessageBubbleInner
          message={messageFrag}
          isAuthor={isAuthor}
          type="elevated"
          artistProfileImageUrl={artistProfileImageUrl}
          areSubscriptionTierBadgesVisible={areSubscriptionTierBadgesVisible}
          isVaultArtist={isVaultArtist}
        />
      </View>
      {isAnimationComplete && (
        <motion.div
          variants={animationVariants}
          initial="initial"
          animate="animate"
          exit="exit"
          transition={{ duration: 0.2 }}
          style={{ transform: `translateY(${translateY}px)` }}
          className={twMerge(
            isAuthor ? 'right-0' : 'left-0',
            'z-10 absolute bottom-0 flex flex-col rounded-lg bg-base700',
          )}
        >
          {items.map((item, index) => (
            <Button
              key={item.label}
              label={item.label}
              trailingImage={item.image}
              imageSize={16}
              trailingIcon={item.icon}
              onClick={item.action}
              className={twMerge(
                'h-[45px] w-[200px] select-none justify-between px-4 font-base text-[16px]/[20px] font-normal text-white',
                index !== items.length - 1 &&
                  'border-0 border-b-[0.5px] border-solid border-[rgba(255,255,255,0.2)]',
                item.className,
              )}
              hideIconWhenLoading
              loading={item.isLoading}
              disabled={item.isLoading}
              event={item.event}
            />
          ))}
        </motion.div>
      )}
    </>
  );
};
