import { useCallback } from 'react';
import { memoize } from 'lodash-es';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../contexts/AuthContext';
import { type ExecutionResultWithData, useQuery } from '../../graphql/client';
import {
  GetSubscribersBanStatusDocument,
  type GetSubscribersBanStatusQuery,
} from '../../graphql/generated';
import { createBatchingInputStore } from '../../utils/batchInput';
import { useAdminArtist } from '../useAdminArtist';

gql(/* GraphQL */ `
  query GetSubscribersBanStatus($input: QueryGetArtistSubscribersBanStatusInput!) {
    getArtistSubscribersBanStatus(input: $input) {
      isBanned
      userId
    }
  }
`);

const batchedInputStore = memoize((_artistId: string) =>
  createBatchingInputStore({
    chunkLimit: 50,
  }),
);

export function useSubscriberBanStatus({
  userId,
  artistId,
  enabled = true,
}: {
  userId: string | null | undefined;
  artistId: string | null | undefined;
  enabled?: boolean;
}) {
  const { loggedInUser } = useAuthContext();

  const artistAdmin = useAdminArtist(artistId ? { artistId } : undefined);

  const { useBatchedKey } = batchedInputStore(artistId || '_');

  const userIds = useBatchedKey({
    key: userId,
  });

  return useQuery(GetSubscribersBanStatusDocument, {
    filterQueryKey: {
      artistId,
    },
    variables: !!artistId &&
      !!userIds && {
        input: {
          userIds,
          artistId,
        },
      },
    enabled: !!loggedInUser && !!artistAdmin && enabled,
    staleTime: 0,
    keepPreviousData: true,
    select: useCallback(
      (data: ExecutionResultWithData<GetSubscribersBanStatusQuery>) =>
        data.data.getArtistSubscribersBanStatus.find(({ userId: id }) => id === userId)?.isBanned,
      [userId],
    ),
  });
}
