import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { type Control, Controller, type FieldValues, type Path } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

export function ToggleRow<T extends FieldValues>({
  label,
  control,
  name,
}: {
  label: string;
  control: Control<T>;
  name: Path<T>;
}) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <Toggle
          className="text-vault_text"
          componentClassName="bg-vault_text/10 after:bg-vault_accent_text peer-checked:bg-vault_accent"
          label={label}
          checked={value}
          onChange={onChange}
          ref={ref}
        />
      )}
    />
  );
}

export const Toggle = React.forwardRef<
  HTMLInputElement,
  {
    label: string;
    componentClassName?: string;
    subLabel?: string;
    subLabelClassName?: string;
  } & React.InputHTMLAttributes<HTMLInputElement>
>(
  (
    { label, className, componentClassName, subLabel, subLabelClassName, ...props },
    forwardedRef,
  ) => {
    return (
      <label
        className={twMerge(
          'inline-flex w-full cursor-pointer items-center justify-between',
          className,
        )}
      >
        <span className="relative flex flex-1 flex-col font-medium">
          {label}
          <AnimatePresence>
            {!!subLabel && (
              <motion.span
                className={twMerge(
                  'absolute top-5 line-clamp-1 w-full text-[12px]/[14.52px] font-normal',
                  subLabelClassName,
                )}
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                exit={{ opacity: 0 }}
              >
                {subLabel}
              </motion.span>
            )}
          </AnimatePresence>
        </span>
        <input
          type="checkbox"
          value=""
          className="peer absolute h-0 w-0 opacity-0"
          ref={forwardedRef}
          {...props}
        />
        <div
          className={twMerge(
            'relative h-6 w-11 rounded-full bg-[#787880]',
            // white toggle button
            "after:absolute after:start-[2px] after:top-0.5 after:h-5 after:w-5 after:rounded-full after:border after:bg-white after:transition-all after:content-['']",

            // style based on checked state
            'peer peer-checked:bg-yellow100 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full',
            'peer-checked:after:shadow-[0_0px_1px_0_rgba(0,0,0,0.25),0_4px_11px_0_rgba(0,0,0,0.08),-1px_3px_3px_0_rgba(0,0,0,0.14)]',
            'peer-checked:after:ring-0 peer-checked:after:ring-offset-0',
            componentClassName,
          )}
        />
      </label>
    );
  },
);

Toggle.displayName = 'Toggle';
