import React, { type FC } from 'react';
import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useLocation, useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { faAngleLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { EVENTS } from '../../types/eventTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { Button } from './Button';

type Props = {
  className?: string;
  onClick?: () => void;
  onBack?: () => void;
  href?: string;
  icon?: IconProp;
  withVaultTheme?: boolean;
};

export const BackButton: FC<Props> = ({
  className,
  onClick,
  onBack,
  href,
  icon = faAngleLeft,
  withVaultTheme,
}) => {
  const { key } = useLocation();
  const { artistHandle } = useArtistHandle();

  const navigate = useNavigate();
  const goBack = () => {
    if (key !== 'default') {
      navigate(-1);
    } else {
      navigate(artistHandle != null ? artistNavigationPath(artistHandle, '/') : '/');
    }

    onBack?.();
  };
  return (
    <Button
      iconOnly
      label="Back"
      leadingIcon={icon}
      className={twMerge(
        'select-none text-[24px] text-white outline-none',
        withVaultTheme && 'text-vault_text',
        className,
      )}
      onClick={href == null ? onClick ?? goBack : undefined}
      href={href}
      event={{ type: EVENTS.BACK, properties: { type: 'default' } }}
    />
  );
};
