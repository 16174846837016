import { useEffect, useRef } from 'react';
import React from 'react';
import { delay, isEqual } from 'lodash-es';
import { proxy, useSnapshot } from 'valtio';
import { gql } from '@soundxyz/gql-string';
import { PlayerType, useAudioController } from '../../audio/AudioController';
import { AudioQueue, shuffleTracks } from '../../audio/AudioQueue';
import { type ExecutionResultWithData, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  ArtistByHandleDocument,
  CreateVaultContentDocument,
  GetTrackIdsToPopulateQueueDocument,
  type GetTrackIdsToPopulateQueueQuery,
  RemoveVaultContentsDocument,
  VaultContentQueueSortBy,
  VaultTrackIdsDocument,
} from '../../graphql/generated';
import { VaultBottomAudioPlayer } from '../audioPlayer/BottomAudioPlayer';
import { VaultFullscreenAudioPlayer } from '../audioPlayer/FullscreenAudioPlayer';

gql(/* GraphQL */ `
  query VaultTrackIds($vaultId: UUID!) {
    vaultFromId(vaultId: $vaultId) {
      id
      trackIds
    }
  }

  query GetTrackIdsToPopulateQueue($input: QueryGetTrackIdsToPopulateAudioQueueInput!) {
    getTrackIdsToPopulateAudioQueue(input: $input)
  }
`);

RefetchOnComplete({
  refetch: [CreateVaultContentDocument, RemoveVaultContentsDocument, ArtistByHandleDocument],
  trigger: [VaultTrackIdsDocument],
});

const FullScreenAudioPlayerState = proxy({
  isClosingFullscreen: false,
  isFullscreenAudioOpen: false,
  isBottomAudioPlayerOpen: false,
});

export const useFullScreenAudioPlayerState = () => useSnapshot(FullScreenAudioPlayerState);

export function openFullscreenPlayer() {
  FullScreenAudioPlayerState.isFullscreenAudioOpen = true;
}

export function closeFullScreen() {
  FullScreenAudioPlayerState.isClosingFullscreen = true;
  FullScreenAudioPlayerState.isFullscreenAudioOpen = false;

  delay(() => {
    FullScreenAudioPlayerState.isClosingFullscreen = false;
  }, 100);
}

export function openBottomAudioPlayer() {
  FullScreenAudioPlayerState.isBottomAudioPlayerOpen = true;
}

export function closeBottomAudioPlayer() {
  FullScreenAudioPlayerState.isBottomAudioPlayerOpen = false;
}

const AudioPlayer = ({
  withBottomNavigator,
  withVaultTheme,
}: {
  withBottomNavigator: boolean;
  withVaultTheme: boolean;
}) => {
  const { isClosingFullscreen, isFullscreenAudioOpen } = useFullScreenAudioPlayerState();
  const { track, hideAudioPlayer, activeVaultId } = useAudioController();
  const lastValidTrack = useRef(track);

  useEffect(() => {
    if (track) {
      lastValidTrack.current = track;
    }
  }, [track]);

  const audioPlayerType = useSnapshot(PlayerType).current;

  const { data: vaultTrackIds } = useQuery(GetTrackIdsToPopulateQueueDocument, {
    variables: !!activeVaultId && {
      input: {
        vaultId: activeVaultId,
        parentVaultContentId: null, //TODO: Re add when want folders to be self contained. isFoldersEnabled ? activeFolderId : null,
        sortBy: VaultContentQueueSortBy.FolderPosition,
      },
    },
    select: (data: ExecutionResultWithData<GetTrackIdsToPopulateQueueQuery>) =>
      data.data.getTrackIdsToPopulateAudioQueue,
    staleTime: 0,
  });

  useEffect(() => {
    if (!isEqual(vaultTrackIds, AudioQueue.vaultTrackIds)) {
      AudioQueue.vaultTrackIds = vaultTrackIds || [];
      AudioQueue.playbackTrackIds = vaultTrackIds || [];

      shuffleTracks();

      return;
    }
  }, [vaultTrackIds]);

  const currentTrack = track || lastValidTrack.current;

  if (hideAudioPlayer || !currentTrack) {
    return null;
  }

  return audioPlayerType === 'vault' ? (
    isFullscreenAudioOpen || isClosingFullscreen ? (
      <VaultFullscreenAudioPlayer
        content={currentTrack}
        closeFullScreen={closeFullScreen}
        isClosingFullscreen={isClosingFullscreen}
        withVaultTheme={withVaultTheme}
      />
    ) : (
      <VaultBottomAudioPlayer
        content={currentTrack}
        withBottomNavigator={withBottomNavigator}
        withVaultTheme={withVaultTheme}
      />
    )
  ) : null;
};

export { AudioPlayer };
