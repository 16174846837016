import { Link, useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faRightToBracket } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { normalizePath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { maskPhoneNumber } from '../../utils/textUtils';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { ProfileImage } from '../user/ProfileImage';

export const MenuUser = ({
  selectedHandle,
  closeAll,
}: {
  selectedHandle?: string | null;
  closeAll: () => void;
}) => {
  const { loggedInUser, loginStatus } = useAuthContext();
  const [searchParams] = useSearchParams();
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');

  const withVaultTheme = !!selectedHandle;

  const userProfileImageUrl =
    loggedInUser?.avatar.userSmallProfileImageUrl || loggedInUser?.avatar.cdnUrl;
  const userUsername = loggedInUser?.displayName ?? loggedInUser?.username;
  const userSubText = !!userUsername ? `@${userUsername}` : maskPhoneNumber(loggedInUser?.phone);

  return loggedInUser != null ? (
    <UserRow
      avatarUrl={userProfileImageUrl}
      username={userUsername}
      subText={userSubText}
      withVaultTheme={withVaultTheme}
      closeAll={closeAll}
    />
  ) : loginStatus === LoginStatus.LOADING ? (
    <View className="mb-3 flex w-full flex-row items-center justify-between rounded-xl">
      <View className="flex flex-row">
        <LoadingSkeleton
          className={twMerge(
            'mr-4 aspect-square w-12 rounded-full',
            withVaultTheme && 'bg-vault_text/10',
          )}
        />
        <View className="flex flex-col justify-center overflow-hidden transition-all ease-in-out">
          <LoadingSkeleton
            className={twMerge('h-4 w-[60px]', withVaultTheme && 'bg-vault_text/10')}
          />
        </View>
      </View>
      <LoadingSkeleton
        className={twMerge(
          'h-4 w-[60px] overflow-hidden pl-0 transition-all ease-in-out',
          withVaultTheme && 'bg-vault_text/10',
        )}
      />
    </View>
  ) : (
    <>
      <View>
        <Button
          href={`${ROUTES.SIGN_IN}?${constructQueryParams({
            artistHandle: selectedHandle,
            invite,
            c: smsCampaignResponseShortcode,
            openBottomSheet: selectedHandle ? 'freeTierModal' : undefined,
          })}`}
          onClick={closeAll}
          className={twMerge(
            'mb-4 w-full justify-center rounded-full px-7 py-3 font-title !text-[16px]/[20px] font-medium md2:ml-2 md2:items-center md2:p-3',
            withVaultTheme ? 'bg-vault_text text-vault_text_opposite' : 'bg-white text-black',
          )}
          label="Log in"
          linkClassName="w-full"
          leadingIcon={faRightToBracket}
          labelClassName={twMerge('w-full overflow-hidden transition-all ease-in-out')}
        />
      </View>
    </>
  );
};

const UserRow = ({
  avatarUrl,
  username,
  subText,
  withVaultTheme,
  closeAll,
}: {
  avatarUrl: string | null | undefined;
  username: string | null | undefined;
  subText: string | null | undefined;
  withVaultTheme: boolean;
  closeAll: () => void;
}) => {
  const { isDesktop } = useWindow();

  const row = (
    <View
      className={twMerge(
        'flex items-center gap-3 py-3 pl-3 md2:pl-2',
        withVaultTheme ? 'text-vault_text/50 hover:text-vault_text' : 'text-white',
      )}
    >
      <ProfileImage
        profileImageUrl={avatarUrl}
        className={twMerge('h-8 w-8 rounded-full', withVaultTheme && 'text-vault_text/30')}
        onClick={undefined}
      />
      <View className="flex flex-col gap-0.5 overflow-hidden">
        <View className="flex items-center gap-1">
          <Text className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap font-base text-[14px] font-medium">
            {username}
          </Text>
        </View>
        {!isDesktop && (
          <View className="flex gap-1">
            {!!subText && (
              <Text
                className={twMerge(
                  'max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap font-base text-[12px] font-medium',
                  withVaultTheme ? 'text-vault_text/50' : 'text-white/50',
                )}
              >
                {subText}
              </Text>
            )}
          </View>
        )}
      </View>
    </View>
  );

  return (
    <Link
      className="cursor-pointer no-underline hover:opacity-80"
      to={normalizePath(`${ROUTES.SETTINGS}/profile`)}
      onClick={() => {
        trackEvent({
          type: EVENTS.MENU_NAVIGATE,
          properties: { type: 'settings' },
          pathname: location.pathname,
        });

        closeAll();
      }}
    >
      {row}
    </Link>
  );
};
