export const FEATURE_GATES = {
  KILLSWITCH_CHAT: 'killswitch_vault_chat',
  KILLSWITCH_SUBSCRIPTION: 'killswitch_vault_subscription',
  SPOTIFY_CONNECT: 'vault_spotify_connect',
  SPOTIFY_REVIEW_PRESAVE: 'vault_spotify_review_presave',
  VAULT_APPLE_MUSIC_CONNECT: 'vault_apple_music_connect',
  PRESAVES: 'vault_presaves',
  STREAMS: 'vault_streams',
  TEXT_BLAST_UPDATES: 'vault_text_blast_updates_web',
  AUTH_ARTIST_USER: 'vault_auth-artist-user-web',
  GROUP_CHAT_MESSAGE_PUSH_NOTIF: 'vault_group_chat_user_push_notifications_web',
} as const satisfies Record<string, string>;

export const FEATURE_GATE_OVERRIDES: Record<keyof typeof FEATURE_GATES, boolean | undefined> = {
  KILLSWITCH_CHAT: undefined,
  KILLSWITCH_SUBSCRIPTION: undefined,
  SPOTIFY_CONNECT: undefined,
  SPOTIFY_REVIEW_PRESAVE: undefined,
  VAULT_APPLE_MUSIC_CONNECT: undefined,
  PRESAVES: undefined,
  STREAMS: undefined,
  TEXT_BLAST_UPDATES: undefined,
  AUTH_ARTIST_USER: undefined,
  GROUP_CHAT_MESSAGE_PUSH_NOTIF: undefined,
};
