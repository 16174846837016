import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBellSlash } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useToast } from '../../../contexts/ToastContext';
import { useMutation } from '../../../graphql/client';
import {
  type FragmentType,
  getFragment,
  OverrideNotificationRowFragmentDoc,
  RemoveMessageChannelOverrideDocument,
} from '../../../graphql/generated';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { Button } from '../../buttons/Button';
import { ProfileImage } from '../../user/ProfileImage';

gql(/* GraphQL */ `
  mutation RemoveMessageChannelOverride(
    $input: MutationRemoveMessageChannelParticipantUserSettingsInput!
  ) {
    removeMessageChannelParticipantUserSettings(input: $input)
  }

  fragment OverrideNotificationRow on MinimalMessageChannel {
    id
    channelType
    details {
      titleText
      isPushNotificationEnabled
      isSmsEnabled
      coverImage {
        id
        cdnUrl
        url: imageOptimizedUrl(input: { width: 200, height: 200 })
      }
    }
  }
`);

export const OverrideNotificationRow = ({
  rowData,
  asArtistId,
}: {
  rowData: FragmentType<OverrideNotificationRowFragmentDoc>;
  asArtistId?: string;
}) => {
  const { openToast } = useToast();
  const { details, id: messageChannelId } = getFragment(
    OverrideNotificationRowFragmentDoc,
    rowData,
  );
  if (!details) return null;

  const { mutateAsync: removeMessageChannelOverride, isLoading } = useMutation(
    RemoveMessageChannelOverrideDocument,
    {
      onSuccess: () => {
        openToast({
          text: `Removed notification override for ${details.titleText} `,
          variant: 'success',
        });
      },
    },
  );

  const onRemovePress = useStableCallback(() => {
    if (!asArtistId) {
      return;
    }

    removeMessageChannelOverride({
      input: { actorId: asArtistId, channelId: messageChannelId },
    });
  });
  return (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center space-x-3">
        <ProfileImage
          profileImageUrl={details.coverImage?.url || details.coverImage?.cdnUrl}
          className="h-14 w-14"
          onClick={undefined}
        />
        <div className="flex flex-col space-y-1">
          <h2 className="max-w-[200px] overflow-hidden overflow-ellipsis whitespace-nowrap font-base text-base-l text-base50">
            {details.titleText}
          </h2>
          <div className="flex items-center gap-x-1">
            <FontAwesomeIcon
              icon={details.isPushNotificationEnabled ? faBell : faBellSlash}
              className="text-white/60"
              fontSize={12}
            />
            <span className="font-base text-base-m text-white/60">
              {details?.isPushNotificationEnabled ? 'Always on' : 'Always off'}
            </span>
          </div>
        </div>
      </div>
      <Button
        type="secondary"
        label="Remove"
        onClick={onRemovePress}
        loading={isLoading}
        className="h-fit w-fit whitespace-nowrap rounded-full border-none bg-base700 px-3 py-2 font-base text-base-m font-medium leading-snug text-white outline-none md2:text-base-l"
      />
    </div>
  );
};
