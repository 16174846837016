import { useEffect, useState } from 'react';
import { Navigate, useParams } from 'react-router';
import * as z from 'zod';
import { gql } from '@soundxyz/gql-string';

import { isUUID4 } from '@soundxyz/utils';
import { setHideAudioPlayer } from '../audio/AudioMeta';
import { BackButton } from '../components/buttons/BackButton';
import { View } from '../components/common/View';
import { ErrorView } from '../components/error/ErrorView';
import { ListDetailLayout } from '../components/layouts/ListDetailLayout';
import { UserChannels } from '../components/messages/UserChannels';
import { SeeDetailsMessageChannelView } from '../components/views/SeeDetailsMessageChannelView';
import { useAuthContext } from '../contexts/AuthContext';
import { useQuery } from '../graphql/client';
import { GetMessageChannelDetailsDocument, MessageChannelType } from '../graphql/generated';
import { useArtistHandle } from '../hooks/useArtistHandle';
import { useActiveSubscriptionFeatures } from '../hooks/useTierFeatures';
import { useVaultTheme } from '../hooks/useVaultTheme';
import { useWindow } from '../hooks/useWindow';
import { LoginStatus } from '../types/authTypes';
import { artistNavigationPath } from '../utils/navigationUtils';
import { PersistenceStorage } from '../utils/storeUtils';

gql(/* GraphQL */ `
  query GetMessageChannelDetails($input: QueryMessageChannelInput!) {
    messageChannel(input: $input) {
      __typename

      ... on QueryMessageChannelSuccess {
        data {
          channelType
          artist {
            id
            isAuthUserAdmin
            name
            linkValue
            profileImage {
              id
              artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
            }
            mainVaultId
          }
          vault {
            id
            isUserArtistAdmin
            activeSubscription {
              id
              ...ActiveSubscriptionFeatures
            }
            artistProfile {
              id
              name
              isAuthUserAdmin
            }
          }
          ...SeeDetailsHeader
        }
      }
    }
  }
`);

export const TabSchema = z.enum(['artist', 'pinned', 'attachments']);
export type SeeDetailsTab = z.infer<typeof TabSchema>;
export const DetailsTabStore = PersistenceStorage({
  schema: z.record(z.string(), TabSchema),
  key: 'detailsTab',
  eager: true,
});

export const SeeDetailsMessageChannelPage = () => {
  const { loginStatus } = useAuthContext();
  const [isAtTop, setIsAtTop] = useState(true);
  const { isDesktop } = useWindow();

  const { channelId } = useParams();
  const { artistHandle } = useArtistHandle();

  useVaultTheme();

  const queryEnabled =
    (artistHandle != null || channelId != null) && loginStatus === LoginStatus.LOGGED_IN;

  const { data, isLoading, isError, refetch } = useQuery(GetMessageChannelDetailsDocument, {
    variables: {
      input:
        !!channelId && isUUID4(channelId)
          ? { messageChannelId: channelId }
          : artistHandle
            ? { artistHandle: { handle: artistHandle, type: MessageChannelType.Vault } }
            : {},
    },
    enabled: queryEnabled,
    staleTime: 0,
    select: data => {
      if (data.data.messageChannel?.__typename !== 'QueryMessageChannelSuccess') return null;
      return data.data.messageChannel.data;
    },
  });

  useEffect(() => {
    if (!isDesktop) {
      setHideAudioPlayer(true);
    }

    return () => {
      if (!isDesktop) {
        setHideAudioPlayer(false);
      }
    };
  }, [isDesktop]);

  const isOwner = data?.artist?.isAuthUserAdmin || data?.vault?.isUserArtistAdmin;
  const artistId = data?.artist?.id || data?.vault?.artistProfile?.id;
  const artistName = data?.artist?.name || data?.vault?.artistProfile?.name;
  const vaultId = data?.artist?.mainVaultId || data?.vault?.id;
  const isGroupChat = data?.channelType === MessageChannelType.Vault;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: data?.vault?.activeSubscription,
    isOwner: !!isOwner,
  });
  const detailsTabStore = DetailsTabStore.useStore().value;

  const tab: SeeDetailsTab = isGroupChat
    ? detailsTabStore?.[artistHandle || '_'] || 'artist'
    : 'attachments';

  const errorView = (
    <ErrorView
      onRetryClick={refetch}
      loggingType="SeeDetailsMessageChannelPage"
      className="flex-1"
      withVaultTheme
    />
  );

  const mainView = (
    <SeeDetailsMessageChannelView
      isHeaderLoading={isLoading || loginStatus === LoginStatus.LOADING}
      messageChannel={data ?? null}
      tab={tab}
      artistHandle={artistHandle}
      isAtTop={isAtTop}
      setIsAtTop={setIsAtTop}
    />
  );

  if (loginStatus !== LoginStatus.LOADING && data == null && !isLoading) {
    return <Navigate to={artistNavigationPath(artistHandle, '/chat')} />;
  }

  const backHref = artistNavigationPath(
    artistHandle,
    isGroupChat ? (isDesktop ? '/messages' : '/chat') : `/messages/${channelId}`,
  );

  return (
    <ListDetailLayout>
      <UserChannels
        vaultId={vaultId}
        artistHandle={artistHandle}
        asArtistId={isOwner ? artistId : undefined}
        showOnMobile={false}
        artistName={artistName}
        artistCoverImage={data?.artist?.profileImage?.artistSmallProfileImageUrl}
        activeSubscription={!!activeSubscriptionFeatures}
      />

      <ListDetailLayout.Detail showOnMobile>
        {isError && artistHandle != null ? (
          errorView
        ) : (
          <View className="flex min-h-full w-full flex-1 flex-col justify-start">
            <BackButton
              className="
              pl-5 pt-[31px]
              md2:self-start md2:pl-6 md2:pt-9"
              withVaultTheme
              href={backHref}
            />
            {mainView}
          </View>
        )}
      </ListDetailLayout.Detail>
    </ListDetailLayout>
  );
};
