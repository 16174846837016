import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import {
  faFiles,
  faMobile,
  faTicket,
  faWaveformLines,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import type { JoinVaultBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ProfileImage } from '../user/ProfileImage';

export function JoinVaultBottomsheet({
  artistHandle,
  buttonTitle,
  joinType,
  title,
  vaultArtistProfileImage,
  onConfirm,
}: JoinVaultBottomsheetProps) {
  const { closeBottomsheet } = useBottomsheetContainer();

  const items =
    joinType === 'free'
      ? [
          {
            icon: faWaveformLines,
            text: 'Listen to unreleased music',
            className: 'text-vault_text',
          },
          {
            icon: faMobile,
            text: 'Text updates for new drops',
            className: 'text-vault_text',
          },
          {
            icon: faTicket,
            text: 'Early access to shows and merch',
            className: 'text-vault_text',
          },
        ]
      : [
          {
            icon: faWaveformLines,
            text: 'Unlimited access to full-length songs',
            className: 'text-vault_text',
          },
          {
            icon: faTicket,
            text: 'Early access to tour presales & merch drop',
            className: 'text-vault_text',
          },
          {
            icon: faFiles,
            text: 'Exclusive content from the artist',
            className: 'text-vault_text',
          },
        ];

  const renderContent = () => {
    return (
      <View className="flex flex-col justify-center gap-4 py-2">
        {items.map((item, index) => (
          <View key={index} className="flex flex-row items-center gap-4">
            <FontAwesomeIcon
              icon={item.icon}
              className={twMerge('text-[16px]/[20px]', item.className)}
            />
            <Text className={twMerge('font-base text-[16px]/[20px] font-normal', item.className)}>
              {item.text}
            </Text>
          </View>
        ))}
      </View>
    );
  };
  return (
    <View className="box-border flex w-full flex-col items-center gap-7 px-4 pb-4">
      <View className="flex flex-col items-center justify-center gap-[18px]">
        <ProfileImage
          profileImageUrl={vaultArtistProfileImage}
          className="h-20 w-20 rounded-full"
          onClick={undefined}
        />
        <Text className="font-title text-[20px]/[22px] font-medium text-vault_text">{title}</Text>
      </View>

      {renderContent()}

      <Button
        label={buttonTitle}
        type="primary"
        onClick={() => {
          closeBottomsheet();
          if (onConfirm) onConfirm();
        }}
        className="bg-vault_accent text-vault_accent_text"
        event={{
          type: joinType ? EVENTS.CONT_TO_SUBSCRIBE : EVENTS.CONT_TO_JOIN_FREE_TIER,
          properties: { artistHandle },
        }}
      />
    </View>
  );
}
