import { produce } from 'immer';
import { proxy, useSnapshot } from 'valtio';
import * as z from 'zod';
import { gql } from '@soundxyz/gql-string';
import type { OnData } from '@soundxyz/graphql-react-ws';
import { DEFAULT_AVATAR } from '../constants/imageConstants';
import { useAuthContext } from '../contexts/AuthContext';
import { useInfiniteQuery } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  getFragment,
  GetUserChannelsDocument,
  makeFragmentData,
  MessageChannelInvolvementFilter,
  MessageChannelInvolvementsSubscriptionDocument,
  MessageChannelType,
  SendMessageDocument,
  SortDirection,
  UserChannelRowFragmentDoc,
} from '../graphql/generated';
import { useSubscription } from '../graphql/wsClient';
import { LoginStatus } from '../types/authTypes';
import { useStableCallback } from './useStableCallback';

const filtersSchema = z.object({
  sort: z.nativeEnum(SortDirection),
  filterType: z.nativeEnum(MessageChannelInvolvementFilter),
});

export type FiltersSchema = z.infer<typeof filtersSchema>;

export const initialFilters = {
  sort: 'DESC',
  filterType: 'ALL',
} satisfies FiltersSchema;

export const filtersState = proxy<FiltersSchema>(initialFilters);

export const resetFilters = () => {
  filtersState.sort = initialFilters.sort;
  filtersState.filterType = initialFilters.filterType;
};

gql(/* GraphQL */ `
  subscription MessageChannelInvolvementsSubscription($artistHandle: String!, $asArtistId: UUID) {
    messageChannelInvolvementUpdates(artistHandle: $artistHandle, asArtistId: $asArtistId) {
      __typename
      ... on SubscriptionMessageChannelInvolvementUpdatesSuccess {
        data {
          __typename
          ... on ReadyMessageSubscription {
            now
          }
          ... on NewInvolvedMessageSubscription {
            channelId
            subscriptionTierLevel
            titleText
            subtitleText
            receiptCount
            showVerifiedBadge
            coverImage {
              cdnUrl
              smallCoverImageUrl: optimizedUrl(input: { width: 200, height: 200 })
            }
            messageId
            messageContent
            channelType
            userId
            artistId
            messageCreatedAt
          }
          ... on DeletedInvolvedMessageSubscription {
            channelId
            deletedAt
            messageId
            newLastMessage {
              messageId
              messageContent
              messageCreatedAt
            }
          }
        }
      }
    }
  }

  fragment UserChannelRow on MessageChannelInvolvement {
    hasUnreadMessages
    messageChannel {
      id
      latestMessage {
        id
        content
        createdAt
      }
      createdAt
      channelType
      artist {
        id
      }

      details {
        singleRecipientActorId
        receiptCount
        subscriptionTierLevel
        subtitleText
        titleText
        showVerifiedBadge
        isPushNotificationEnabled
        isSmsEnabled
        isPushNotificationEnabledForUserMessagesInGroupChat
        coverImage {
          id
          cdnUrl
          smallCoverImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
      }
    }
  }

  query GetUserChannels(
    $after: String
    $artistHandle: String!
    $asArtistId: UUID
    $filterType: MessageChannelInvolvementFilter!
    $first: Int!
    $sort: SortDirection!
  ) {
    artistMessageChannelInvolvements(
      after: $after
      artistHandle: $artistHandle
      asArtistId: $asArtistId
      filterType: $filterType
      first: $first
      sort: $sort
    ) {
      edges {
        cursor
        node {
          messageChannel {
            id
            channelType
          }
          ...UserChannelRow
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`);

export function useMessageChannelInvolvementsSubscription({
  artistHandle,
  asArtistId,
  onSubscriptionData,
}: {
  artistHandle: string | null | undefined;
  asArtistId: string | null | undefined;
  onSubscriptionData: OnData<typeof MessageChannelInvolvementsSubscriptionDocument>;
}) {
  return useSubscription({
    query: MessageChannelInvolvementsSubscriptionDocument,
    variables: !!artistHandle && {
      artistHandle,
      asArtistId,
    },
    onData: onSubscriptionData,
  });
}

const LIMIT = 20;

// Update the list of channels when a message is sent to allow new channels to be displayed
RefetchOnComplete({
  trigger: [SendMessageDocument],
  refetch: [GetUserChannelsDocument],
});

export function useUserChannels({
  limit,
  sort: passedSort,
  filterType: passedFilterType,
  artistHandle,
  asArtistId,
}: {
  limit?: number;
  sort?: SortDirection;
  filterType?: MessageChannelInvolvementFilter;
  artistHandle: string | undefined;
  asArtistId: string | undefined;
}) {
  const { loginStatus } = useAuthContext();

  const { sort, filterType } = useSnapshot(filtersState);

  const enabled = loginStatus === LoginStatus.LOGGED_IN && !!artistHandle;

  const {
    orderedList: channels,
    hasNextPage,
    isInitialLoading,
    isError,
    isLoadingError,
    isFetchingNextPage,
    refetch,
    fetchNextPage,
    entityStore,
  } = useInfiniteQuery(GetUserChannelsDocument, {
    enabled,
    staleTime: 0,
    cacheTime: 0,
    filterQueryKey: {
      sort: passedSort ?? sort,
      filterType: passedFilterType ?? filterType,
      asArtistId,
      artistHandle,
    },
    getNextPageParam: ({ data }) => {
      return (
        data.artistMessageChannelInvolvements.pageInfo.hasNextPage && {
          after: data.artistMessageChannelInvolvements.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!enabled &&
      (({ pageParam }) => {
        return {
          after: pageParam?.after ?? null,
          first: limit ?? LIMIT,
          sort: passedSort ?? sort,
          filterType: passedFilterType ?? filterType,
          artistHandle,
          asArtistId,
        };
      }),
    list: ({ artistMessageChannelInvolvements }) => {
      return artistMessageChannelInvolvements.edges.map(({ node }) => node);
    },
    uniq: ({ messageChannel }) => messageChannel.id,
  });

  useMessageChannelInvolvementsSubscription({
    artistHandle,
    asArtistId,
    onSubscriptionData: useStableCallback(async data => {
      if (
        data.data.messageChannelInvolvementUpdates.__typename !==
        'SubscriptionMessageChannelInvolvementUpdatesSuccess'
      ) {
        return;
      }
      const { data: subscriptionData } = data.data.messageChannelInvolvementUpdates;
      if (subscriptionData.__typename === 'ReadyMessageSubscription') {
        return;
      }

      if (subscriptionData.__typename === 'DeletedInvolvedMessageSubscription') {
        const { channelId, messageId, newLastMessage } = subscriptionData;

        const involvement = entityStore.nodes[channelId];

        if (involvement == null) {
          return;
        }

        entityStore.nodes[channelId] = produce(involvement, draft => {
          const involvement = getFragment(UserChannelRowFragmentDoc, draft);

          if (
            involvement.messageChannel.latestMessage == null ||
            involvement.messageChannel.latestMessage.id !== messageId
          ) {
            return;
          }

          involvement.messageChannel.latestMessage = {
            id: newLastMessage?.messageId ?? messageId,
            content: newLastMessage?.messageContent ?? '[message deleted]',
            createdAt:
              newLastMessage?.messageCreatedAt ??
              involvement.messageChannel.latestMessage.createdAt,
          };
        });
      } else if (subscriptionData.__typename === 'NewInvolvedMessageSubscription') {
        const {
          channelId,
          coverImage,
          receiptCount,
          showVerifiedBadge,
          subscriptionTierLevel,
          subtitleText,
          titleText,
          messageId,
          messageContent,
          channelType,
          userId,
          artistId,
          messageCreatedAt: createdAt,
        } = subscriptionData;

        const involvement = entityStore.nodes[channelId];

        if (involvement == null) {
          const involvement = makeFragmentData(
            {
              messageChannel: {
                id: channelId,
                channelType,
                createdAt,
                latestMessage: {
                  id: messageId,
                  content: !!messageContent ? messageContent : 'Sent an attachment',
                  createdAt,
                },
                artist: { id: artistId },
                details: {
                  isPushNotificationEnabled: false,
                  isSmsEnabled: false,
                  isPushNotificationEnabledForUserMessagesInGroupChat: null,
                  singleRecipientActorId: userId,
                  receiptCount,
                  showVerifiedBadge,
                  subscriptionTierLevel,
                  subtitleText,
                  titleText,
                  coverImage:
                    coverImage != null
                      ? {
                          id: '-1',
                          smallCoverImageUrl: coverImage.smallCoverImageUrl,
                          cdnUrl: coverImage.cdnUrl ?? DEFAULT_AVATAR,
                        }
                      : null,
                },
              },
              hasUnreadMessages: true,
            },
            UserChannelRowFragmentDoc,
          );
          entityStore.nodes[channelId] = {
            messageChannel: {
              id: channelId,
              channelType: MessageChannelType.ArtistDm,
              ...involvement,
            },
          };
          return;
        }

        entityStore.nodes[channelId] = produce(involvement, draft => {
          const involvement = getFragment(UserChannelRowFragmentDoc, draft);

          involvement.messageChannel.latestMessage = {
            id: messageId,
            content: !!messageContent ? messageContent : 'Sent an attachment',
            createdAt,
          };

          involvement.hasUnreadMessages = true;
        });
      }
    }),
  });

  return {
    fetchNextPage,
    hasNextPage,
    isError,
    isFetchingNextPage,
    isInitialLoading,
    isLoadingError,
    refetch,
    channels,
  };
}
