import { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { uuidv4 } from '@soundxyz/utils';
import {
  EarnedReceiptViewFragmentDoc,
  type FragmentType,
  getFragment,
  type MembershipReceiptFragment,
  MembershipReceiptFragmentDoc,
} from '../../../graphql/generated';
import { useLastMembershipReceipt } from '../../../hooks/campaign/useLastMembershipReceipt';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { ONE_MINUTE_IN_MS } from '../../../utils/dateUtils';
import { Image } from '../../common/Image';
import { View } from '../../common/View';
import { LoadingSkeleton } from '../../loading/LoadingSkeleton';
import { SpotifyBottomAudioPlayer } from '../../spotify/SpotifyAudioPlayer';
import { UserPlaceholderImage } from '../../user/UserPlaceholderImage';
import { EarnedView } from './EarnedView';
import { PlayView } from './PlayView';

gql(/* GraphQL */ `
  fragment EarnedReceiptView on ReleaseCampaignPublicInfo {
    id
    title
    linkValue
    currentState
    spotifyResourceId
    appleMusicResourceId
    contentType
    coverImage {
      id
      campaignCoverImageUrl: imageOptimizedUrl
    }
    initialReleaseImageUrl
    artist {
      id
      name
      linkValue
      profileImage {
        id
        artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      }
    }
    externalLinks {
      id
      url
      platform
    }
  }

  mutation ReleaseCampaignReportPlayStream($input: MutationReportPlayStreamReleaseCampaignInput!) {
    reportPlayStreamReleaseCampaign(input: $input) {
      __typename
      ... on MutationReportPlayStreamReleaseCampaignSuccess {
        data {
          id
          ...MembershipReceipt
        }
      }

      ... on Error {
        message
      }
    }
  }

  query GetReceiptNumber($input: QueryReceiptTypeCountInput!) {
    receiptTypeCount(input: $input) {
      __typename
      ... on QueryReceiptTypeCountSuccess {
        data
      }
      ... on Error {
        message
      }
    }
  }
`);

export function EarnedReceiptView({
  campaignFrag,
  artistData,
  step,
  sourceParam,
}: {
  campaignFrag: FragmentType<EarnedReceiptViewFragmentDoc>;
  artistData?: {
    id: string | null | undefined;
    membershipImage: string | null | undefined;
    linkValue: string | null | undefined;
    name: string | null | undefined;
  };
  step: string | null;
  sourceParam: 'apple' | 'spotify' | null;
}) {
  const { artistHandle } = useArtistHandle();
  const campaign = getFragment(EarnedReceiptViewFragmentDoc, campaignFrag);

  const coverImageUrl =
    campaign?.coverImage?.campaignCoverImageUrl || campaign?.initialReleaseImageUrl;

  const randomUUID = useMemo(() => uuidv4(), []);

  const [source, setSource] = useState<'apple' | 'spotify' | null>(sourceParam);
  const [receiptData, setReceiptData] = useState<MembershipReceiptFragment | null>(null);

  const { data: lastReceiptData, isLoading } = useLastMembershipReceipt({
    artistHandle,
    releaseCampaignId: campaign.id,
    source,
    receiptType: undefined,
    rsvpEventId: undefined,
    enabled: true,
    staleTime: ONE_MINUTE_IN_MS * 2, // If we call this too quickly after, it will get the same new receipt we just got
  });

  const lastMembershipReceipt = getFragment(
    MembershipReceiptFragmentDoc,
    lastReceiptData?.data.lastMembershipReceipt,
  );

  const background = useMemo(() => {
    return (
      <>
        {coverImageUrl != null ? (
          <Image
            src={coverImageUrl}
            alt="Blurred Track/Album Cover"
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-75 blur-2xl filter"
          />
        ) : (
          <UserPlaceholderImage
            id={randomUUID}
            className="absolute inset-0 z-base h-full w-full overflow-hidden object-cover opacity-75 blur-2xl filter"
          />
        )}

        <View
          className={twMerge(
            'absolute bottom-0 z-base hidden h-full w-full bg-gradient-to-b from-transparent to-black md2:block',
          )}
        />
        <View
          className={twMerge(
            'absolute bottom-0 z-base h-full w-full bg-gradient-to-b from-transparent via-black/80 to-black md2:hidden',
          )}
        />
      </>
    );
  }, [coverImageUrl, randomUUID]);

  const content = useMemo(() => {
    if (step === 'view')
      return <PlayView campaign={campaign} setReceiptData={setReceiptData} setSource={setSource} />;
    if (step === 'claim')
      return (
        <EarnedView
          campaign={campaign}
          receiptData={receiptData || lastMembershipReceipt}
          source={source}
          artistData={artistData}
          isLoading={isLoading}
        />
      );

    return null;
  }, [step, campaign, receiptData, lastMembershipReceipt, source, artistData, isLoading]);

  return (
    <View className="no-scrollbar box-border flex h-full w-full flex-col items-center justify-center overflow-x-clip">
      {background}
      {content}
      <View className="sticky bottom-0 z-stickyHeader w-full">
        <SpotifyBottomAudioPlayer withVaultTheme={false} withBottomNavigator={false} />
      </View>
    </View>
  );
}

export const EarnedReceiptViewSkeleton = () => {
  return (
    <View className="box-border h-full w-full items-center justify-center overflow-x-hidden md2:flex">
      <View className="z-above1 flex h-full flex-col items-center justify-center gap-6 md2:w-2/3 md2:min-w-[420px] md2:max-w-[420px]">
        <View className="relative mx-auto flex w-full flex-shrink-0 flex-col items-center justify-center overflow-hidden">
          <LoadingSkeleton className="aspect-square w-[140px] rounded-xl object-cover md2:w-[240px]" />
        </View>

        <View className="z-above1 flex w-full flex-col items-center">
          <LoadingSkeleton className="h-[40px] w-[280px] md2:h-[46px] md2:w-[300px]" />

          <View className="mt-3 box-border flex w-full items-center justify-center space-x-2 px-6 no-underline">
            <LoadingSkeleton className="aspect-square h-6 w-6 rounded-full md2:h-8 md2:w-8" />
            <LoadingSkeleton className="h-[20px] w-[100px] md2:h-[22px]" />
          </View>
        </View>

        <View className="z-above1 flex w-full flex-col items-center px-6 md2:px-0">
          <LoadingSkeleton className="h-[50px] w-[120px]" />
        </View>
      </View>
    </View>
  );
};
