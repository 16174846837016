import type { ReactNode } from 'react';
import { faMessage } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faLink } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import type { AnnouncementSourceType } from '../../../graphql/generated';
import { useCopy } from '../../../hooks/useCopy';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { constructQueryParams } from '../../../utils/stringUtils';
import { Button } from '../../buttons/Button';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

export function BaseShareView({
  contentTypeText,
  title,
  imageElement,
  url,
  sourceId,
  sourceType,
  artistName,
  artistHandle,
  prefilledMessage,
}: {
  title: string;
  imageElement: ReactNode;
  url: string;
  contentTypeText: string;
  sourceId: string;
  sourceType: AnnouncementSourceType;
  artistName: string;
  artistHandle: string;
  prefilledMessage: string | null | undefined;
}) {
  const { closeBottomsheet } = useBottomsheetContainer();
  const { copy } = useCopy({ text: url, successMessage: 'URL copied to clipboard' });

  return (
    <View className="flex flex-col items-center gap-12">
      <View className="flex flex-col items-center gap-2">
        <Text className="font-title text-[24px]/[22px] font-medium text-vault_text">
          Share {contentTypeText}
        </Text>
        <Text className="line-clamp-2 text-center font-base text-[14px]/[18px] font-normal text-vault_text/60">
          {artistName} - {title}
        </Text>
      </View>
      <View className="flex flex-col items-center gap-8">
        {imageElement}
        <Button
          label="Text members"
          type="primary-themed"
          leadingIcon={faMessage}
          href={artistNavigationPath(
            artistHandle,
            '/messages/create',
            constructQueryParams({
              sourceId,
              sourceType,
              m: !!prefilledMessage ? encodeURIComponent(prefilledMessage) : undefined,
            }),
          )}
          onClick={closeBottomsheet}
        />
        <Button
          label="Copy URL"
          onClick={copy}
          leadingIcon={faLink}
          className="font-title text-[16px]/[20px] font-medium text-vault_text"
        />
      </View>
    </View>
  );
}
