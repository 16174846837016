import { getSubdomain, isValidSubdomain } from './subdomainUtils';

export const artistNavigationPath = (
  artistHandle: string | undefined | null,
  path: `/${string}` | '',
  queryParams: string | undefined = undefined,
) => {
  const currentSubdomainArtistHandle = isValidSubdomain() ? getSubdomain() : null;
  const isCurrentArtistSubdomain =
    !!currentSubdomainArtistHandle && currentSubdomainArtistHandle === artistHandle;

  const basePath = isCurrentArtistSubdomain ? `${path}` : `/${artistHandle}${path}`;
  const fullPath = queryParams ? `${basePath}?${queryParams}` : basePath;

  if (isCurrentArtistSubdomain) {
    return fullPath;
  }

  return normalizePath(fullPath);
};

export const normalizePath = (path: string, queryParams?: string) => {
  const isSubdomainValid = isValidSubdomain();
  const fullPathWithParams = queryParams ? `${path}?${queryParams}` : path;

  if (isSubdomainValid) {
    return `${window.location.protocol}//${window.location.host.replace(`${getSubdomain()}.`, '')}${fullPathWithParams}`;
  } else {
    return fullPathWithParams;
  }
};
