import { type UIEventHandler, useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { debounce, type DebouncedFunc } from 'lodash-es';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { faHardDrive, faMessages } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { faChevronRight } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faChevronLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { ROUTES } from '../../../constants/routeConstants';
import { useAppleMusicContext } from '../../../contexts/AppleMusicContext';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useSpotifyContext } from '../../../contexts/SpotifyContext';
import { useQuery } from '../../../graphql/client';
import {
  type EarnedReceiptViewFragment,
  GetReceiptNumberDocument,
  type MembershipReceiptFragment,
} from '../../../graphql/generated';
import { useMembership } from '../../../hooks/membership/useMembership';
import { useAdminArtist } from '../../../hooks/useAdminArtist';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useWindow } from '../../../hooks/useWindow';
import { LoginStatus } from '../../../types/authTypes';
import { EVENTS } from '../../../types/eventTypes';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { constructQueryParams } from '../../../utils/stringUtils';
import { Button } from '../../buttons/Button';
import { Text } from '../../common/Text';
import { View } from '../../common/View';
import { ReceiptUI } from '../../membership/receipt/ReceiptUI';
import { StaticMembershipBadge } from '../../vault/MembershipBadge';
import { CarouselItem } from './CarouselItem';

type CarouselItem = {
  title: string;
  description:
    | string
    | ((isPresave: boolean, isAuthorized: boolean, hasMembership: boolean) => string);
  component: 'receipt' | 'membershipCard' | 'vault' | 'chat';
};

const EARNED_RECEIPT_SLIDE = {
  title: 'You earned a receipt',
  description: (isPresave: boolean, isAuthorized: boolean, hasMembership: boolean) =>
    isPresave
      ? `Thanks for pre-saving! ${isAuthorized && hasMembership ? 'Your receipt has been added to your collection.' : ' Complete your membership to save the receipt.'}`
      : `${isAuthorized && hasMembership ? 'Your receipt has been added to your collection.' : ' Complete your membership to save the receipt.'}`,
  component: 'receipt',
} satisfies CarouselItem;

const NEW_MEMBERS_INFO = [
  {
    title: 'Your membership card',
    description: 'As you collect more receipts, show them off on your membership card.',
    component: 'membershipCard',
  },
  {
    title: 'Access my Vault',
    description:
      "I'll share music, images, videos, and more as my way of thanking you for your support.",
    component: 'vault',
  },
  {
    title: 'Chat with me',
    description:
      'Hang out in the group chat with me and let me know what you think about my music.',
    component: 'chat',
  },
] satisfies CarouselItem[];

export function EarnedView({
  campaign,
  receiptData,
  source,
  artistData,
  isLoading,
}: {
  campaign: EarnedReceiptViewFragment;
  receiptData: MembershipReceiptFragment | null | undefined;
  source: 'apple' | 'spotify' | null;
  artistData?: {
    id: string | null | undefined;
    membershipImage: string | null | undefined;
    linkValue: string | null | undefined;
    name: string | null | undefined;
  };
  isLoading: boolean;
}) {
  const navigate = useNavigate();
  const { loginStatus, loggedInUser } = useAuthContext();
  const { isDesktop } = useWindow();
  const [_searchParams, setSearchParams] = useSearchParams();
  const { artistHandle } = useArtistHandle();

  const adminArtist = useAdminArtist({
    artistHandle,
  });

  const isOwner = !!artistHandle && !!adminArtist;
  const { membership, isLoading: isLoadingMembership } = useMembership({
    artistHandle,
    hasActiveSubscription: !isOwner,
  });

  const hasMembership = !!membership?.artist.mainVault.activeSubscription;

  const { player, setActiveCampaignId } = useSpotifyContext();
  const { musicKit, setCampaignId } = useAppleMusicContext();

  const isPresave = campaign.currentState === 'PRESAVE';
  const isAuthorized = !!loggedInUser?.id && loginStatus === LoginStatus.LOGGED_IN;

  const { data: receiptNumberData } = useQuery(GetReceiptNumberDocument, {
    staleTime: 0,
    variables: !!artistData?.id && {
      input: {
        artistId: artistData.id,
        receiptType:
          campaign.currentState === 'PRESAVE'
            ? 'ArtistMembershipPresaveReceipt'
            : campaign.currentState === 'STREAMING'
              ? 'ArtistMembershipPlayStreamReceipt'
              : 'ArtistMembershipUnknownReceipt',
        releaseCampaignId: campaign.id,
      },
    },
    enabled: receiptData === null,
  });

  const receiptNumber =
    receiptData?.serialNumber != null
      ? receiptData?.serialNumber
      : receiptNumberData?.data.receiptTypeCount.__typename === 'QueryReceiptTypeCountSuccess'
        ? receiptNumberData.data.receiptTypeCount.data + 1
        : '...';

  const randomMemberId = useMemo(() => Math.floor(Math.random() * 10000000), []);

  const containerRef = useRef<HTMLDivElement>(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const carouselData = useMemo(() => {
    if (hasMembership) return [EARNED_RECEIPT_SLIDE];
    return [EARNED_RECEIPT_SLIDE, ...NEW_MEMBERS_INFO];
  }, [hasMembership]);
  const nextSlide = () => {
    setCurrentSlide(prev => (prev + 1) % carouselData.length);
  };

  const prevSlide = () => {
    setCurrentSlide(prev => (prev - 1 + carouselData.length) % carouselData.length);
  };

  const scrollToSlide = (index: number) => {
    const container = containerRef.current;
    if (container) {
      const child = container.firstChild as HTMLElement | null;
      const childWidth = child?.getBoundingClientRect().width || 0;
      container.scrollLeft = childWidth * index;
    }
  };

  const onScroll: DebouncedFunc<UIEventHandler<HTMLDivElement>> = useMemo(
    () =>
      debounce(
        e => {
          const container = e.target as HTMLDivElement;
          const scrollLeft = container.scrollLeft;
          const child = container.firstChild as HTMLElement | null;
          const childWidth = child?.getBoundingClientRect().width || 0;
          const newIndex = Math.round(scrollLeft / childWidth);

          if (newIndex !== currentSlide) {
            setCurrentSlide(newIndex);
          }
        },
        100,
        { trailing: true },
      ),
    [currentSlide],
  );

  const onViewOrCreateAccountClick = useStableCallback(() => {
    if (!isPresave) {
      if (player != null) {
        player.activateElement().then(() => {
          player.pause().then(() => {
            setActiveCampaignId(null);
          });
        });
      }

      if (musicKit != null) {
        musicKit.pause();
        setCampaignId(null);
      }
    }

    const linkValue =
      receiptData?.membership.artist.linkValue || artistData?.linkValue || artistHandle;

    if (loginStatus !== LoginStatus.LOGGED_IN) {
      const queryParams = constructQueryParams({
        linkSpotify: source === 'spotify' ? true : undefined,
        linkAppleMusic: source === 'apple' ? true : undefined,
        artistHandle: linkValue,
        openBottomSheet: hasMembership ? null : 'freeTierModal',
        source,
        sourceReleaseCampaignId: campaign.id,
        eventType: isPresave ? 'presave' : 'streaming',
        redirect: 'drops',
        showReceiptModal: true,
        receiptId: receiptData?.id,
      });
      navigate(`${ROUTES.SIGN_IN}?${queryParams}`);
    } else {
      const queryParams = constructQueryParams({
        linkSpotify: source === 'spotify' ? true : undefined,
        linkAppleMusic: source === 'apple' ? true : undefined,
        openBottomSheet: 'freeTierModal',
        source,
        sourceReleaseCampaignId: campaign.id,
        eventType: isPresave ? 'presave' : 'streaming',
        showReceiptModal: true,
        receiptId: receiptData?.id,
      });
      navigate(artistNavigationPath(linkValue, '/drops', queryParams));
    }
  });

  useEffect(() => {
    scrollToSlide(currentSlide);
  }, [currentSlide]);

  useEffect(() => {
    if (!source) setSearchParams({ step: 'view' });
  }, [setSearchParams, source]);

  const renderCarouselContent = useMemo(() => {
    return (component: string | undefined) => {
      if (!component || !artistHandle) return null;

      switch (component) {
        case 'receipt':
          return (
            <ReceiptUI
              className="aspect-[280/378] h-full max-h-[378px] min-h-[322px] w-full max-w-[280px] select-none md2:aspect-[300/425] md2:h-[425px] md2:max-h-[425px] md2:w-[300px] md2:max-w-[300px]"
              title={campaign.title}
              receiptNumber={receiptNumber}
              username={receiptData?.user.username || receiptData?.user.displayName || '@yourname'}
              artistName={receiptData?.membership.artist.name}
              artistHandle={
                receiptData?.membership.artist.linkValue || artistData?.linkValue || artistHandle
              }
              createdAt={receiptData?.createdAt || new Date().toISOString()}
              type={isPresave ? 'pre-save' : 'stream'}
            />
          );
        case 'membershipCard':
          return (
            <View className="aspect-[280/378] h-full max-h-[378px] w-full max-w-[280px] select-none md2:aspect-[300/425] md2:h-[425px] md2:max-h-[425px] md2:w-[300px] md2:max-w-[300px]">
              <StaticMembershipBadge
                isLoading={isLoading}
                artistName={receiptData?.membership.artist.name || artistData?.name || artistHandle}
                serialNumber={receiptData?.membership.serialNumber || randomMemberId}
                imageUrl={
                  receiptData?.membership.artist.membershipCardImage?.membershipCardImageUrl ||
                  artistData?.membershipImage
                }
                displayName={
                  receiptData?.user.username || receiptData?.user.displayName || 'yourname'
                }
                createdAt={receiptData?.membership.createdAt || new Date().toDateString()}
                receiptCount={receiptData?.membership.receipts || 0}
                artistHandle={artistHandle}
                className="!h-full !w-full md2:max-w-[300px]"
              />
            </View>
          );
        case 'vault':
          return <CarouselItem icon={faHardDrive} />;
        case 'chat':
          return <CarouselItem icon={faMessages} />;
        default:
          return null;
      }
    };
  }, [
    artistHandle,
    campaign.title,
    receiptNumber,
    receiptData?.user.username,
    receiptData?.user.displayName,
    receiptData?.membership.artist.name,
    receiptData?.membership.artist.linkValue,
    receiptData?.membership.artist.membershipCardImage?.membershipCardImageUrl,
    receiptData?.membership.serialNumber,
    receiptData?.membership.createdAt,
    receiptData?.membership.receipts,
    receiptData?.createdAt,
    artistData?.linkValue,
    artistData?.name,
    artistData?.membershipImage,
    isPresave,
    isLoading,
    randomMemberId,
  ]);

  const currSlideInfo = carouselData[currentSlide];
  const description =
    !!currSlideInfo?.description && typeof currSlideInfo?.description === 'function'
      ? `${currSlideInfo.description(isPresave, isAuthorized, hasMembership)}`
      : `${currSlideInfo?.description}`;
  const needCarousel = carouselData.length > 1;
  return (
    <View className="flex flex-1 flex-col items-center justify-center pb-4 pt-8">
      <View className="z-above4 flex flex-col">
        <View className="flex items-center justify-center">
          {isDesktop ? (
            <View className="flex flex-row items-center gap-8">
              {needCarousel && (
                <FontAwesomeIcon
                  icon={faChevronLeft}
                  className="aspect-square cursor-pointer rounded-full bg-white/15 p-2 text-[16px]"
                  onClick={prevSlide}
                />
              )}
              {renderCarouselContent(carouselData[currentSlide]?.component)}
              {needCarousel && (
                <FontAwesomeIcon
                  icon={faChevronRight}
                  className="aspect-square cursor-pointer rounded-full bg-white/15 p-2 text-[16px]"
                  onClick={nextSlide}
                />
              )}
            </View>
          ) : (
            <View
              containerRef={containerRef}
              className="no-scrollbar flex min-h-[322px] w-full flex-shrink-[1] snap-x snap-mandatory flex-row items-center overflow-x-auto"
              onScroll={onScroll}
            >
              {carouselData.map((item, index) => (
                <motion.div key={index} className="h-full w-full flex-none snap-center">
                  <View className="flex h-full flex-col items-center justify-center overflow-y-hidden">
                    {renderCarouselContent(item.component)}
                  </View>
                </motion.div>
              ))}
            </View>
          )}
        </View>

        {/* Dots */}
        {needCarousel && (
          <View className="mt-6 flex justify-center">
            {carouselData.map((_, index) => (
              <View
                key={index}
                className={`mx-1 h-2 w-2 rounded-full ${
                  index === currentSlide ? 'bg-yellow100' : 'bg-base500'
                }`}
                onClick={() => setCurrentSlide(index)}
              />
            ))}
          </View>
        )}

        <View className="mt-4 flex flex-[1.4] flex-shrink-[10] flex-col items-center justify-start">
          {/* Text content */}
          <View className="mt-2 box-border w-full max-w-md px-10 md2:px-0">
            <View className="relative">
              <View className="box-border flex flex-col items-center justify-center overflow-hidden rounded-lg px-4 text-center">
                {carouselData[currentSlide]?.title && (
                  <Text className="mb-2 text-base-xl font-semibold text-white">
                    {carouselData[currentSlide]?.title}
                  </Text>
                )}

                {!!description && (
                  <Text className="box-border max-h-20 w-full text-base-l text-base400 md2:w-5/6">
                    {description}
                  </Text>
                )}
              </View>
            </View>
          </View>
          <Button
            type="primary"
            loading={isAuthorized && isLoadingMembership}
            iconOnly={isAuthorized && isLoadingMembership}
            label={isAuthorized && hasMembership ? 'View in Vault' : 'Claim Membership'}
            className="mx-8 mt-5 w-[250px]"
            onClick={onViewOrCreateAccountClick}
            event={{
              type: EVENTS.CLAIM_RECEIPT,
              properties: { campaignId: campaign.id, dropId: undefined },
            }}
          />
        </View>
      </View>
    </View>
  );
}
