import { useMemo } from 'react';
import { proxy, useSnapshot } from 'valtio';

import * as z from 'zod';
import { gql } from '@soundxyz/gql-string';
import { TEXT_MESSAGE_LIMIT } from '../../constants/phoneConstants';
import { useMutation, useQuery } from '../../graphql/client';
import { RefetchOnComplete } from '../../graphql/effects';
import {
  type AnnouncementLocationTargetInput,
  AnnouncementSourceType,
  CreateMassMessageDocument,
  EditMassMessageDocument,
  FilteredMemberCountDocument,
  GetAnnouncementDocument,
  GetPaginatedVaultAnnouncementsDocument,
  IsoCountry,
  IsousState,
  MassMessageInsightsDocument,
  ScheduledEventAccessFeatureInput,
} from '../../graphql/generated';

gql(/* GraphQL */ `
  mutation CreateMassMessage($input: MutationCreateAnnouncementInput!) {
    createAnnouncement(input: $input) {
      __typename
      ... on MutationCreateAnnouncementSuccess {
        data {
          id
        }
      }
      ... on Error {
        message
      }
    }
  }

  mutation EditMassMessage($input: MutationUpdateAnnouncementInput!) {
    updateAnnouncement(input: $input) {
      __typename
      ... on MutationUpdateAnnouncementSuccess {
        data {
          id
        }
      }
      ... on Error {
        message
      }
    }
  }

  query FilteredMemberCount(
    $vaultId: UUID!
    $artistEventIds: [UUID!]
    $memberType: [ScheduledEventAccessFeatureInput!]
    $locations: [AnnouncementLocationTargetInput!]
  ) {
    filteredMemberCount(
      vaultId: $vaultId
      artistEventIds: $artistEventIds
      features: $memberType
      locations: $locations
    )
  }
`);

export const MASS_MESSAGE_MAX_CHARS = TEXT_MESSAGE_LIMIT;

export const MASS_MESSAGE_ERRORS = {
  message: {
    max: `Message must be less than ${MASS_MESSAGE_MAX_CHARS} characters`,
  },
};

export const MassMessageSourceTypeEnum = z.nativeEnum(AnnouncementSourceType);

export const MassMessageFieldSchema = z.object({
  message: z
    .string()
    .max(MASS_MESSAGE_MAX_CHARS, {
      message: MASS_MESSAGE_ERRORS.message.max,
    })
    .trim()
    .min(1, {
      message: 'Message is required',
    }),
  date: z.date().nullable(),

  locations: z.array(
    z.object({ code: z.nativeEnum(IsoCountry), regionCode: z.nativeEnum(IsousState).nullable() }),
  ),
  drops: z.array(z.object({ id: z.string().uuid(), name: z.string() })),
  memberType: z.array(z.nativeEnum(ScheduledEventAccessFeatureInput)),

  hasAddedMembers: z.boolean(),

  fetchedAnnouncementId: z.string().nullable(),

  sourceId: z.string().nullable(),
  sourceType: MassMessageSourceTypeEnum.nullable(),
});

const MassMessageErrorsSchema = z.object({
  message: z.string().nullish(),
  accessType: z.string().nullish(),
  date: z.string().nullish(),
  locations: z.string().nullish(),
  drops: z.string().nullish(),
  memberType: z.string().nullish(),
  hasAddedMembers: z.string().nullish(),

  fetchedAnnouncementId: z.string().nullish(),

  sourceId: z.string().nullish(),
  sourceType: z.string().nullish(),
});

export const MassMessageSchema = z.object({
  fields: MassMessageFieldSchema,
  errors: MassMessageErrorsSchema,
});

type MassMessageState = z.infer<typeof MassMessageSchema>;

type MassMessageStateFields = z.infer<typeof MassMessageFieldSchema>;
type MassMessageErrors = z.infer<typeof MassMessageErrorsSchema>;

export const initialMassMessageState = () =>
  ({
    fields: {
      message: '',
      date: null,
      locations: [],
      drops: [],
      memberType: [
        ScheduledEventAccessFeatureInput.PaidEvent,
        ScheduledEventAccessFeatureInput.FreeEvent,
      ],
      fetchedAnnouncementId: null,
      hasAddedMembers: false,
      sourceId: null,
      sourceType: null,
    },
    errors: {
      message: null,
      date: null,
      locations: null,
      drops: null,
      memberType: null,
      fetchedAnnouncementId: null,
      hasAddedMembers: null,
      sourceId: null,
      sourceType: null,
    },
  }) satisfies MassMessageState;

export const massMessageState: MassMessageState = proxy(initialMassMessageState());

function clearErrors() {
  massMessageState.errors = {
    message: null,
    date: null,
    locations: null,
    drops: null,
    memberType: null,
    fetchedAnnouncementId: null,
    hasAddedMembers: null,
  };
}

function clearFields() {
  massMessageState.fields = {
    message: '',
    date: null,
    locations: [],
    drops: [],
    memberType: [
      ScheduledEventAccessFeatureInput.PaidEvent,
      ScheduledEventAccessFeatureInput.FreeEvent,
    ],
    fetchedAnnouncementId: null,
    hasAddedMembers: false,
    sourceId: null,
    sourceType: null,
  };
}

async function validateField(name: MassMessageStateKeys) {
  massMessageState.errors[name] = null;

  const result = await MassMessageFieldSchema.shape[name].safeParseAsync(
    massMessageState.fields[name],
  );

  if (!result.success) {
    massMessageState.errors[name] = result.error.flatten().formErrors?.[0];
  }
}

type MassMessageStateKeys = keyof MassMessageState['fields'];
type MassMessageErrorsKeys = keyof MassMessageState['errors'];

function setField<FieldKey extends MassMessageStateKeys>(
  name: FieldKey,
  value: MassMessageStateFields[FieldKey],
) {
  massMessageState.fields[name] = value;
}

function setError<ErrorKey extends MassMessageErrorsKeys>(
  name: ErrorKey,
  value: MassMessageErrors[ErrorKey],
) {
  massMessageState.errors[name] = value;
}

RefetchOnComplete({
  trigger: [CreateMassMessageDocument, EditMassMessageDocument],
  refetch: [
    GetPaginatedVaultAnnouncementsDocument,
    GetAnnouncementDocument,
    MassMessageInsightsDocument,
  ],
});

export function useMassMessageForm({ vaultId }: { vaultId: string | null | undefined }) {
  const { fields, errors } = useSnapshot(massMessageState, {
    sync: true,
  });

  const { data: memberCount = 0, isLoading: isLoadingMemberCount } = useQuery(
    FilteredMemberCountDocument,
    {
      staleTime: Infinity,
      variables: !!vaultId && {
        vaultId,
        artistEventIds: fields.drops.length > 0 ? fields.drops.map(drop => drop.id) : undefined,
        memberType: fields.memberType.length > 0 ? fields.memberType : undefined,
        locations:
          fields.locations.length > 0
            ? fields.locations.map(
                ({ code, regionCode }) =>
                  ({
                    isoCountry: code,
                    isoUsStateCode: regionCode,
                  }) satisfies AnnouncementLocationTargetInput,
              )
            : undefined,
      },
      enabled: fields.hasAddedMembers,
      select: data => data.data.filteredMemberCount,
    },
  );

  const { mutateAsync: createMassMessage, isLoading: isLoadingCreateMassMessage } = useMutation(
    CreateMassMessageDocument,
    {},
  );

  const { mutateAsync: editMassMessage, isLoading: isLoadingEditMassMessage } = useMutation(
    EditMassMessageDocument,
    {},
  );

  const enableSubmit = useMemo(() => {
    return (
      Object.values(errors).every(error => error === null) &&
      fields.message.trim().length > 0 &&
      fields.hasAddedMembers &&
      !!memberCount
    );
  }, [errors, fields.hasAddedMembers, fields.message, memberCount]);

  return {
    fields,
    errors,
    clearErrors,
    clearFields,
    setField,
    setError,
    validateField,
    enableSubmit,
    createMassMessage,
    isLoadingCreateMassMessage,
    memberCount: fields.hasAddedMembers ? memberCount ?? 0 : 0,
    isLoadingMemberCount,
    editMassMessage,
    isLoadingEditMassMessage,
  };
}
