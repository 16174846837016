import { useEffect, useMemo } from 'react';
import { Navigate, useParams } from 'react-router';
import { faFolderOpen } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { BackButton } from '../../components/buttons/BackButton';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { SelectingVaultNav } from '../../components/vault/SelectingVaultNav';
import { UploadDropdownSection } from '../../components/vault/Upload';
import { VaultContents } from '../../components/vault/VaultContents';
import { VaultNav } from '../../components/vault/VaultNav';
import { RowItemsSkeleton } from '../../components/vault/items/ItemSkeleton';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useMenuContainer } from '../../contexts/MenuContext';
import { type ExecutionResultWithData, useQuery } from '../../graphql/client';
import {
  FolderPageFragmentDoc,
  GetFolderInformationBySlugDocument,
  type GetFolderInformationBySlugQuery,
  GetFolderInformationDocument,
  type GetFolderInformationQuery,
  getFragment,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { useSelectVaultContent } from '../../hooks/vault/useSelectVaultContent';
import { artistNavigationPath } from '../../utils/navigationUtils';

gql(/* GraphQL */ `
  fragment FolderPage on VaultContent {
    __typename
    id
    vaultId
    title
    vault {
      price
      messageChannelId
      activeSubscription {
        id
        ...ActiveSubscriptionFeatures
      }
      id
      contentCount
      artist: artistProfile {
        id
        name
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
        linkValue
      }
    }
    ... on VaultFolder {
      id
      childrenVaultCount
    }
  }
  query GetFolderInformation($folderId: UUID!) {
    __typename
    vaultContentById(vaultContentId: $folderId) {
      id
      ...FolderPage
    }
  }

  query GetFolderInformationBySlug($folderSlug: String!, $artistHandle: String!) {
    __typename
    vaultContentBySlug(slug: $folderSlug, artistHandle: $artistHandle) {
      __typename
      ... on QueryVaultContentBySlugSuccess {
        data {
          id
          ...FolderPage
        }
      }
      ... on Error {
        message
      }
    }
  }
`);

export const FolderPage = () => {
  const { folderId: folderIdParam, folderSlug } = useParams<{
    folderId?: string;
    folderSlug?: string;
  }>();
  const { artistHandle } = useArtistHandle();

  useVaultTheme();

  const { isDesktop } = useWindow();
  const showVaultNav = !isDesktop;

  const bySlug = folderSlug != null;

  const { loggedInUser } = useAuthContext();
  const { data, isLoading, isError, refetch } = useQuery(
    bySlug ? GetFolderInformationBySlugDocument : GetFolderInformationDocument,
    {
      staleTime: Infinity,
      variables:
        !!artistHandle &&
        (bySlug
          ? {
              artistHandle,

              folderSlug,
            }
          : !!folderIdParam && {
              folderId: folderIdParam,
            }),
      select: useStableCallback(
        (
          data: ExecutionResultWithData<
            GetFolderInformationBySlugQuery | GetFolderInformationQuery
          >,
        ) => {
          if ('vaultContentById' in data.data && data.data.vaultContentById != null) {
            return data.data.vaultContentById;
          }

          return 'vaultContentBySlug' in data.data &&
            data.data.vaultContentBySlug?.__typename === 'QueryVaultContentBySlugSuccess'
            ? data.data.vaultContentBySlug.data
            : null;
        },
      ),
    },
  );

  const folderContent = getFragment(FolderPageFragmentDoc, data);

  useSetMetaHeaders({
    title:
      folderContent?.vault.artist?.name != null && folderContent?.title != null
        ? `${folderContent.vault.artist.name} - ${folderContent.title}`
        : null,
    imageUrl: folderContent?.vault.artist?.profileImage?.artistSmallProfileImageUrl ?? undefined,
  });

  const folderId = folderIdParam ?? folderContent?.id ?? null;

  const { isSelecting, setIsSelecting, clearSelection } = useSelectVaultContent();
  const { setFolderId } = useMenuContainer();
  const isOwner =
    !!artistHandle &&
    !!loggedInUser?.adminArtists?.some(artist => artist.artistLinks.includes(artistHandle));

  const features = useActiveSubscriptionFeatures({
    isOwner,
    subscription: folderContent?.vault.activeSubscription,
  });

  if (artistHandle == null || (folderId == null && folderSlug == null)) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (folderContent != null && folderContent.__typename !== 'VaultFolder') {
    return <Navigate to={artistNavigationPath(artistHandle, `/${folderContent.id}`)} />;
  }

  const resetSelection = useStableCallback(() => {
    setIsSelecting(false);
    clearSelection();
  });

  useEffect(() => {
    return resetSelection;
  }, [resetSelection]);

  useEffect(() => {
    if (!!folderId) {
      setFolderId(folderId);
    } else {
      setFolderId(folderContent?.id ?? null);
    }
    return () => {
      setFolderId(null);
    };
  }, [folderContent, folderId, setFolderId]);

  const vaultId = folderContent?.vaultId;
  const bottomNav = useMemo(
    () => (
      <>
        {isSelecting && isOwner && !!vaultId ? (
          <SelectingVaultNav vaultId={vaultId} />
        ) : (
          showVaultNav && (
            <VaultNav
              vaultId={folderContent?.vaultId}
              messageChannelId={folderContent?.vault.messageChannelId}
              chatAvailableForFreeUsers={false}
              hasChatReadAccess={features?.enabledFeatures.ChatRead ?? false}
              variant="default"
              withVaultTheme
            />
          )
        )}
      </>
    ),
    [
      features?.enabledFeatures.ChatRead,
      folderContent?.vault.messageChannelId,
      folderContent?.vaultId,
      isOwner,
      isSelecting,
      showVaultNav,
      vaultId,
    ],
  );

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      withBottomNavigator={false}
      customBottomNavigator={<View className="w-full md2:hidden">{bottomNav}</View>}
      headerLeft={<BackButton className="text-vault_text" onBack={resetSelection} />}
      headerRight={
        vaultId && isOwner ? (
          <UploadDropdownSection
            artistLinkValue={artistHandle}
            vaultId={vaultId}
            folderId={folderId}
          />
        ) : null
      }
      hasChatReadAccess={features?.enabledFeatures.ChatRead}
      vaultId={folderContent?.vaultId}
      messageChannelId={folderContent?.vault.messageChannelId}
      isHeaderTransparent={false}
      headerClassName="border-0 border-b border-solid border-vault_text/5 pt-10 bg-vault_background"
      headerGridClassName="flex items-center h-full"
      headerLeftClassName="w-[unset] mr-4"
      headerCenterClassName="flex-grow justify-start"
      headerCenter={
        <Text className="line-clamp-1 text-left font-title text-[18px]/[20px] font-medium text-vault_text">
          {folderContent?.title ?? ''}
        </Text>
      }
      footer={<View className="hidden w-full md2:block">{bottomNav}</View>}
      contentClassName="md2:bg-vault_text/3"
    >
      {isLoading ? (
        <View className="mb-4 mt-5 w-full">
          <RowItemsSkeleton rows={1} />
        </View>
      ) : isError || !folderContent ? (
        <ErrorView onRetryClick={refetch} withVaultTheme />
      ) : (
        <VaultContents
          isOwner={isOwner}
          vaultId={folderContent.vaultId}
          artistLinkValue={artistHandle}
          folderId={folderContent.id}
          emptyState={{
            title: 'This folder is empty',
            subTitle: 'Check back later for new uploads',
            icon: faFolderOpen,
          }}
        />
      )}
    </DefaultLayout>
  );
};
