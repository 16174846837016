import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Virtuoso } from 'react-virtuoso';
import { twMerge } from 'tailwind-merge';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import type { ArtistNotificationRowFragmentDoc } from '../../../graphql/generated';
import { type FragmentType } from '../../../graphql/generated';
import { useArtistsNotificationSettings } from '../../../hooks/useArtistsNotificationSettings';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { ArtistNotificationRow } from './ArtistNotificationRow';

export const ArtistNotificationList = () => {
  const { artists, loadMoreNextPage, hasNextPage, isLoadingNewPage, isInitialLoading } =
    useArtistsNotificationSettings();

  const renderItem = useStableCallback(
    (
      _i: number,
      artistData: FragmentType<ArtistNotificationRowFragmentDoc> & {
        id: string;
        isManagedVault?: boolean;
      },
    ) => {
      return (
        <div
          className={twMerge(
            'flex w-full items-center overflow-hidden',
            _i === 0 ? 'py-1 [&_a]:rounded-t-md' : 'py-1',
            _i === artists.length - 1 ? '[&_a]:rounded-b-md' : '',
          )}
        >
          <ArtistNotificationRow
            artistData={artistData}
            isManagedVault={artistData.isManagedVault}
          />
        </div>
      );
    },
  );

  const memoizedFooter = useStableCallback(() =>
    hasNextPage && isLoadingNewPage ? (
      <div className="flex w-full items-center justify-center py-5">
        <FontAwesomeIcon
          icon={faSpinner}
          size="2xl"
          className="ml-2 inline-block animate-spin rounded-full font-medium text-vault_text/50"
        />
      </div>
    ) : (
      <div className="h-4" />
    ),
  );

  const Header = useStableCallback(() => (
    <div className="mb-3 flex flex-col space-y-1">
      <h2 className="font-title text-title-m text-white">Artist notifications</h2>
      <p className="font-base text-base-m text-white/50">
        Customize notifications for individual artists.
      </p>
    </div>
  ));

  const EmptyState = useStableCallback(() => {
    if (isInitialLoading) {
      return (
        <div className="flex flex-col">
          {Array.from({ length: 3 }).map((_, index) => (
            <div key={`artist-notif-${index}`} className="py-1">
              <div className="flex h-[60px] w-full animate-pulse items-center justify-between gap-3 rounded-md bg-base800/0" />
            </div>
          ))}
        </div>
      );
    }
    return null;
  });

  if (!artists) return null;

  return (
    <section className="flex flex-col">
      <Virtuoso
        className="no-scrollbar "
        data={artists}
        endReached={loadMoreNextPage}
        computeItemKey={(_, data) => data.id}
        itemContent={renderItem}
        increaseViewportBy={700}
        useWindowScroll
        components={{ Header, Footer: memoizedFooter, EmptyPlaceholder: EmptyState }}
      />
    </section>
  );
};
