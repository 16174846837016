const timestampParseRegex = /(?:^|\s)(?<timestamp>(\d+):[0-5][0-9])(?=\s|$|\p{P})/gu;

export type TimestampString = `${number}:${number}${number}`;

type ParsedTimestamp = {
  timestamp: TimestampString;
  timestampLength: number;
  startIndex: number;
  endIndex: number;
  seconds: number;
};

export function timestampToSeconds(timestamp: TimestampString): number {
  const [minutes, seconds] = timestamp.split(':').map(Number);

  if (minutes == null || seconds == null) return 0;

  return minutes * 60 + seconds;
}

const isTimestampStringRegex = /^\d+:[0-5][0-9]$/;

export function isTimestampString(value: string | undefined): value is TimestampString {
  if (!value) return false;

  return isTimestampStringRegex.test(value);
}

export function secondsToTimestamp(totalSeconds: number): TimestampString {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const timestamp = `${minutes}:${seconds.toString().padStart(2, '0')}` as const;

  if (isTimestampString(timestamp)) return timestamp;

  throw new Error('Unexpected invalid seconds');
}

export function parseTimestamps(content: string) {
  const timestamps: Array<ParsedTimestamp> = [];

  for (const match of content.matchAll(timestampParseRegex)) {
    const timestamp = match.groups?.timestamp;
    if (isTimestampString(timestamp)) {
      const timestampLength = timestamp.length;
      const index = match.index ? match.index + (match[0].match(/\S/)?.index ?? 0) : 0;
      timestamps.push({
        timestamp,
        timestampLength,
        startIndex: index,
        endIndex: index + timestampLength,
        seconds: timestampToSeconds(timestamp),
      });
    }
  }

  if (timestamps.length === 0) {
    return null;
  }

  return timestamps;
}

export function splitOnTimestamps({
  content,
  timestamps,
}: {
  content: string;
  timestamps: ReturnType<typeof parseTimestamps>;
}): Array<string | ParsedTimestamp> {
  if (!timestamps) return [content];

  const result: Array<string | ParsedTimestamp> = [];

  let sliceStartIndex = 0;

  for (const parsedTimestamp of timestamps) {
    const preContent = content.slice(sliceStartIndex, parsedTimestamp.startIndex);

    if (preContent) {
      result.push(preContent);
    }

    result.push(parsedTimestamp);

    sliceStartIndex = sliceStartIndex + preContent.length + parsedTimestamp.timestampLength;
  }

  const postContent = content.slice(sliceStartIndex);

  if (postContent) {
    result.push(postContent);
  }

  return result;
}
