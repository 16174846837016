import msFn from 'ms';
import { gql } from '@soundxyz/gql-string';
import type { InfiniteData } from '@soundxyz/graphql-react-query/reactQuery';
import type { Falsy } from '@soundxyz/utils';
import { useAuthContext } from '../../contexts/AuthContext';
import { type ExecutionResultWithData, useInfiniteQuery } from '../../graphql/client';
import {
  GetPaginatedVaultAnnouncementsDocument,
  type GetPaginatedVaultAnnouncementsQuery,
  ScheduledEventSmsDeliveryStatus,
} from '../../graphql/generated';
import { getManyFromList } from '../../utils/arrayUtils';
import { useStableCallback } from '../useStableCallback';

gql(/* GraphQL */ `
  query GetPaginatedVaultAnnouncements($artistHandle: String!, $after: String, $first: Int!) {
    announcementsForUserByArtistHandle(artistHandle: $artistHandle, after: $after, first: $first) {
      edges {
        node {
          id
          ... on Announcement {
            content
            scheduledAt
            smsDeliveryStatus
            ...MMInsights
            ...MassMessageRow
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

const LIMIT = 10;

const fiveMinutes = msFn('5 minutes');
const thirtySeconds = msFn('30 seconds');

export function usePaginatedVaultAnnouncements({
  enabled,
  artistHandle,
  limit = LIMIT,
}: {
  enabled: boolean;
  artistHandle: string | Falsy;
  limit?: number;
}) {
  const { loggedInUser } = useAuthContext();

  return useInfiniteQuery(GetPaginatedVaultAnnouncementsDocument, {
    filterQueryKey: { artistHandle },
    staleTime: 0,
    enabled: enabled && !!loggedInUser,
    getNextPageParam: ({ data }) => {
      return (
        data.announcementsForUserByArtistHandle.pageInfo.hasNextPage && {
          after: data.announcementsForUserByArtistHandle.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      (({ pageParam }) => {
        return {
          artistHandle,
          after: pageParam?.after ?? null,
          first: limit,
        };
      }),
    list: ({ announcementsForUserByArtistHandle }) => {
      return getManyFromList(
        announcementsForUserByArtistHandle.edges,
        ({ node }) => node.content != null && node,
      );
    },
    uniq: ({ id }) => id,
    refetchInterval: useStableCallback(
      (
        data:
          | InfiniteData<ExecutionResultWithData<GetPaginatedVaultAnnouncementsQuery>>
          | undefined,
      ) => {
        const now = new Date();
        const messages =
          data?.pages.flatMap(page =>
            page.data.announcementsForUserByArtistHandle.edges.map(edge => edge.node),
          ) ?? [];
        const hasProcessingMessages = messages.some(message => {
          if (message.smsDeliveryStatus === ScheduledEventSmsDeliveryStatus.Processing) {
            return true;
          }
          const scheduledAt = new Date(message.scheduledAt);
          const diff = Math.abs(scheduledAt.getTime() - now.getTime());
          return diff < fiveMinutes;
        });

        return hasProcessingMessages ? thirtySeconds : false;
      },
    ),
  });
}
