import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextareaAutosize from 'react-textarea-autosize';
import { twMerge } from 'tailwind-merge';
import { faClose } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faInfoCircle } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { TEXT_MESSAGE_LIMIT } from '../../constants/phoneConstants';
import { TrackUpload, useTrackUpload } from '../../contexts/TrackUploadContext';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { Toggle } from '../forms/Toggle';

export function FinalizeTrackUploadView({ onShareClick }: { onShareClick: () => void }) {
  const [showBanner, setShowBanner] = useState(true);

  const { isUploadingTrack, isPromptOpen, notificationMessage, shouldSendSms, fileRef } =
    useTrackUpload();
  const [messageLength, setMessageLength] = useState(notificationMessage?.length ?? 0);

  const handleNotificationMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newMessage = e.target.value;
    if (newMessage.length <= TEXT_MESSAGE_LIMIT) {
      TrackUpload.notificationMessage = newMessage;
      setMessageLength(newMessage.length);
    }
  };

  useEffect(() => {
    setMessageLength(notificationMessage?.length ?? 0);
  }, [notificationMessage]);

  if (!fileRef.current) return null;

  const { contentType } = fileRef.current;

  return (
    <View className="no-scrollbar flex w-full flex-1 flex-col justify-between overflow-y-scroll pb-4">
      <View className="my-3 flex flex-col gap-6">
        <View className="flex flex-col gap-4 pb-6">
          <View className="flex w-full flex-row">
            <Toggle
              label="Notify fans with SMS"
              onChange={e => (TrackUpload.shouldSendSms = e.target.checked)}
              checked={shouldSendSms}
              className="text-vault_text"
              componentClassName="bg-vault_text/10 after:bg-vault_accent_text peer-checked:bg-vault_accent"
            />
          </View>

          {showBanner && (
            <View
              className={twMerge(
                'flex flex-row items-center justify-between rounded-full bg-vault_text/20 bg-opacity-30 px-1.5 py-3 md:px-2',
                !shouldSendSms && 'opacity-20',
              )}
            >
              <View className="ml-1 flex w-full flex-row items-center justify-start gap-2 ">
                <FontAwesomeIcon icon={faInfoCircle} className="text-vault_text/50" />
                <Text className="line-clamp-1 text-[12px]/[16px] text-vault_text/50 md:!text-base-m">
                  A personalized message improves member engagement
                </Text>
              </View>
              <Button
                label=""
                leadingIcon={faClose}
                leadingIconClassName="h-4 w-4 text-vault_text/50"
                onClick={() => setShowBanner(false)}
              />
            </View>
          )}

          <View className="flex flex-col gap-3">
            <TextareaAutosize
              className={twMerge(
                'mt-1 min-h-[100px] w-[inherit] resize-none rounded-md  border p-3 font-base !text-base-l focus:font-normal focus:outline-none disabled:opacity-20',
                'border-vault_text/5 bg-vault_background text-vault_text placeholder:text-vault_text/50 disabled:text-vault_text/50',
              )}
              placeholder={
                contentType === 'TRACK'
                  ? '"Hey! I just dropped a new track. Let me know what you think!"'
                  : `"Just uploaded a new ${contentType.toLowerCase()}! Check it out."`
              }
              value={notificationMessage ?? ''}
              onChange={handleNotificationMessageChange}
              disabled={isUploadingTrack || !shouldSendSms}
              autoFocus
            />
            <View
              className={twMerge(
                'flex flex-row items-center justify-between  text-vault_text/50',
                !shouldSendSms && 'opacity-20',
              )}
            >
              <Text className="text-[12px]/[16px]">
                {messageLength}/{TEXT_MESSAGE_LIMIT}
              </Text>
              <View className="flex flex-row items-center justify-center gap-1">
                <FontAwesomeIcon icon={faLink} size="xs" />
                <Text className="text-[12px]/[16px]">
                  {contentType === 'IMAGE' ? 'Image' : contentType === 'VIDEO' ? 'Video' : 'Track'}{' '}
                  link will be included in the message
                </Text>
              </View>
            </View>
          </View>
        </View>
      </View>
      <Button
        label={contentType === 'TRACK' ? 'Upload track' : 'Upload media'}
        type="primary-themed"
        buttonType="submit"
        disabledClassName="opacity-30"
        disabled={isPromptOpen || isUploadingTrack || (shouldSendSms && !notificationMessage)}
        className="w-full"
        onClick={onShareClick}
      />
    </View>
  );
}
