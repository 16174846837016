import { useEffect, useMemo, useRef } from 'react';
import { isArray } from 'lodash-es';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useWindow } from '../../hooks/useWindow';
import type { ToggleFormBottomsheetProps, ToggleFormValues } from '../../types/bottomsheetTypes';
import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ToggleRow } from '../forms/Toggle';
import { HookAutoSave } from '../forms/hook-form/Autosave';

export function ToggleFormBottomsheet({
  onSubmit,
  title,
  defaultValues,
  parseToggleName,
  onClose,
  checkDisabledRadioFields,
}: ToggleFormBottomsheetProps<ToggleFormValues>) {
  const { isDesktop } = useWindow();
  const { closeBottomsheet } = useBottomsheetContainer();

  const form = useForm<ToggleFormValues>({
    defaultValues,
  });

  const hasBeenChanged = useRef(false);

  useEffect(() => {
    if (hasBeenChanged.current) return;

    hasBeenChanged.current = form.formState.isDirty;
  }, [form.formState.isDirty]);

  useEffect(() => {
    return () => {
      if (!isDesktop) {
        onClose?.({ hasChanges: hasBeenChanged.current, values: form.getValues() });
      }
    };
  }, [defaultValues, form, isDesktop, onClose]);

  const handleCancel = () => {
    form.reset(defaultValues);
    closeBottomsheet();
  };

  const handleSave = async () => {
    const values = form.getValues();
    await onSubmit(values);
    closeBottomsheet();
    onClose?.({ hasChanges: true, values });
  };

  const formValues = form.getValues();

  const toggleFields = useMemo(
    () =>
      Object.entries(formValues).filter(([key, value]) => {
        if (key === 'radioFields' && isArray(value)) {
          return false;
        }

        return value != null;
      }),
    [formValues],
  );

  const isRadioFieldsDisabled = useMemo(
    () => checkDisabledRadioFields?.(formValues) ?? false,
    [formValues, checkDisabledRadioFields],
  );

  return (
    <View className="flex h-full w-full flex-col justify-center pb-[36px] md2:h-auto md2:w-[500px] md2:justify-start md2:pb-4">
      {!!title && (
        <Text className="text-center text-title-xl font-medium text-vault_text">{title}</Text>
      )}
      <FormProvider {...form}>
        {!isDesktop && <HookAutoSave onSubmit={onSubmit} />}
        <View className="mt-8 flex flex-col gap-5 px-4 md2:px-0">
          {toggleFields.map(
            ([key, value]) =>
              value != null && (
                <ToggleRow
                  key={key}
                  label={parseToggleName(key)}
                  control={form.control}
                  name={key}
                />
              ),
          )}
        </View>
        {!!formValues.radioFields && (
          <View
            className={twMerge(
              'mt-5 flex flex-col gap-5 px-4 md2:px-0',
              isRadioFieldsDisabled && 'opacity-40',
            )}
          >
            <Controller
              control={form.control}
              name="radioFields"
              render={({ field: { onChange, value } }) => {
                if (!value || value.length === 0) return <></>;

                return (
                  <>
                    {value.map(({ key, displayName, enabled }) => (
                      <View
                        key={key}
                        className="flex cursor-pointer flex-row items-center justify-between"
                        onClick={() => {
                          if (isRadioFieldsDisabled) return;
                          onChange(
                            value.map(({ key: loopedKey, displayName }) => ({
                              displayName,
                              key: loopedKey,
                              enabled: loopedKey === key ? true : false,
                            })),
                          );
                        }}
                      >
                        <Text className="line-clamp-1 flex-1 flex-shrink gap-1 font-base text-[16px]/[20px] font-normal text-vault_text">
                          {displayName}
                        </Text>
                        <input
                          type="radio"
                          className="vault-theme-radio cursor-pointer"
                          checked={enabled}
                          readOnly
                        />
                      </View>
                    ))}
                  </>
                );
              }}
            />
          </View>
        )}

        <View className="mt-6 hidden w-full flex-row-reverse border-0 border-t border-solid border-t-vault_text/10 pt-6 md2:flex">
          <View className="flex w-full max-w-[50%] items-center justify-end gap-6">
            <Button
              label="Cancel"
              type="primary"
              className="max-h-[48px] w-full rounded-full bg-vault_text/10 text-center font-title text-[16px] font-medium text-vault_text"
              onClick={handleCancel}
            />
            <Button
              label="Save"
              type="primary-themed"
              className="max-h-[48px] w-full rounded-full text-center font-title text-[16px] font-medium"
              onClick={handleSave}
            />
          </View>
        </View>
      </FormProvider>
    </View>
  );
}
