import React, { useCallback, useState } from 'react';
import type { ToastProps } from '../components/common/Toast';
import { Toast } from '../components/common/Toast';

import { useWindow } from '../hooks/useWindow';
import { createContainer } from '../utils/unstated';

export type OpenToastProps = Omit<ToastProps, 'isToastOpen' | 'setToastOpen'>;

export const ToastContext = createContainer(function ToastContext() {
  const [isOpen, setIsOpen] = useState(false);
  const [toastProps, setToastProps] = useState<OpenToastProps | null>(null);

  const openToast = useCallback(
    (newToastProps: OpenToastProps) => {
      setToastProps(newToastProps);
      setIsOpen(true);
    },
    [setIsOpen, setToastProps],
  );

  const closeToast = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return {
    isOpen,
    toastProps,
    openToast,
    closeToast,
  };
});

export function useToast() {
  return ToastContext.useContainer();
}

export const ToastContainer = () => {
  const { isOpen, toastProps, closeToast } = ToastContext.useContainer();
  const { isDesktop } = useWindow();

  const handleOpenChange = useCallback(
    (isOpen: boolean) => {
      if (!isOpen) closeToast();
    },
    [closeToast],
  );

  if (toastProps === null) return null;

  return (
    <Toast
      {...toastProps}
      alignment={
        toastProps.alignment != null ? toastProps.alignment : isDesktop ? 'right' : undefined
      }
      isToastOpen={isOpen}
      setToastOpen={handleOpenChange}
    />
  );
};
