import { useMemo } from 'react';
import { compact } from 'lodash-es';
import { faFile, faMessage, faMessages } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { exhaustiveGuard } from '@soundxyz/utils';
import { ERROR_TYPE, NOTIFICATIONS_ERROR_ACTIONS } from '@soundxyz/vault-logs-utils/constants';
import { useToast } from '../contexts/ToastContext';
import { useMutation, useQuery } from '../graphql/client';
import { RefetchOnComplete } from '../graphql/effects';
import {
  ArtistUserNotificationSettingsByFeatureDocument,
  ArtistUserSettingsFeature,
  UpdateNotificationSettingsDocument,
  UserFeatureSettingsByArtistDocument,
} from '../graphql/generated';
import { logError } from './logger/useLogError';
import { useBetterGate } from './useFeatureGate';

gql(/* GraphQL */ `
  mutation UpdateNotificationSettings($input: MutationUpdateArtistUserSettingsByFeatureInput!) {
    updateArtistUserSettingsByFeature(input: $input)
  }

  query ArtistUserNotificationSettingsByFeature($input: QueryArtistUserSettingsByFeatureInput!) {
    artistUserSettingsByFeature(input: $input) {
      artistName
      dmFromArtist {
        feature
        isSmsEnabled
        isPushEnabled
        radioFields {
          displayName
          key
          enabled
        }
      }
      dmFromUser {
        feature
        isSmsEnabled
        isPushEnabled
        radioFields {
          displayName
          key
          enabled
        }
      }
      groupChatMessageFromArtist {
        feature
        isSmsEnabled
        isPushEnabled
        radioFields {
          displayName
          key
          enabled
        }
      }
      newVaultContent {
        feature
        isSmsEnabled
        isPushEnabled
        radioFields {
          displayName
          key
          enabled
        }
      }
      groupChatMessage {
        feature
        isSmsEnabled
        isPushEnabled
        radioFields {
          displayName
          key
          enabled
        }
      }
    }
  }

  query UserFeatureSettingsByArtist($input: QueryUserFeatureSettingsByArtistInput!) {
    userFeatureSettingsByArtist(input: $input) {
      artistName
      dmFromArtist {
        feature
        isSmsEnabled
        isPushEnabled
        radioFields {
          displayName
          key
          enabled
        }
      }
      dmFromUser {
        feature
        isSmsEnabled
        isPushEnabled
        radioFields {
          displayName
          key
          enabled
        }
      }
      groupChatMessageFromArtist {
        feature
        isSmsEnabled
        isPushEnabled
        radioFields {
          displayName
          key
          enabled
        }
      }
      newVaultContent {
        feature
        isSmsEnabled
        isPushEnabled
        radioFields {
          displayName
          key
          enabled
        }
      }
      groupChatMessage {
        feature
        isSmsEnabled
        isPushEnabled
        radioFields {
          displayName
          key
          enabled
        }
      }
    }
  }
`);

RefetchOnComplete({
  trigger: UpdateNotificationSettingsDocument,
  refetch: [ArtistUserNotificationSettingsByFeatureDocument, UserFeatureSettingsByArtistDocument],
});

export function useArtistUserNotificationSettings({ artistHandle }: { artistHandle: string }) {
  const isUserMessageInGroupChatPushNotificationEnabled =
    useBetterGate('GROUP_CHAT_MESSAGE_PUSH_NOTIF') === 'enabled';

  const { openToast } = useToast();
  const {
    data: artistUserSettingsByFeature,
    isLoading: isLoadingArtistUserSettingsByFeature,
    isError: isErrorArtistUserSettingsByFeature,
    error: errorArtistUserSettingsByFeature,
    refetch: refetchArtistUserSettingsByFeature,
  } = useQuery(ArtistUserNotificationSettingsByFeatureDocument, {
    variables: {
      input: {
        artistHandle,
      },
    },
    staleTime: 0,
    select: data => data.data.artistUserSettingsByFeature,
    enabled: !!artistHandle && !isUserMessageInGroupChatPushNotificationEnabled,
  });

  const {
    data: userFeatureSettingsByArtist,
    isLoading: isLoadingUserFeatureSettingsByArtist,
    isError: isErrorUserFeatureSettingsByArtist,
    error: errorUserFeatureSettingsByArtist,
    refetch: refetchUserFeatureSettingsByArtist,
  } = useQuery(UserFeatureSettingsByArtistDocument, {
    variables: {
      input: {
        artistHandle,
      },
    },
    staleTime: 0,
    select: data => data.data.userFeatureSettingsByArtist,
    enabled: !!artistHandle && isUserMessageInGroupChatPushNotificationEnabled,
  });

  const isLoading = isLoadingArtistUserSettingsByFeature || isLoadingUserFeatureSettingsByArtist;
  const isError = isErrorArtistUserSettingsByFeature || isErrorUserFeatureSettingsByArtist;
  const error = errorArtistUserSettingsByFeature || errorUserFeatureSettingsByArtist;
  const refetch = refetchArtistUserSettingsByFeature || refetchUserFeatureSettingsByArtist;

  const { mutateAsync } = useMutation(UpdateNotificationSettingsDocument, {
    onError: error => {
      logError({
        action: NOTIFICATIONS_ERROR_ACTIONS.NOTIFICATION_SETTINGS_ERROR,
        error,
        level: 'warning',
        message: 'Failed to update notification settings',
        toast: 'Failed to update notification settings',
        openToast,
        errorType: ERROR_TYPE.MUTATION_ERROR,
        feature: 'NOTIFICATIONS',
        indexedTags: {
          artistHandle,
          source: 'useArtistUserNotificationSettings',
        },
      });
    },
  });

  const {
    dmFromArtist,
    dmFromUser,
    groupChatMessageFromArtist,
    newVaultContent,
    artistName,
    groupChatMessage,
  } =
    (isUserMessageInGroupChatPushNotificationEnabled
      ? userFeatureSettingsByArtist
      : artistUserSettingsByFeature) || {};

  const data = useMemo(() => {
    return compact([
      dmFromArtist,
      dmFromUser,
      groupChatMessageFromArtist,
      groupChatMessage,
      newVaultContent,
    ]);
  }, [dmFromArtist, dmFromUser, groupChatMessage, groupChatMessageFromArtist, newVaultContent]);

  return {
    data,
    isLoading,
    isError,
    error,
    refetch,
    dmFromArtist,
    dmFromUser,
    groupChatMessageFromArtist,
    groupChatMessage,
    newVaultContent,
    artistName,
    updateNotificationSettings: mutateAsync,
  };
}

export function parseNotificationFeature({
  feature,
  isUserMessageInGroupChatPushNotificationEnabled,
}: {
  feature: ArtistUserSettingsFeature;
  isUserMessageInGroupChatPushNotificationEnabled: boolean;
}) {
  switch (feature) {
    case ArtistUserSettingsFeature.DmFromArtist:
      return { label: 'Artist DMs', icon: faMessage, path: 'artist-dms' };
    case ArtistUserSettingsFeature.DmFromUser:
      return {
        label: isUserMessageInGroupChatPushNotificationEnabled ? 'Direct messages' : 'Messages',
        icon: faMessage,
        path: 'dms',
      };
    case ArtistUserSettingsFeature.GroupChatMessageFromArtist:
      return { label: 'Group chats', icon: faMessages, path: 'group-chats' };
    case ArtistUserSettingsFeature.NewVaultContent:
      return { label: 'Content uploads', icon: faFile, path: 'content' };
    case ArtistUserSettingsFeature.GroupChatMessage:
      return { label: 'Group chats', icon: faMessages, path: 'group-chat' };
    default:
      exhaustiveGuard(feature);
  }
}
