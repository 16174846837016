import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faArrowUpRight } from '@soundxyz/font-awesome/pro-light-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { useQuery } from '../../graphql/client';
import { VaultInsightsHeaderDocument } from '../../graphql/generated';
import { useWindow } from '../../hooks/useWindow';
import { artistNavigationPath } from '../../utils/navigationUtils';

gql(/* GraphQL */ `
  query VaultInsightsHeader($vaultId: UUID!) {
    mySubscribersSummary(vaultId: $vaultId) {
      activeSubscriptionsCount
      activeSubscriptionsCountLast7Days
    }
  }
`);

export function VaultInsightsHeader({
  vaultId,
  artistHandle,
}: {
  vaultId: string;
  artistHandle: string;
}) {
  const { data } = useQuery(VaultInsightsHeaderDocument, {
    variables: {
      vaultId,
    },
    staleTime: 0,
  });

  const divider = (
    <View className="h-8 w-[1px] border-b-0 border-l-0 border-r border-t-0 border-solid border-white opacity-10" />
  );

  const { width } = useWindow();

  const showLast7days = width != null && width > 500;

  return (
    <Link
      to={artistNavigationPath(artistHandle, '/members')}
      className="text-vault_text no-underline"
    >
      <View className="flex cursor-pointer flex-row items-center justify-between gap-2 rounded-md bg-gradient-to-b from-vault_text/30 via-vault_text/5 to-vault_text/0 p-[1px]">
        <View className="flex w-full cursor-pointer flex-row items-center justify-between gap-2 rounded-md bg-vault_background/10 p-4 backdrop-blur-3xl">
          <View>
            <Text className="font-title text-[26px]/[24px] font-medium text-vault_text">
              {data?.data.mySubscribersSummary.activeSubscriptionsCount ?? '...'}
            </Text>
            <Text className="whitespace-nowrap font-base text-base-s font-normal text-vault_text opacity-50">
              Total members
            </Text>
          </View>
          {divider}
          {showLast7days && (
            <>
              <View>
                <Text className="font-title text-[26px]/[24px] font-medium text-vault_text">
                  {data?.data.mySubscribersSummary.activeSubscriptionsCountLast7Days ?? '...'}
                </Text>
                <Text className="whitespace-nowrap font-base text-base-s font-normal text-vault_text opacity-50">
                  New signups this week
                </Text>
              </View>
              {divider}
            </>
          )}
          <View className="flex flex-row items-center justify-start gap-2 p-2 font-base text-base-s font-normal">
            <Text className="whitespace-nowrap">View insights dashboard</Text>
            <FontAwesomeIcon icon={faArrowUpRight} className="text-[14px] font-light" />
          </View>
        </View>
      </View>
    </Link>
  );
}
