import React from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { DEFAULT_AVATAR } from '../../constants/imageConstants';
import { Image } from '../common/Image';

type ProfileImageProps = {
  profileImageUrl: string | undefined | null;
  className?: string;
};

export function ProfileImage(
  props: ProfileImageProps &
    ({ href: string | null | undefined } | { onClick: (() => void) | undefined }),
) {
  const { className, profileImageUrl } = props;

  const component = (
    <Image
      src={profileImageUrl ?? DEFAULT_AVATAR}
      alt="User avatar"
      className={twMerge('aspect-square rounded-[50%]', className)}
    />
  );

  if ('onClick' in props && props.onClick) {
    return (
      <button
        className={twMerge(
          'm-0 aspect-square cursor-pointer appearance-none rounded-[50%] border-none bg-transparent p-0 outline-none',
          className,
        )}
        onClick={props.onClick}
      >
        {component}
      </button>
    );
  }

  if ('href' in props && props.href) {
    return <Link to={props.href}>{component}</Link>;
  }

  return component;
}
