import { useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import {
  faCalendar,
  faHome,
  faMessages,
  type IconDefinition,
} from '@soundxyz/font-awesome/pro-regular-svg-icons';
import {
  faCalendar as faCalendarSolid,
  faHome as faHomeSolid,
  faMessages as faMessagesSolid,
} from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useAdminArtist } from '../../hooks/useAdminArtist';
import { type EventObject, EVENTS, type EventType } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { usePageChecks } from '../../utils/pathUtils';
import { getSubdomain, isValidSubdomain } from '../../utils/subdomainUtils';
import { Button } from '../buttons/Button';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { useBatchedVaultMessageUnreadCount } from '../views/hooks/useVaultMessageUnreadCount';

export function NavigationLinks({
  chatAvailableForFreeUsers,
  hasChatReadAccess,
  isLoading,
  messageChannelId,
  selectedHandleMemo,
  vaultId,
}: {
  chatAvailableForFreeUsers: boolean;
  hasChatReadAccess: boolean;
  isLoading: boolean;
  messageChannelId: string;
  selectedHandleMemo: string;
  vaultId: string;
}) {
  const { loggedInUser } = useAuthContext();

  const artistAdmin = useAdminArtist({ artistHandle: selectedHandleMemo });
  const isOwner = !!artistAdmin;

  const chatEnabled =
    (isOwner || hasChatReadAccess || !!chatAvailableForFreeUsers) && !!loggedInUser?.id;

  const { unreadCount: messageNotificationCount } = useBatchedVaultMessageUnreadCount({
    messageChannelId,
    vaultId,
    enabled: chatEnabled,
  });

  const subdomainArtistHandle = isValidSubdomain() ? getSubdomain() : null;
  const isSubdomain = !!subdomainArtistHandle;

  const getIsHomeSelected = (
    isSubdomain: boolean,
    pathname: string,
    selectedHandleMemo?: string,
  ) => {
    const paths = isSubdomain
      ? ['/vault', '/vault/', '/']
      : [
          `/${selectedHandleMemo}/vault`,
          `/${selectedHandleMemo}/vault/`,
          `/${selectedHandleMemo}`,
          `/${selectedHandleMemo}/`,
        ];
    return paths.some(path => {
      return isSubdomain ? pathname === path : pathname.endsWith(path);
    });
  };

  const isHomeSelected = getIsHomeSelected(isSubdomain, location.pathname, selectedHandleMemo);
  const isMessagesSelected = (() => {
    const path = location.pathname;
    if (path.includes('/messages/insights')) return false;
    return path.endsWith('/messages') || path.endsWith('/chat') || /\/messages\/[^\/]+$/.test(path);
  })();

  const [isChatSelected = false, isMembershipSelected = false] = usePageChecks({
    pages: ['chat', 'drops'],
  });

  return (
    <>
      <MenuItem
        href={artistNavigationPath(selectedHandleMemo, '/vault')}
        icon={isHomeSelected ? faHomeSolid : faHome}
        label="Home"
        selected={isHomeSelected}
        showCountIndicator={false}
        isLoading={isLoading}
        event={{
          type: EVENTS.SECONDARY_MENU_NAVIGATE,
          properties: { artistHandle: selectedHandleMemo, path: '/vault' },
        }}
      />

      <MenuItem
        href={artistNavigationPath(selectedHandleMemo, '/messages')}
        icon={isMessagesSelected || isChatSelected ? faMessagesSolid : faMessages}
        label="Messages"
        selected={isChatSelected || isMessagesSelected}
        showCountIndicator={!!messageNotificationCount && hasChatReadAccess}
        isLoading={isLoading}
        event={{
          type: EVENTS.SECONDARY_MENU_NAVIGATE,
          properties: { artistHandle: selectedHandleMemo, path: '/chat' },
        }}
      />

      <MenuItem
        href={artistNavigationPath(selectedHandleMemo, '/drops')}
        icon={isMembershipSelected ? faCalendarSolid : faCalendar}
        label="Drops"
        selected={isMembershipSelected}
        showCountIndicator={false}
        isLoading={isLoading}
        event={{
          type: EVENTS.SECONDARY_MENU_NAVIGATE,
          properties: { artistHandle: selectedHandleMemo, path: '/drops' },
        }}
      />
    </>
  );
}

function MenuItem<Event extends EventType>(
  props: {
    icon: IconDefinition;
    label: string;
    selected: boolean;
    showCountIndicator: boolean;
    isLoading: boolean;
  } & ({ onClick: () => void } | { event: EventObject<Event> | undefined; href: string }),
) {
  const location = useLocation();

  if (props.isLoading) {
    return <MenuItemSkeleton />;
  }

  return (
    <View className="relative">
      <Button
        href={'href' in props ? props.href : undefined}
        className={twMerge(
          'flex h-14 max-h-14 min-h-14 w-full flex-row items-center justify-start gap-3 rounded-xl p-3 font-title text-[18px] font-medium outline-none transition-all duration-500 ease-in-out focus:outline-none active:outline-none',
          props.selected ? 'text-vault_text' : 'text-vault_text/50',
          'hover:bg-vault_text/10 hover:text-vault_text',
        )}
        label={props.label}
        leadingIcon={props.icon}
        leadingIconClassName="mr-1 aspect-square w-8 text-[24px]"
        onClick={() => {
          if ('event' in props && !!props.event) {
            trackEvent({ ...props.event, pathname: location.pathname });
          } else if ('onClick' in props) {
            props.onClick();
          }
        }}
      />

      {props.showCountIndicator && (
        <View className="absolute left-[30px] top-[14px] h-2 w-2 items-center justify-center rounded-full border-2 border-solid border-vault_background bg-vault_text" />
      )}
    </View>
  );
}

function MenuItemSkeleton() {
  return (
    <View className="relative">
      <View
        className={twMerge(
          'flex h-10 w-full flex-row items-center justify-start gap-3 rounded-xl p-3',
        )}
      >
        <LoadingSkeleton className="aspect-square h-8 w-8 rounded-full bg-vault_text/10" />
        <LoadingSkeleton className="h-6 w-32 rounded-lg bg-vault_text/10" />
      </View>
    </View>
  );
}
