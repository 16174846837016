import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router';
import { Link, Navigate, useSearchParams } from 'react-router-dom';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { faPlay } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { ArtistProfileImage } from '../../components/artist/ArtistProfileImage';
import { BackButton } from '../../components/buttons/BackButton';
import { Button } from '../../components/buttons/Button';
import { Image } from '../../components/common/Image';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useVaultContentByIdOrSlug } from '../../hooks/useVaultContent';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { formatTime } from '../../utils/textUtils';
import { SignInForm } from './TrackLandingPage';

export const MediaLandingPage = () => {
  const { vaultContentId, vaultContentSlug } = useParams<{
    vaultContentId: string;
    vaultContentSlug: string;
    artistHandle: string;
  }>();
  const { artistHandle } = useArtistHandle();

  const { loginStatus, loggedInUser } = useAuthContext();
  const { openBottomsheet } = useBottomsheetContainer();

  useVaultTheme();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const invite = searchParams.get('invite'); // invite code
  const smsCampaignResponseShortcode = searchParams.get('c');

  const {
    data: media,
    isLoading,
    isError,
    refetch,
  } = useVaultContentByIdOrSlug({
    vaultContentId,
    vaultContentSlug,
    artistHandle,
  });

  const vault = media?.vault;
  const artistName = vault?.artist?.name ?? 'Unknown';
  const artistProfileImage = vault?.artist?.profileImage?.artistSmallProfileImageUrl;
  const membershipCardImage = vault?.artist?.membershipCardImage?.membershipCardImageUrl;
  const title = media?.title ?? 'Untitled';
  const isOwner = vault?.isUserArtistAdmin || loggedInUser?.id === vault?.artist?.id;

  const isSubscriberOrAdmin = !!vault?.activeSubscription || !!vault?.isUserArtistAdmin;

  /**
   * If the user is already subscribed and has access to the media content, navigate to the vault page
   *
   * Follows the track landing page redirect logic, maintaining `trackId` param for backwards compatibility
   */
  useEffect(() => {
    if (isSubscriberOrAdmin && media?.isFullVersionAvailable) {
      navigate(artistNavigationPath(artistHandle, '/', `trackId=${media.id}`));
    }
  }, [artistHandle, isSubscriberOrAdmin, navigate, media?.isFullVersionAvailable, media?.id]);

  useSetMetaHeaders({
    title: media
      ? `${artistName} - ${title}`
      : /**
         * Use defaults for loading state
         */
        '',
  });
  const hasSubscription = vault?.activeSubscription != null;

  const onJoinClick = useStableCallback(() => {
    if (hasSubscription) {
      const queryParams = constructQueryParams({
        inviteCode: invite,
        c: smsCampaignResponseShortcode,
      });
      navigate(artistNavigationPath(artistHandle, '/subscribe', queryParams));
      return;
    }

    if (
      vault?.id != null &&
      loginStatus === LoginStatus.LOGGED_IN &&
      media?.id &&
      !isSubscriberOrAdmin
    ) {
      const queryParams = constructQueryParams({
        artistHandle,
        contentId: media.id,
        invite,
      });

      openBottomsheet({
        type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
        membershipConfirmationBottomsheetProps: {
          vaultId: vault.id,
          isLoading: false,
          artistHandle: artistHandle,
          artistName: artistName,
          imageUrl: membershipCardImage,
          loggedInUserUsername: loggedInUser?.username,
          loginStatus,
          inviteCode: invite,
          smsCampaignResponseShortcode,
          sourceReleaseCampaignId: null,
          onConfirm: () => {
            navigate(artistNavigationPath(artistHandle, '/', queryParams));
          },
        },
        shared: {
          hideCloseBottomsheetButton: false,
          preventSwipeToDismiss: false,
          preventOutsideAutoClose: true,
          hidePulleyBar: true,
          withVaultTheme: true,
          isUnclosable: true,
        },
      });
    }
  });

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isLoading) {
    return <FullPageLoading withVaultTheme />;
  }
  if (!media) {
    return !isError ? (
      <Navigate to={ROUTES.NOT_FOUND} />
    ) : (
      <DefaultLayout
        showBorder
        showRoundedTop
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        shouldSkipMargin
        withVaultTheme
        contentClassName="md2:bg-vault_text/3"
        headerLeft={<BackButton className="text-vault_text" />}
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="track_landing_page"
            withVaultTheme
          />
        }
      />
    );
  }

  if (media.__typename === 'VaultFolder') {
    const queryParams = constructQueryParams({
      invite,
      c: smsCampaignResponseShortcode,
    });
    return (
      <Navigate
        to={artistNavigationPath(
          artistHandle,
          vaultContentSlug != null ? `/f/${vaultContentSlug}` : `/folder/${media.id}`,
          queryParams,
        )}
        replace
      />
    );
  }

  if (media.__typename === 'VaultTrack') {
    const queryParams = constructQueryParams({
      invite,
      c: smsCampaignResponseShortcode,
    });

    return (
      <Navigate
        to={artistNavigationPath(
          artistHandle,
          media.linkValue != null ? `/t/${media.linkValue}` : `/${media.id}`,
          queryParams,
        )}
        replace
      />
    );
  }

  if (media.__typename === 'VaultImage' || media.__typename === 'VaultVideo') {
    if (isOwner) {
      const queryParams = constructQueryParams({
        trackId: media.id,
        invite,
        c: smsCampaignResponseShortcode,
      });
      return <Navigate to={artistNavigationPath(artistHandle, '/', queryParams)} replace />;
    }
  }

  const isLoggedOut = loginStatus === LoginStatus.LOGGED_OUT;
  const isVideo = media.__typename === 'VaultVideo';

  return (
    <DefaultLayout
      showBorder
      shouldSkipMargin
      showRoundedTop
      hasChatReadAccess={false}
      messageChannelId={undefined}
      withVaultTheme
      vaultId={undefined}
      withBottomNavigator={false}
      headerClassName="bg-vault_background"
      contentClassName="md2:bg-vault_text/3"
      headerCenter={
        <Link to={artistNavigationPath(artistHandle, '/')} className="no-underline">
          <View className="flex flex-row items-center gap-2">
            <ArtistProfileImage
              profileImageUrl={artistProfileImage}
              className="h-9 w-9 rounded-full md2:h-10 md2:w-10"
            />

            <Text className="select-none font-base text-[16px]/[20px] font-medium text-vault_text">
              {artistName}
            </Text>
          </View>
        </Link>
      }
    >
      <View
        className={clsx(
          'it box-border w-full max-w-[502px] flex-col items-center justify-center px-6 sm:pt-0 md:px-0',
          isLoggedOut ? 'pt-8' : 'pt-12',
        )}
      >
        <View className="relative box-border h-full w-full overflow-hidden rounded-xl">
          {media.blurredMediaUrl && (
            <div
              className={clsx(
                'cd-fade relative flex w-full items-center justify-center',
                isLoggedOut ? 'h-[calc(68vh-96px)] sm:h-[calc(76vh-96px)] ' : 'h-[74vh] ',
              )}
            >
              <div className="cd-fade h-full w-full bg-transparent">
                <Image
                  src={media.blurredMediaUrl}
                  alt={title}
                  className={clsx('h-full w-full object-cover ')}
                />
              </div>

              <div
                className={clsx(
                  'absolute z-above2 flex h-11 w-11 items-center justify-center rounded-full bg-vault_text_opposite/50',
                  isLoggedOut ? 'mb-10 sm:mb-0' : '',
                )}
              >
                <FontAwesomeIcon
                  icon={isVideo ? faPlay : faLock}
                  className={clsx('text-vault_text', isVideo ? ' h-5 w-5' : 'h-4 w-4')}
                />
              </div>
            </div>
          )}
          <View className="absolute bottom-32 z-above1 box-border w-[inherit] px-6 sm:bottom-8 ">
            <View className="flex w-full min-w-0 flex-col items-center justify-center gap-1">
              <View className="flex w-full items-center font-title !text-title-l font-medium text-vault_text sm:!text-title-xl">
                {media.fileTypeExtension ? `${title}.${media.fileTypeExtension}` : title}
              </View>
              <View className="flex w-full flex-row justify-between">
                <Link to={artistNavigationPath(artistHandle, '/')} className="no-underline">
                  <Text className="!text-base!-m sm:!text-base!-l font-base font-medium text-vault_text">
                    {artistName}
                  </Text>
                </Link>

                {media.__typename === 'VaultVideo' && (
                  <Text className="!text-base!-m sm:!text-base!-l font-base font-medium text-vault_text opacity-50">
                    {formatTime(media.duration)}
                  </Text>
                )}
              </View>
            </View>
          </View>
        </View>
        <div className="-mt-24 box-border w-full max-w-[502px] items-center justify-center px-2 py-2 sm:mt-2 sm:px-0">
          {isLoggedOut ? (
            <div className="box-border">
              <SignInForm
                artistHandle={artistHandle}
                trackId={media.id}
                invite={invite}
                smsCampaignResponseShortcode={smsCampaignResponseShortcode}
                type="Media"
              />
            </div>
          ) : (
            <Button
              label={hasSubscription ? 'Upgrade to view' : 'Join for free'}
              type="primary-themed"
              buttonType="submit"
              loading={false}
              disabled={loginStatus === LoginStatus.LOADING}
              disabledClassName="opacity-30"
              className="flex w-full items-center justify-center text-nowrap text-center !text-base-l font-medium"
              event={{ type: EVENTS.NEXT, properties: { type: 'Sign In' } }}
              onClick={onJoinClick}
            />
          )}
        </div>
      </View>
    </DefaultLayout>
  );
};
