import { useEffect } from 'react';
import React from 'react';
import { Navigate, useLocation } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { useSnapshot } from 'valtio';
import { faArrowUpFromBracket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faArrowDownArrowUp } from '@soundxyz/font-awesome/pro-regular-svg-icons';

import { Button } from '../../components/buttons/Button';
import { DropdownEllipsis } from '../../components/common/Dropdown';
import { PaginatedView } from '../../components/common/PaginatedView';
import { Text } from '../../components/common/Text';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { SettingsLayout } from '../../components/layouts/SettingsLayout';
import { LoadingSkeleton } from '../../components/loading/LoadingSkeleton';
import { Chart } from '../../components/membership/ArtistMembershipView';
import { MemberRow } from '../../components/membership/MemberRow';
import { SkeletonUserRow } from '../../components/user/UserRow';
import { EmptyStateView } from '../../components/views/EmptyStateView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { MemberRowFragmentDoc, VaultType } from '../../graphql/generated';
import { getFragment } from '../../graphql/generated';
import { useAdminArtist } from '../../hooks/useAdminArtist';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { filtersState, resetFilters, useMySubscribers } from '../../hooks/useMySubscribers';
import { useSelectedArtist } from '../../hooks/useSelectedArtist';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { LoginStatus } from '../../types/authTypes';
import type { ActionBottomsheetProps } from '../../types/bottomsheetTypes';
import { EVENTS } from '../../types/eventTypes';
import { generateShareLink } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';

export const MySubscribersPage = ({ withVaultTheme = false }: { withVaultTheme?: boolean }) => {
  const { loginStatus } = useAuthContext();

  const { pathname } = useLocation();
  const isSettings = pathname.includes('settings');

  const selectedArtist = useSelectedArtist();
  const paramArtistHandle = useArtistHandle();

  const { openBottomsheet } = useBottomsheetContainer();

  const { sort, tierLevel } = useSnapshot(filtersState);

  useEffect(() => {
    return () => {
      resetFilters();
    };
  }, []);

  const artistHandle =
    selectedArtist.status === 'ready'
      ? paramArtistHandle.artistHandle || selectedArtist.artistHandle
      : paramArtistHandle.artistHandle;

  const artistOwner = useAdminArtist({
    artistHandle,
  });

  useVaultTheme({ enabled: withVaultTheme && !!artistOwner });

  const hasFilterChanges = sort !== 'DESC' || !!tierLevel;

  const {
    hasNextPage,
    fetchNextPage,
    exportCsv,
    isError,
    subscribers,
    refetch,
    isInitialLoading,
    isLoadingError,
    isFetchingNextPage,
    subscribersSummary,
  } = useMySubscribers({
    vaultId: artistOwner?.artistMainVaultId,
    searchQueryString: undefined,
  });

  const onShareClick = () => {
    if (!artistHandle) return;

    const link = generateShareLink({
      artistLinkValue: artistHandle,
      inviteCode: null,
      path: null,
    });

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.SHARE,
      shared: {
        withVaultTheme: false,
      },
      shareBottomsheetProps: {
        link,
        artistName: artistOwner?.artistName ?? 'vault',
        withVaultTheme: false,
      },
    });
  };

  const onExportCsvClick = () => {
    if (!artistOwner) return;

    exportCsv({
      vaultId: artistOwner.artistMainVaultId,
    });
  };

  const buttons: ActionBottomsheetProps['buttons'] = [
    {
      leadingIcon: faArrowUpFromBracket,
      label: 'Export CSV',
      type: 'secondary',
      onClick: onExportCsvClick,
      event:
        artistOwner?.artistId != null
          ? { type: EVENTS.EXPORT_CSV, properties: { artistId: artistOwner.artistId } }
          : undefined,
    },
  ];

  const onEllipsisClick = () => {
    openBottomsheet({
      type: BOTTOMSHEET_TYPES.ACTION,
      actionBottomsheetProps: {
        buttons,
        withVaultTheme: false,
      },
    });
  };

  if (!artistOwner && !!artistHandle && loginStatus !== LoginStatus.LOADING) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} />;
  }

  if (isError && subscribers.length === 0) {
    return (
      <SettingsLayout
        titleComponent={
          <Text
            className={twMerge(
              'font-title !text-title-m font-medium text-white',
              withVaultTheme && 'text-vault_text',
            )}
          >
            Members
          </Text>
        }
        withVaultTheme={withVaultTheme}
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={refetch}
            loggingType="my_subscribers_page"
            withVaultTheme={withVaultTheme}
          />
        }
      />
    );
  }

  if (isInitialLoading) {
    return (
      <SettingsLayout
        withVaultTheme={withVaultTheme}
        titleComponent={
          <View className="flex flex-col items-center">
            <Text className={twMerge('font-title !text-title-m font-medium text-white')}>
              Members
            </Text>
          </View>
        }
      >
        <View className="mb-6 mt-1 flex h-[98.5px] w-full flex-row items-center justify-start gap-2">
          <LoadingSkeleton
            className="flex h-full flex-1 flex-col rounded-md border border-solid border-vault_text border-opacity-10"
            withVaultTheme={withVaultTheme}
          />
          <LoadingSkeleton
            className="flex h-full flex-1 flex-col rounded-md border border-solid border-vault_text border-opacity-10"
            withVaultTheme={withVaultTheme}
          />
        </View>
        <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
        <SkeletonUserRow
          rightComponent
          className="mb-0 items-center rounded-none py-[20px]"
          withVaultTheme={withVaultTheme}
        />
        <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
        <SkeletonUserRow
          rightComponent
          className="mb-0 items-center rounded-none py-[20px]"
          withVaultTheme={withVaultTheme}
        />
        <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
        <SkeletonUserRow
          rightComponent
          className="mb-0 items-center rounded-none py-[20px]"
          withVaultTheme={withVaultTheme}
        />
        <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
        <SkeletonUserRow
          rightComponent
          className="mb-0 items-center rounded-none py-[20px]"
          withVaultTheme={withVaultTheme}
        />
        <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
        <SkeletonUserRow
          rightComponent
          className="mb-0 items-center rounded-none py-[20px]"
          withVaultTheme={withVaultTheme}
        />
      </SettingsLayout>
    );
  }

  return (
    <SettingsLayout
      withVaultTheme={withVaultTheme}
      childrenWrapperClassName={isSettings ? '!pt-3' : '!pt-0'}
      headerLeft={
        <View className="flex flex-col items-center">
          <Text
            className={twMerge(
              'text-nowrap pl-4 font-title !text-[24px]/[24px] font-medium text-white',
              withVaultTheme && 'text-vault_text',
            )}
          >
            Members insights
          </Text>
        </View>
      }
      titleComponent={<></>}
      nonScrollingChildren={
        !isInitialLoading &&
        loginStatus !== LoginStatus.LOADING &&
        subscribers.length === 0 && (
          <EmptyStateView
            title="You currently have no members"
            subtitle="Share your vault with fans to build up your members list"
            buttonText="Share"
            onButtonClick={onShareClick}
            withVaultTheme={withVaultTheme}
          />
        )
      }
      right={
        <DropdownEllipsis
          onClick={onEllipsisClick}
          buttons={buttons}
          dropdownType="Export Member CSV"
          withVaultTheme={withVaultTheme}
        />
      }
    >
      {artistOwner && (
        <Chart
          artistHandle={artistOwner.artistMainLinkValue}
          vaultId={artistOwner.artistMainVaultId}
          visibleRef={null}
          artistId={artistOwner?.artistId}
          artistName={artistOwner.artistName}
          source="members"
        />
      )}
      {subscribersSummary?.data.mySubscribersSummary != null ? (
        <View className="mb-6 mt-4 flex w-full flex-1 flex-col items-center justify-start gap-2">
          {artistOwner?.artistMainVaultType === VaultType.Freemium && (
            <View className="flex w-full flex-1 flex-row gap-4">
              <View
                className={twMerge(
                  'flex flex-1 flex-col gap-3 rounded-md border border-solid border-white border-opacity-10 p-3',
                  withVaultTheme && 'border-vault_text',
                )}
              >
                <Text
                  className={twMerge(
                    'font-title text-[32px] font-medium text-white',
                    withVaultTheme && 'text-vault_text',
                  )}
                >
                  {subscribersSummary.data.mySubscribersSummary.activePaidSubscriptionsCount}
                </Text>
                <Text className={withVaultTheme ? 'text-vault_text' : 'text-white'}>
                  Paid members
                </Text>
              </View>
              <View
                className={twMerge(
                  'flex flex-1 flex-col gap-3 rounded-md border border-solid border-white border-opacity-10 p-3',
                  withVaultTheme && 'border-vault_text',
                )}
              >
                <Text
                  className={twMerge(
                    'font-mediumtext-white font-title text-[32px] text-white',
                    withVaultTheme && 'text-vault_text',
                  )}
                >
                  {subscribersSummary.data.mySubscribersSummary.activeFreeSubscriptionsCount}
                </Text>
                <Text className={withVaultTheme ? 'text-vault_text' : 'text-white'}>
                  Free members
                </Text>
              </View>
            </View>
          )}
        </View>
      ) : (
        <View className="mb-6 mt-1 flex h-[98.5px] w-full flex-row items-center justify-start gap-2">
          <LoadingSkeleton
            className="flex h-full flex-1 flex-col rounded-md border border-solid border-vault_text border-opacity-10"
            withVaultTheme={withVaultTheme}
          />
          <LoadingSkeleton
            className="flex h-full flex-1 flex-col rounded-md border border-solid border-vault_text border-opacity-10"
            withVaultTheme={withVaultTheme}
          />
        </View>
      )}
      <Button
        label={artistOwner?.artistMainVaultType === VaultType.Freemium ? 'Sort & filter' : 'Sort'}
        className={twMerge(
          'mb-4 flex items-center gap-2 self-start font-base !text-base-m font-medium text-base500',
          withVaultTheme && 'text-vault_text/60',
        )}
        leadingIcon={faArrowDownArrowUp}
        labelComponent={
          <View className="flex items-center gap-3">
            <Text>
              {artistOwner?.artistMainVaultType === VaultType.Freemium ? 'Sort & filter' : 'Sort'}
            </Text>

            {hasFilterChanges && (
              <View className="h-4 min-h-4 w-4 min-w-4 items-center justify-center rounded-full bg-yellow100 p-[1px]">
                <Text className="text-center font-base text-base-s text-black">1</Text>
              </View>
            )}
          </View>
        }
        onClick={() => {
          if (!artistOwner) return;
          openBottomsheet({
            type: BOTTOMSHEET_TYPES.SUBSCRIBERS_FILTERS,
            shared: {
              withVaultTheme,
            },
            subscribersFiltersBottomsheetProps: {
              withVaultTheme,
              vaultType: artistOwner.artistMainVaultType,
              vaultId: artistOwner.artistMainVaultId,
            },
          });
        }}
      />
      <PaginatedView
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        paginationThreshold={0.1}
        className="flex w-full flex-col gap-6 pb-4"
        isFetchingNextPage={isFetchingNextPage}
      >
        {subscribers.map(subscriber => {
          const member = getFragment(MemberRowFragmentDoc, subscriber);
          return (
            <MemberRow
              key={member.user.id}
              id={member.id}
              source="subscribers"
              user={member.user}
              tier={member.tier}
              status={member.status}
              createdAt={member.createdAt}
              phone={member.phone}
              email={member.email}
              isTrial={member.isTrial}
              userLocation={member.userLocation}
              artistMembership={member.artistMembership}
              withVaultTheme={withVaultTheme}
              artist={member.artist}
              subText="joined-vault"
            />
          );
        })}
      </PaginatedView>
      {isFetchingNextPage ? (
        <>
          <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
          <SkeletonUserRow
            rightComponent
            className="mb-0 items-center rounded-none py-[20px]"
            withVaultTheme={withVaultTheme}
          />
          <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
          <SkeletonUserRow
            rightComponent
            className="mb-0 items-center rounded-none py-[20px]"
            withVaultTheme={withVaultTheme}
          />
          <LoadingSkeleton className="h-[2px] w-full" withVaultTheme={withVaultTheme} />
          <SkeletonUserRow
            rightComponent
            className="mb-0 items-center rounded-none py-[20px]"
            withVaultTheme={withVaultTheme}
          />
        </>
      ) : isLoadingError ? (
        <ErrorView
          onRetryClick={fetchNextPage}
          loggingType="my_subscribers_page_next_page"
          withVaultTheme={withVaultTheme}
        />
      ) : null}
    </SettingsLayout>
  );
};
