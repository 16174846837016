import { type LegacyRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import { faImage } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faLock } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useMenuContainer } from '../../../contexts/MenuContext';
import { type FragmentType, getFragment, ImageItemFragmentDoc } from '../../../graphql/generated';
import { useBatchedTracksViewed } from '../../../hooks/useBatchedTracksViewed';
import { useActiveSubscriptionFeatures } from '../../../hooks/useTierFeatures';
import { formatDateString } from '../../../utils/textUtils';
import { Image } from '../../common/Image';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

gql(/* GraphQL */ `
  fragment ImageItem on VaultImage {
    id
    title
    linkValue
    createdAt
    blurredMediaUrl

    uploadedMedia {
      id
      mediaType
      fullImageUrl: imageOptimizedUrl
      mediumImageUrl: imageOptimizedUrl(input: { width: 600, height: 600 })
      smallImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
      cdnUrl
    }

    vaultId

    parentVaultContentId

    featureAccess {
      feature {
        __typename
      }
    }

    vault {
      id
      isUserArtistAdmin
      artist: artistProfile {
        id
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
        }
      }
      activeSubscription {
        id
        createdAt
        ...ActiveSubscriptionFeatures
      }
    }
  }
`);
export const ImageItem = ({
  image,
  containerRef,
  onClick,
  hasEllipsis,
}: {
  image: FragmentType<ImageItemFragmentDoc>;
  containerRef?: LegacyRef<HTMLDivElement>;
  onClick?: () => void;
  hasEllipsis?: boolean;
}) => {
  const {
    title,
    createdAt,
    id: imageId,
    vault,
    uploadedMedia,
    blurredMediaUrl,
  } = getFragment(ImageItemFragmentDoc, image);

  const { isVaultCustomizeOpen } = useMenuContainer();

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });

  const { isViewed, isLoading } = useBatchedTracksViewed({ vaultContentId: imageId });

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = (!isOwner && activeSubscriptionFeatures?.tier == null) || uploadedMedia == null;

  const couldBeNew =
    !isOwner && vault.activeSubscription != null && vault.activeSubscription.createdAt < createdAt;

  const [isNew, setIsNew] = useState(false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    setIsNew((couldBeNew && isViewed === false) || isVaultCustomizeOpen);
  }, [couldBeNew, isLoading, isVaultCustomizeOpen, isViewed]);

  return (
    <View
      className="group flex w-full flex-grow cursor-pointer select-none flex-col gap-3 pb-3 text-left"
      containerRef={containerRef}
      onClick={onClick}
    >
      <View className="relative flex min-h-[110px] w-full items-center justify-center rounded-md bg-vault_text/10 transition-all duration-300 ease-in-out group-hover:bg-vault_text/20">
        <AnimatePresence>
          {isNew && (
            <motion.div
              className="absolute right-3 top-2 rounded-full font-base !text-base-xs font-semibold text-vault_accent"
              exit={{ opacity: 0 }}
            >
              New
            </motion.div>
          )}
        </AnimatePresence>
        <View className="relative flex h-[74px] items-start justify-center">
          {!isLocked ? (
            <Image
              className="h-full w-14 rounded-lg bg-vault_text/10 object-cover"
              src={uploadedMedia.smallImageUrl ?? uploadedMedia.cdnUrl}
              alt="VaultImageContent"
            />
          ) : (
            <LockedImageItem blurredMediaUrl={blurredMediaUrl} />
          )}
        </View>
      </View>
      <View className="flex flex-col gap-1">
        <View className="flex w-full justify-between gap-1">
          <Text className="line-clamp-2 text-ellipsis break-words font-base !text-base-m font-medium text-vault_text">
            {title ?? 'Untitled'}
          </Text>
          {!!hasEllipsis && <div className="h-5 w-5 flex-shrink-0" />}
        </View>

        <Text className="text-[12px] font-normal leading-[16px] text-vault_text/50">
          {formatDateString({ date: createdAt, format: 'numerical_month_day_year' })}
        </Text>
      </View>
    </View>
  );
};

export const LockedImageItem = ({
  blurredMediaUrl,
  onClick,
}: {
  blurredMediaUrl: string | null;
  onClick?: () => void;
}) => {
  return (
    <>
      {blurredMediaUrl ? (
        <View className="relative flex h-full w-14 items-center justify-center">
          <img
            className="block h-full w-14 cursor-pointer rounded-lg bg-vault_text/10 object-cover"
            src={blurredMediaUrl}
            onClick={onClick}
          />
          <div className="absolute flex h-6 w-6 items-center justify-center rounded-full bg-vault_text_opposite/50">
            <FontAwesomeIcon icon={faLock} className="text-[10px] text-vault_text/50" />
          </div>
        </View>
      ) : (
        <>
          <View className="bg-new-file-background h-[74px] w-[54px] bg-contain bg-no-repeat" />
          <View className="absolute top-5 flex flex-col items-center justify-center gap-1.5">
            {/* TODO: Better icon */}
            <FontAwesomeIcon icon={faImage} className="text-[24px] text-vault_text" />
            <FontAwesomeIcon icon={faLock} className="text-[11px] text-vault_text" />
          </View>
        </>
      )}
    </>
  );
};
