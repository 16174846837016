import React from 'react';
import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../../contexts/AuthContext';
import {
  type FragmentType,
  getFragment,
  MembershipReceiptFragmentDoc,
} from '../../../graphql/generated';
import { ReceiptUI } from './ReceiptUI';

gql(/* GraphQL */ `
  fragment MembershipReceipt on ArtistMembershipReceipt {
    __typename
    id
    createdAt
    membership {
      createdAt
      artist {
        id
        name
        membershipCardImage {
          id
          membershipCardImageUrl: imageOptimizedUrl
        }
        linkValue
      }
      receipts
      serialNumber
    }
    artist {
      id
      name
      linkValue
    }
    user {
      id
      displayName
      username
    }
    serialNumber
    ... on ArtistMembershipPlayStreamReceipt {
      releaseCampaign {
        id
        title
      }
    }
    ... on ArtistMembershipPresaveReceipt {
      releaseCampaign {
        id
        title
      }
    }
    ... on ArtistMembershipRsvpEventReceipt {
      rsvpEvent {
        id
        title
      }
    }
  }
`);

type ReceiptProps = {
  className?: string;
  receiptFrag: FragmentType<typeof MembershipReceiptFragmentDoc>;
  title?: string;
  receiptNumber?: string | number;
};
export const Receipt = ({
  className,
  receiptFrag,
  title,
  receiptNumber,
  ref,
}: {
  ref?: React.Ref<HTMLDivElement>;
} & ReceiptProps) => {
  const receiptData = getFragment(MembershipReceiptFragmentDoc, receiptFrag);
  const { loggedInUser } = useAuthContext();
  const {
    serialNumber,
    createdAt,
    user: { displayName, username },
    artist: { name: artistName, linkValue: artistHandle },
    __typename,
  } = receiptData;

  const fragTitle =
    __typename === 'ArtistMembershipRsvpEventReceipt'
      ? receiptData.rsvpEvent.title
      : receiptData.releaseCampaign.title;

  const type =
    __typename === 'ArtistMembershipRsvpEventReceipt'
      ? 'rsvp'
      : __typename === 'ArtistMembershipPlayStreamReceipt'
        ? 'stream'
        : 'pre-save';

  const receiptNumberToUse = receiptNumber ?? serialNumber;
  return (
    <ReceiptUI
      ref={ref}
      className={className}
      title={title ?? fragTitle}
      receiptNumber={receiptNumberToUse}
      username={username ?? displayName ?? loggedInUser?.username ?? '@yourname'}
      artistName={artistName}
      artistHandle={artistHandle}
      createdAt={createdAt}
      type={type}
    />
  );
};
