import React, { memo, useCallback, useMemo } from 'react';
import { FontAwesomeIcon, type FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { Content, Overlay, Root } from '@radix-ui/react-dialog';
import { useNavigate } from 'react-router';
import { Link, type LinkProps, useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faCog } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faWaveformLines } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useAudioController } from '../../audio/AudioController';
import {
  HIDDEN_ABOUT_NAVIGATION,
  HIDDEN_EXPLORE_VAULTS_NAVIGATION,
  ROUTES,
} from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useQuery } from '../../graphql/client';
import { ArtistByHandleDocument, FeatureTypename, TierTypename } from '../../graphql/generated';
import { useAdminArtist } from '../../hooks/useAdminArtist';
import { useActiveSubscriptionFeatures } from '../../hooks/useTierFeatures';
import { useWindow } from '../../hooks/useWindow';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { trackEvent } from '../../utils/analyticsUtils';
import { normalizePath } from '../../utils/navigationUtils';
import { getSubdomain, isValidSubdomain } from '../../utils/subdomainUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { Logo } from '../svg/Logo';
import { MenuUser } from './MenuUser';
import { NavigationLinks } from './NavigationLinks';
import { SubscribedArtists } from './SubscribedArtists';
import { VaultSelector } from './VaultSelector';

export function Menu() {
  const location = useLocation();
  const { isDesktop } = useWindow();
  const { hideAudioPlayer, activeTrackId } = useAudioController();
  const { loginStatus } = useAuthContext();
  const { isOpen, closeMenu, selectedHandle, isClosingMenu, setIsAccountOpen, showMenu } =
    useMenuContainer();

  const navigate = useNavigate();

  const hasAudioPlayer = !!activeTrackId && !hideAudioPlayer;

  const subdomainArtistHandle = isValidSubdomain() ? getSubdomain() : null;
  const { pathname } = useLocation();

  const possibleArtistHandleFromPathname = pathname.split('/')[1];
  const possibleArtistHandle = subdomainArtistHandle || possibleArtistHandleFromPathname;

  const hideMenuContent = location.pathname.includes(ROUTES.SETTINGS);

  const selectedHandleMemo = useMemo(() => {
    return (
      selectedHandle ??
      (Object.values(ROUTES).includes(`/${possibleArtistHandle}`) ? null : possibleArtistHandle)
    );
  }, [possibleArtistHandle, selectedHandle]);

  const withVaultTheme = !!selectedHandleMemo;

  const closeAll = useCallback(() => {
    closeMenu();
    setIsAccountOpen(false);
  }, [closeMenu, setIsAccountOpen]);

  if (
    (location.pathname === ROUTES.LANDING_PAGE && !subdomainArtistHandle) ||
    location.pathname.includes('textme') ||
    !showMenu
  ) {
    return null;
  }

  return (
    <Root open={isDesktop || isOpen}>
      <MobileOverlay
        setIsAccountOpen={setIsAccountOpen}
        isClosingMenu={isClosingMenu}
        closeAll={closeAll}
        selectedHandleMemo={selectedHandleMemo}
      >
        <View
          className={twMerge(
            'mt-[18px] flex w-full flex-col overflow-hidden md2:mt-[10px]',
            isDesktop && 'justify-between',
          )}
        >
          {isDesktop && selectedHandleMemo && loginStatus === LoginStatus.LOGGED_IN ? (
            <VaultSelector selectedHandleMemo={selectedHandleMemo} />
          ) : (
            <View className="mb-2 max-w-8 pl-3 md2:pl-2 [&_svg]:w-8">
              <button
                className="flex cursor-pointer appearance-none items-center gap-5 border-none bg-transparent outline-none"
                onClick={() => navigate('/')}
              >
                <Logo className="flex-shrink-0" variant={withVaultTheme ? 'themed' : 'white'} />
                <Text
                  className={twMerge(
                    'hidden font-title text-[18px] font-medium md2:block',
                    withVaultTheme ? 'text-vault_text' : 'text-white',
                  )}
                >
                  Vault
                </Text>
              </button>
            </View>
          )}

          {!isDesktop && (
            <VaultsList
              selectedHandleMemo={selectedHandleMemo}
              withVaultTheme={withVaultTheme}
              closeAll={closeAll}
            />
          )}
        </View>

        {isDesktop && selectedHandleMemo && <MenuLinks selectedHandleMemo={selectedHandleMemo} />}

        {!hideMenuContent && (
          <View
            className={twMerge(
              'bottom-0 mt-2 w-full bg-transparent',
              hasAudioPlayer && 'md2:pb-[87px]',
            )}
          >
            <View className="pb-5">
              <BottomMenuLinks closeAll={closeAll} withVaultTheme={withVaultTheme} />

              <MenuUser selectedHandle={selectedHandleMemo} closeAll={closeAll} />
            </View>
          </View>
        )}
      </MobileOverlay>
    </Root>
  );
}

function MobileOverlay({
  children,
  setIsAccountOpen,
  isClosingMenu,
  closeAll,
  selectedHandleMemo,
}: {
  setIsAccountOpen: (isOpen: boolean) => void;
  isClosingMenu: boolean;
  closeAll: () => void;
  children: React.ReactNode;
  selectedHandleMemo: string | null | undefined;
}) {
  const { isDesktop } = useWindow();
  const location = useLocation();

  const withVaultTheme = !!selectedHandleMemo;

  if (isDesktop) {
    if (
      location.pathname === ROUTES.VERIFY ||
      location.pathname === ROUTES.SIGN_IN ||
      location.pathname === ROUTES.ONBOARDING_USERNAME
    ) {
      return null;
    }

    return (
      <View
        className={twMerge(
          'no-scrollbar absolute bottom-0 left-0 top-0 box-border flex flex-col',
          'z-above5 items-start justify-between overflow-y-scroll pb-0',
          'group/menu w-[300px] pl-8 pr-3 pt-6',
          // Where 300px is the menu width
          withVaultTheme
            ? 'border-r-vault_text/5 bg-vault_background'
            : 'border-r-white/10 bg-black',
        )}
      >
        {children}
      </View>
    );
  }

  return (
    <Overlay className="fixed inset-0 z-overlay grid h-full max-h-screen w-full overflow-x-hidden bg-[rgba(0,0,0,0.6)] sm:mx-[auto] sm:w-full md:w-full lg:w-[33%]">
      <Content
        onOpenAutoFocus={(e: Event) => e.preventDefault()}
        className={twMerge(
          'no-scrollbar absolute bottom-0 left-0 top-0 box-border flex flex-col items-start justify-between overflow-y-scroll pb-0',
          'z-menu shadow-[0px_0px_0px_1px_rgba(0,0,0,0.04),0px_-8px_28px_rgba(0,0,0,0.28)]',
          'w-[338px] max-w-[87%] animate-openMenu',
          isClosingMenu && 'animate-closeMenu',
          'border-0 border-r border-solid px-3',
          withVaultTheme
            ? 'border-r-vault_text/5 bg-vault_background '
            : 'border-r-white/10 bg-black',
        )}
        onPointerDownOutside={() => {
          trackEvent({
            type: EVENTS.CLOSE_MENU,
            properties: { type: 'pointer' },
            pathname: location.pathname,
          });

          closeAll();
          setIsAccountOpen(false);
        }}
      >
        {children}
      </Content>
    </Overlay>
  );
}

function BottomMenuLinks({
  closeAll,
  withVaultTheme,
}: {
  closeAll: () => void;
  withVaultTheme: boolean;
}) {
  const { loginStatus } = useAuthContext();

  if (loginStatus !== LoginStatus.LOGGED_IN) return null;

  return (
    <>
      <MenuLink
        icon={faCog}
        label="Settings"
        onClick={() => {
          trackEvent({
            type: EVENTS.MENU_NAVIGATE,
            properties: { type: 'explore' },
            pathname: location.pathname,
          });

          closeAll();
        }}
        to={normalizePath(ROUTES.SETTINGS)}
        withVaultTheme={withVaultTheme}
      />
    </>
  );
}

function MenuLink({
  icon,
  label,
  onClick,
  to,
  withVaultTheme,
}: {
  icon: FontAwesomeIconProps['icon'];
  label: string;
  onClick: () => void;
  to: LinkProps['to'];
  withVaultTheme: boolean;
}) {
  return (
    <Link
      className={twMerge(
        'my-2 flex min-h-11 flex-row items-center justify-start gap-3 rounded-xl pl-3 text-[unset] no-underline transition-all duration-300 ease-in-out md2:pl-2',
        withVaultTheme
          ? 'text-vault_text/50 hover:bg-vault_text/10 hover:text-vault_text'
          : 'text-white hover:bg-white/10',
      )}
      to={to}
      onClick={onClick}
    >
      <View className="flex aspect-square w-8 flex-shrink-0 flex-col items-center justify-center rounded-full">
        <FontAwesomeIcon icon={icon} fontSize={16} />
      </View>
      <Text className="w-full justify-start overflow-hidden font-base text-[14px] font-medium">
        {label}
      </Text>
    </Link>
  );
}

function VaultsList({
  selectedHandleMemo,
  withVaultTheme,
  closeAll,
}: {
  selectedHandleMemo: string | null | undefined;
  withVaultTheme: boolean;
  closeAll: () => void;
}) {
  const { loginStatus } = useAuthContext();
  const subscribedArtistsRef = React.useRef<HTMLDivElement>(null);
  const { isDesktop } = useWindow();

  return (
    <View
      containerRef={subscribedArtistsRef}
      className={twMerge(
        'overflow-auto pr-1 scrollbar-thin scrollbar-none scrollbar-track-transparent scrollbar-track-rounded-md scrollbar-thumb-rounded-md scrollbar-w-1',
        'scrollbar-thumb-vault_text/50 md2:pr-0',
      )}
    >
      {loginStatus === LoginStatus.LOGGED_IN ? (
        <>
          <SubscribedArtists
            selectedHandleMemo={selectedHandleMemo}
            closeAll={closeAll}
            source="menu"
          />

          {!isDesktop && (
            <Link
              className={twMerge(
                'flex min-h-14 flex-row items-center justify-start gap-3 rounded-xl pl-3 text-[unset] no-underline transition-all duration-300 ease-in-out md2:pl-2',
                withVaultTheme ? 'hover:bg-vault_text/20' : 'hover:bg-white/20',
              )}
              to={normalizePath(ROUTES.VAULTS)}
              onClick={() => {
                trackEvent({
                  type: EVENTS.MENU_NAVIGATE,
                  properties: { type: 'vaults' },
                  pathname: location.pathname,
                });

                closeAll();
              }}
            >
              <View
                className={twMerge(
                  'flex aspect-square w-8 flex-shrink-0 flex-col items-center justify-center rounded-full',
                  withVaultTheme ? 'bg-vault_text/10' : 'bg-white/15',
                )}
              >
                <FontAwesomeIcon
                  icon={faWaveformLines}
                  className={withVaultTheme ? 'text-vault_text' : 'text-white'}
                  fontSize={12}
                />
              </View>
              <Text
                className={twMerge(
                  'justify-start overflow-hidden font-base text-[14px] font-medium transition-all ease-in-out md2:w-0 md2:opacity-0 md2:group-hover/menu:w-auto md2:group-hover/menu:opacity-100 md2:group-hover/menu:delay-75',
                  withVaultTheme ? 'text-vault_text' : 'text-white',
                )}
              >
                Explore Vaults
              </Text>
            </Link>
          )}
        </>
      ) : (
        loginStatus === LoginStatus.LOGGED_OUT && (
          <>
            {!HIDDEN_EXPLORE_VAULTS_NAVIGATION && (
              <Link
                className={twMerge(
                  'group flex min-h-14 flex-row items-center justify-start gap-3 rounded-xl px-3 text-[unset] no-underline',
                  'overflow-hidden transition-all ease-in-out md2:opacity-0 md2:group-hover/menu:opacity-100 md2:group-hover/menu:delay-75',
                )}
                to={normalizePath(ROUTES.VAULTS)}
                onClick={() => {
                  trackEvent({
                    type: EVENTS.MENU_NAVIGATE,
                    properties: { type: 'vaults' },
                    pathname: location.pathname,
                  });

                  closeAll();
                }}
              >
                <Text
                  className={twMerge(
                    'w-full justify-start font-title !text-title-m font-normal transition-all duration-300 ease-in-out',
                    withVaultTheme
                      ? 'text-vault_text group-hover:text-vault_text/50'
                      : 'text-white group-hover:text-base300',
                  )}
                >
                  Explore Vaults
                </Text>
              </Link>
            )}

            {!HIDDEN_ABOUT_NAVIGATION && (
              <Link
                className={twMerge(
                  'group flex min-h-14 flex-row items-center justify-start gap-3 rounded-xl px-3 text-[unset] no-underline',
                  'overflow-hidden transition-all ease-in-out md2:opacity-0 md2:group-hover/menu:opacity-100 md2:group-hover/menu:delay-75',
                )}
                to={normalizePath(ROUTES.ABOUT)}
                onClick={() => {
                  trackEvent({
                    type: EVENTS.MENU_NAVIGATE,
                    properties: { type: 'about' },
                    pathname: location.pathname,
                  });

                  closeAll();
                }}
              >
                <Text
                  className={twMerge(
                    'w-full justify-start font-title !text-title-m font-normal transition-all duration-300 ease-in-out',
                    withVaultTheme
                      ? 'text-vault_text group-hover:text-vault_text/50'
                      : 'text-white group-hover:text-base300',
                  )}
                >
                  About
                </Text>
              </Link>
            )}
          </>
        )
      )}
    </View>
  );
}

const MenuLinks = memo(({ selectedHandleMemo }: { selectedHandleMemo: string }) => {
  const location = useLocation();
  const { loggedInUser, loginStatus } = useAuthContext();

  const adminArtist = useAdminArtist({ artistHandle: selectedHandleMemo });
  const isOwner = !!adminArtist;

  const { data, isLoading } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: {
      link: selectedHandleMemo.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
      selectedHandleMemo,
    },
    keepPreviousData: true,
  });

  const mainVault = data?.data.artistLink?.artist.mainVault;
  const artistId = data?.data.artistLink?.artist.id;
  const vaultId = mainVault?.id;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: mainVault?.activeSubscription,
    isOwner,
  });

  const chatAvailableForFreeUsers = mainVault?.tiers
    ?.find(tier => tier.__typename === TierTypename.FreeTier)
    ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatRead);

  const hasChatReadAccess = activeSubscriptionFeatures?.enabledFeatures.ChatRead === true;

  const isNotFoundPage = location.pathname.includes('/404');

  // This prevents the menu from showing on non vault pages
  if (!vaultId || !artistId || isNotFoundPage) {
    return null;
  }

  return (
    <View
      className={twMerge(
        'overflow-auto scrollbar-thin scrollbar-none scrollbar-track-transparent scrollbar-track-rounded-md scrollbar-thumb-rounded-md scrollbar-w-1',
        'flex w-full flex-col gap-1 pr-0 scrollbar-thumb-vault_text/50',
      )}
    >
      <NavigationLinks
        selectedHandleMemo={selectedHandleMemo}
        messageChannelId={mainVault.messageChannelId}
        hasChatReadAccess={hasChatReadAccess}
        chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
        vaultId={vaultId}
        isLoading={isLoading || loginStatus === LoginStatus.LOADING}
      />
    </View>
  );
});
