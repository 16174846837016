import { useEffect, useState } from 'react';
import { useLoginWithSms } from '@privy-io/react-auth';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faAngleLeft } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { AUTH_ERROR_ACTIONS, ERROR_TYPE, PILLARS } from '@soundxyz/vault-logs-utils/dist/constants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useLogError } from '../../hooks/logger/useLogError';
import { useSignIn } from '../../hooks/useSignIn';
import { useTimer } from '../../hooks/useTimer';
import { OnLoginAction } from '../../providers/AuthProvider';
import { SignInStore } from '../../screens/auth/store';
import { LoginStatus } from '../../types/authTypes';

import { Button } from '../buttons/Button';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { CodeInput } from '../forms/CodeInput';

export function VerifyForm({
  setView,
  onConfirm,
  isPreview,
}: {
  setView: (view: 'sign_in' | 'verify') => void;
  onConfirm: () => void;
  isPreview: boolean;
}) {
  const { loginStatus, authError } = useAuthContext();
  const [errorText, setErrorText] = useState<string | null>(null);
  const logError = useLogError();

  const {
    loginWithCode,
    state: { status },
  } = useLoginWithSms();
  const { onResendCodeClick } = useSignIn();

  useEffect(() => {
    if (authError != null) {
      setErrorText('We encountered an error creating your profile');
    }
  }, [authError]);

  const signInState = SignInStore.useStore().value;

  const { pathname } = useLocation();

  const phoneNumber = signInState?.lastActivePhoneNumber;

  const hasError = errorText != null;

  const codeRenabled = signInState?.codeRenabled[phoneNumber ?? '_'] ?? 1;

  const { seconds: codeSentDisabledSecondsRemaining, isRunning: isInCooldown } = useTimer({
    expiryTimestamp: codeRenabled,
  });

  const tryLogin = async (code: string): Promise<void> => {
    OnLoginAction.action = onConfirm;
    try {
      await loginWithCode({ code });
      // Still loading, need to wait for AuthProvider loginStatus to update
    } catch (e) {
      logError({
        action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
        error: e,
        errorType: ERROR_TYPE.AUTH_ERROR,
        level: 'warning',
        message: 'We encountered an error sending your verification code',
        pillar: PILLARS.AUTH,
        indexedTags: {
          phoneNumber,
        },
        unindexedExtra: {
          source: 'VerifyForm - tryLogin',
        },
      });
      setErrorText('We encountered an error verifying your code');

      return;
    }
  };

  const onChange = (value: string) => {
    const newVal = value.replace(/\D/g, '').replace(/\s/g, ''); // Remove non-digit characters

    if (newVal.length === 6) {
      void tryLogin(newVal);
    }

    setErrorText(null);
  };

  return (
    <View
      className={twMerge(
        'mt-6 box-border h-[174px] min-h-[174px] w-full max-w-[364px] flex-col rounded-[24px] bg-white/10 p-4 backdrop-blur-2xl',
        !isPreview && 'md2:max-w-none',
      )}
    >
      <View className="flex items-center justify-between">
        <Button
          iconOnly
          label="Back"
          leadingIcon={faAngleLeft}
          className="select-none text-[24px] text-white outline-none"
          onClick={() => setView('sign_in')}
        />

        <Text className="text-center font-title text-[22px] font-medium text-white">Verify</Text>

        <View className="w-6" />
      </View>

      <View className="mt-4 box-border flex w-full flex-col items-center justify-center">
        <CodeInput
          autoFocus
          length={6}
          placeholder="_"
          onChange={onChange}
          allowedCharacters="numeric"
          hasError={hasError}
          variant="transparent"
        />

        <Text className="mt-4 text-center font-base text-[14px]/[14px] text-white/50">
          Didn't receive the code yet?
        </Text>

        <button
          className="appearance-none border-none bg-transparent outline-none hover:cursor-pointer focus:outline-none"
          onClick={() => {
            onResendCodeClick({
              codeSentDisabledSecondsRemaining,
              source: 'VerifyForm',
              pathname,
            });
          }}
          disabled={
            codeSentDisabledSecondsRemaining > 0 ||
            status === 'sending-code' ||
            status === 'submitting-code' ||
            isInCooldown ||
            loginStatus === LoginStatus.LOADING
          }
        >
          <Text
            className={twMerge(
              'mt-2 cursor-pointer text-center font-base text-[14px]/[14px] underline',
              hasError ? 'text-destructive300' : 'text-white',
            )}
          >
            {codeSentDisabledSecondsRemaining > 0
              ? `Resend in ${codeSentDisabledSecondsRemaining}s.`
              : 'Resend'}
          </Text>
        </button>
      </View>
    </View>
  );
}
