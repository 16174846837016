import { useMemo } from 'react';
import { gql } from '@soundxyz/gql-string';
import { useQuery } from '../../../graphql/client';
import {
  AnnouncementSourceType,
  CampaignToShareDocument,
  ReleaseCampaignState,
  RsvpEventToShareDocument,
} from '../../../graphql/generated';
import { generateShareLink } from '../../../utils/linkUtils';
import { Image } from '../../common/Image';
import { View } from '../../common/View';
import { ErrorView } from '../../error/ErrorView';
import { FullPageLoading } from '../../views/FullPageLoading';
import { BaseShareView } from './BaseShareView';

gql(/* GraphQL */ `
  query RsvpEventToShare($dropSlug: String!, $artistHandle: String!) {
    rsvpEventByLink(eventLinkValue: $dropSlug, artistHandle: $artistHandle) {
      __typename
      id
      title
      artist {
        id
        name
        profileImage {
          id
          profileImageUrl: imageOptimizedUrl(input: { width: 480, height: 480 })
        }
      }
      ... on RsvpEventPrivateInfo {
        coverImage {
          id
          coverImageUrl: imageOptimizedUrl(input: { width: 480, height: 480 })
        }
      }
    }
  }

  query CampaignToShare($dropSlug: String!, $artistHandle: String!) {
    releaseCampaignByIdOrSlug(slug: $dropSlug, artistHandle: $artistHandle) {
      __typename
      id
      title
      artist {
        id
        name
        profileImage {
          id
          profileImageUrl: imageOptimizedUrl(input: { width: 480, height: 480 })
        }
      }
      ... on ReleaseCampaignPrivateInfo {
        currentState
        initialReleaseImageUrl
        coverImage {
          id
          coverImageUrl: imageOptimizedUrl(input: { width: 480, height: 480 })
        }
      }
    }
  }
`);

export function ShareDropView({
  dropSlug,
  artistHandle,
  type,
}: {
  dropSlug: string;
  artistHandle: string;
  type: 'rsvp' | 'campaign';
}) {
  const { data, isLoading, isError, refetch } = useDropToShare({ dropSlug, artistHandle, type });

  const shareLink = useMemo(() => {
    return generateShareLink({
      artistLinkValue: artistHandle,
      path: `/${type === 'rsvp' ? 'd' : 's'}/${dropSlug}`,
      inviteCode: null,
    });
  }, [artistHandle, dropSlug, type]);

  if (isError) {
    return <ErrorView onRetryClick={refetch} withVaultTheme className="md2:w-full" />;
  }

  if (data == null || isLoading) {
    return <FullPageLoading withVaultTheme />;
  }

  const { title, artist, coverImage, initialReleaseImageUrl } = data;

  const prefilledMessage =
    data.__typename === 'ReleaseCampaignPrivateInfo'
      ? `${title} is available for ${
          data.currentState === ReleaseCampaignState.Streaming ? 'streaming' : 'pre-save'
        } now!\n\n${shareLink}`
      : `RSVP for ${title} in my Vault!\n\n${shareLink}`;

  return (
    <BaseShareView
      title={title ?? 'Untitled'}
      artistHandle={artistHandle}
      artistName={artist.name}
      contentTypeText="drop"
      sourceId={data.id}
      sourceType={AnnouncementSourceType.ArtistEvent}
      imageElement={
        <ImageElement
          imageUrl={
            coverImage?.coverImageUrl ??
            initialReleaseImageUrl ??
            artist.profileImage?.profileImageUrl
          }
        />
      }
      url={shareLink}
      prefilledMessage={prefilledMessage}
    />
  );
}

function ImageElement({ imageUrl }: { imageUrl: string | null | undefined }) {
  return (
    <View className="flex h-[240px] w-[240px] flex-col items-center justify-center overflow-hidden rounded-xl bg-vault_text/5">
      {!!imageUrl && <Image src={imageUrl} alt="Drop image" className="h-full w-full" />}
    </View>
  );
}

function useDropToShare({
  dropSlug,
  artistHandle,
  type,
}: {
  dropSlug: string;
  artistHandle: string;
  type: 'rsvp' | 'campaign';
}) {
  const {
    data: rsvpData,
    isLoading: rsvpIsLoading,
    isError: rsvpIsError,
    refetch: rsvpRefetch,
  } = useQuery(RsvpEventToShareDocument, {
    staleTime: 0,
    variables: { dropSlug, artistHandle },
    select: data =>
      data.data.rsvpEventByLink?.__typename === 'RsvpEventPrivateInfo'
        ? data.data.rsvpEventByLink
        : null,
    enabled: type === 'rsvp',
  });

  const {
    data: campaignData,
    isLoading: campaignIsLoading,
    isError: campaignIsError,
    refetch: campaignRefetch,
  } = useQuery(CampaignToShareDocument, {
    staleTime: 0,
    variables: { dropSlug, artistHandle },
    select: data =>
      data.data.releaseCampaignByIdOrSlug?.__typename === 'ReleaseCampaignPrivateInfo'
        ? data.data.releaseCampaignByIdOrSlug
        : null,
    enabled: type === 'campaign',
  });

  return {
    data:
      type === 'rsvp'
        ? rsvpData != null
          ? { ...rsvpData, initialReleaseImageUrl: null }
          : null
        : campaignData,
    isLoading: type === 'rsvp' ? rsvpIsLoading : campaignIsLoading,
    isError: type === 'rsvp' ? rsvpIsError : campaignIsError,
    refetch: type === 'rsvp' ? rsvpRefetch : campaignRefetch,
  };
}
