import { useEffect } from 'react';

type CloseFunction = (reason: 'swipe' | 'pointer' | 'esc' | 'other' | 'button') => void;

export const useEscapeKeyListener = (
  isOpen: boolean,
  closeFunction: CloseFunction,
  isUnclosable?: boolean,
) => {
  useEffect(() => {
    if (!isOpen || isUnclosable) return;

    const listener = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closeFunction('esc');
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', listener);

      return () => {
        document.removeEventListener('keydown', listener);
      };
    }

    return;
  }, [closeFunction, isOpen, isUnclosable]);
};
