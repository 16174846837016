import type { Draft } from 'immer';
import { produce } from 'immer';
import { parse, stringify } from 'superjson';
import { proxy, useSnapshot } from 'valtio';

import type { ZodType, ZodTypeDef } from 'zod';
import { ENVIRONMENT } from '@soundxyz/utils/const';
import { LazyPromise } from '@soundxyz/utils/promise';

interface PersistedStoreState<Output> {
  value: Output | null;
  isLoading: boolean;
}

type PersistedStore<Input, Output> = {
  get: () => Promise<Output | null>;
  set: (value: Input) => Promise<Output | null>;
  setFromString: (value: string) => Promise<Output | null>;
  state: PersistedStoreState<Output>;
  useStore(): PersistedStoreState<Output>;
  initialValue: Promise<Output | null>;
  clear(): Promise<void>;
  produceExistingState(
    callback: (draft: Draft<Output>) => void,
    defaultValue: Input,
  ): Promise<Output | null>;
  onTabSync(callback: (value: Output | null) => void): () => void;
  notifyTabSync(value: Output | null): void;
};

const PersistedStores: Map<string, PersistedStore<unknown, unknown>> = new Map();

interface PersistenceStorageInput<
  Output = unknown,
  Input = Output,
  Def extends ZodTypeDef = ZodTypeDef,
> {
  /**
   * Parse and validation schema
   */
  schema: ZodType<Output, Def, Input>;
  /**
   * Unique key combination
   */
  key: string;
  /**
   * Should the initial value be eagerly requested?
   */
  eager?: boolean;
}

const PersistedStoresFromStorageKeys: Map<string, PersistedStore<unknown, unknown>> = new Map();

if (typeof window !== 'undefined') {
  window.addEventListener('storage', async e => {
    if (!e.key) return;

    try {
      const store = PersistedStoresFromStorageKeys.get(e.key);

      if (!store) return;

      if (e.newValue) {
        const newValue = await store.setFromString(e.newValue);

        store.notifyTabSync(newValue);
      } else {
        await store.clear();

        store.notifyTabSync(null);
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  });
}

export function PersistenceStorage<
  Output = unknown,
  Input = Output,
  Def extends ZodTypeDef = ZodTypeDef,
>({
  schema,
  key: idempotentStoreKey,
  eager,
}: PersistenceStorageInput<Output, Input, Def>): PersistedStore<Input, Output> {
  const existingStore = PersistedStores.get(idempotentStoreKey);

  if (existingStore) return existingStore as PersistedStore<Input, Output>;

  const state = proxy<PersistedStoreState<Output>>({
    value: null,
    isLoading: true,
  });

  const key = `sound-${ENVIRONMENT}-${idempotentStoreKey}`;

  async function setFromString(value: string) {
    const parsedValue = await schema.safeParseAsync(parse(value));

    if (parsedValue.success) {
      return (state.value = parsedValue.data);
    } else {
      await localStorage.removeItem(key);
      throw parsedValue.error;
    }
  }

  async function get(): Promise<Output | null> {
    try {
      const value = localStorage.getItem(key);

      if (value == null) return (state.value = null);

      return await setFromString(value);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      return (state.value = null);
    } finally {
      if (state.isLoading) state.isLoading = false;
    }
  }

  const initialValue = eager ? get() : LazyPromise(() => get());

  async function setStorage(value: Output) {
    try {
      await localStorage.setItem(key, stringify(value));
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);

      return (state.value = null);
    }

    return value;
  }

  async function set(value: Input): Promise<Output | null> {
    const parsedValue = await schema.parseAsync(value);

    state.value = parsedValue;

    return setStorage(parsedValue);
  }

  async function clear() {
    try {
      state.value = null;
      await localStorage.removeItem(key);
    } catch (err) {
      // We ignore browser flaky storage errors

      // eslint-disable-next-line no-console
      console.error(err);
    }
  }

  async function produceExistingState(
    callback: (draft: Draft<Output>) => void,
    defaultValue: Input,
  ): Promise<Output | null> {
    if (state.value == null) {
      return set(defaultValue);
    }

    const newState = produce(state.value, callback);

    // Skip persisting if the value didn't change
    if (newState === state.value) return state.value;

    state.value = newState;

    return setStorage(newState);
  }

  const tabSyncCallbacks: Set<Parameters<PersistedStore<Input, Output>['onTabSync']>[0]> =
    new Set();

  const onTabSync: PersistedStore<Input, Output>['onTabSync'] = function subscribe(callback) {
    tabSyncCallbacks.add(callback);

    return function unsubscribe() {
      tabSyncCallbacks.delete(callback);
    };
  };

  const notifyTabSync: PersistedStore<Input, Output>['notifyTabSync'] = function notifyTabSync(
    value,
  ) {
    /**
     * forEach doesn't wait for async callbacks to finish,
     * and it's faster + less code than doing a `for of` with an IIFE
     */
    tabSyncCallbacks.forEach(async function syncCallback(cb) {
      try {
        await cb(value);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
      }
    });
  };

  const store: PersistedStore<Input, Output> = {
    get,
    set,
    clear,
    initialValue,
    state,
    useStore() {
      return useSnapshot(state);
    },
    produceExistingState,
    setFromString,
    onTabSync,
    notifyTabSync,
  };

  PersistedStoresFromStorageKeys.set(key, store as PersistedStore<unknown, unknown>);

  PersistedStores.set(idempotentStoreKey, store as PersistedStore<unknown, unknown>);

  return store;
}
