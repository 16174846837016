import { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Navigate, useParams } from 'react-router';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { ErrorView } from '../../../components/error/ErrorView';
import { SettingsLayout } from '../../../components/layouts/SettingsLayout';

import { ArtistOverridesList } from '../../../components/settings/notifications/ArtistOverridesList';
import { ToggleView } from '../../../components/settings/notifications/ToggleView';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { ArtistUserSettingsFeature } from '../../../graphql/generated';
import { useAdminArtist } from '../../../hooks/useAdminArtist';
import {
  parseNotificationFeature,
  useArtistUserNotificationSettings,
} from '../../../hooks/useArtistUserNotificationSettings';
import { useBetterGate } from '../../../hooks/useFeatureGate';
import { LoginStatus } from '../../../types/authTypes';

export function FeatureArtistSettings({
  notificationType,
}: {
  notificationType: ArtistUserSettingsFeature;
}) {
  const isUserMessageInGroupChatPushNotificationEnabled =
    useBetterGate('GROUP_CHAT_MESSAGE_PUSH_NOTIF') === 'enabled';
  const { artistHandle } = useParams();
  const { loginStatus } = useAuthContext();
  const {
    dmFromArtist,
    dmFromUser,
    groupChatMessageFromArtist,
    groupChatMessage,
    newVaultContent,
    isError,
    artistName,
    refetch,
    isLoading,
  } = useArtistUserNotificationSettings({ artistHandle: artistHandle ?? '' });

  const adminArtist = useAdminArtist({ artistHandle });
  const isAdminArtist = !!adminArtist;

  const defaultState = useMemo(() => {
    switch (notificationType) {
      case ArtistUserSettingsFeature.DmFromArtist:
        return {
          isPushEnabled: dmFromArtist?.isPushEnabled,
          isSmsEnabled: dmFromArtist?.isSmsEnabled,
          radioFields: dmFromArtist?.radioFields,
        };
      case ArtistUserSettingsFeature.DmFromUser:
        return {
          isPushEnabled: dmFromUser?.isPushEnabled,
          isSmsEnabled: dmFromUser?.isSmsEnabled,
          radioFields: dmFromUser?.radioFields,
        };
      case ArtistUserSettingsFeature.GroupChatMessage:
        return {
          isPushEnabled: groupChatMessage?.isPushEnabled,
          isSmsEnabled: groupChatMessage?.isSmsEnabled,
          radioFields: groupChatMessage?.radioFields,
        };
      case ArtistUserSettingsFeature.GroupChatMessageFromArtist:
        return {
          isPushEnabled: groupChatMessageFromArtist?.isPushEnabled,
          isSmsEnabled: groupChatMessageFromArtist?.isSmsEnabled,
          radioFields: groupChatMessageFromArtist?.radioFields,
        };
      case ArtistUserSettingsFeature.NewVaultContent:
        return {
          isPushEnabled: newVaultContent?.isPushEnabled,
          isSmsEnabled: newVaultContent?.isSmsEnabled,
          radioFields: newVaultContent?.radioFields,
        };
      default:
        return {
          isPushEnabled: null,
          isSmsEnabled: null,
          radioFields: null,
        };
    }
  }, [
    dmFromArtist?.isPushEnabled,
    dmFromArtist?.isSmsEnabled,
    dmFromArtist?.radioFields,
    dmFromUser?.isPushEnabled,
    dmFromUser?.isSmsEnabled,
    dmFromUser?.radioFields,
    groupChatMessage?.isPushEnabled,
    groupChatMessage?.isSmsEnabled,
    groupChatMessage?.radioFields,
    groupChatMessageFromArtist?.isPushEnabled,
    groupChatMessageFromArtist?.isSmsEnabled,
    groupChatMessageFromArtist?.radioFields,
    newVaultContent?.isPushEnabled,
    newVaultContent?.isSmsEnabled,
    newVaultContent?.radioFields,
    notificationType,
  ]);

  if (
    (isAdminArtist &&
      notificationType !== ArtistUserSettingsFeature.DmFromUser &&
      notificationType !== ArtistUserSettingsFeature.GroupChatMessage) ||
    loginStatus === LoginStatus.LOGGED_OUT
  ) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }
  if (isError || artistHandle == null) {
    return (
      <SettingsLayout title={`${artistName ?? 'Artist'} notifications`}>
        <ErrorView onRetryClick={refetch} withVaultTheme={false} />
      </SettingsLayout>
    );
  }

  if (isLoading || loginStatus === LoginStatus.LOADING) {
    return (
      <SettingsLayout title="">
        <FontAwesomeIcon
          icon={faSpinner}
          size="3x"
          className="inline-block animate-spin items-center self-center rounded-full font-medium text-vault_text/50"
        />
      </SettingsLayout>
    );
  }

  return (
    <SettingsLayout
      title={
        parseNotificationFeature({
          feature: notificationType,
          isUserMessageInGroupChatPushNotificationEnabled,
        }).label
      }
      childrenWrapperClassName="space-y-6"
    >
      <ToggleView
        feature={notificationType}
        artistHandle={artistHandle}
        isPushEnabled={defaultState.isPushEnabled}
        isSmsEnabled={defaultState.isSmsEnabled}
        radioFields={defaultState.radioFields ?? []}
      />

      {notificationType === ArtistUserSettingsFeature.DmFromUser && (
        <ArtistOverridesList
          artistHandle={artistHandle}
          notificationType={notificationType}
          asArtistId={adminArtist?.artistId}
        />
      )}
    </SettingsLayout>
  );
}
