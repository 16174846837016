import { getAccessToken } from '@privy-io/react-auth';
import { Sentry } from '../sentry';
import { UserProfileState } from '../store/userProfile';

const X_VAULT_PROFILE_ID_HEADER = 'x-vault-user-profile-id';

export async function getAuthToken() {
  const authToken = await getAccessToken().catch(error => {
    Sentry.captureException(error, {
      tags: {
        type: 'getAuthToken',
      },
    });

    return null;
  });

  if (!authToken) return {};

  const profileState = UserProfileState.state.value;

  const currentPrivyId = profileState?.currentPrivyId;

  if (!currentPrivyId) {
    return {
      'auth-token': authToken,
    };
  }

  const userProfileId = profileState.store?.[currentPrivyId]?.userId;

  if (!userProfileId) {
    return {
      'auth-token': authToken,
    };
  }

  return {
    'auth-token': authToken,
    [X_VAULT_PROFILE_ID_HEADER]: userProfileId,
  };
}
