import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { faArrowUpFromBracket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faTrophy } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faPalette } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { faBell } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { isUUID4 } from '@soundxyz/utils/validation';
import { loadTrack } from '../../audio/AudioController';
import { Button } from '../../components/buttons/Button';
import { MenuButton } from '../../components/buttons/MenuButton';
import { View } from '../../components/common/View';
import { ErrorView } from '../../components/error/ErrorView';
import { ArtistLayout } from '../../components/layouts/ArtistLayout';
import { DefaultLayout } from '../../components/layouts/DefaultLayout';
import { openFullscreenPlayer } from '../../components/main/AudioPlayer';
import { MediaViewer } from '../../components/message/MediaViewer';
import { useSetMetaHeaders } from '../../components/metatags/MetatagsHeader';
import { useListenNowModal } from '../../components/modals/ListenNowModal';
import { PreviewToast } from '../../components/vault/PreviewToast';
import { VaultContentSkeleton } from '../../components/vault/VaultContents';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { ReferralLinkView } from '../../components/views/ReferralLinkView';
import {
  lurkState,
  VaultLandingError,
  VaultLandingLoading,
  VaultLandingView,
} from '../../components/views/VaultLandingView';
import { VaultView } from '../../components/views/VaultView';
import { BOTTOMSHEET_TYPES } from '../../constants/bottomsheetConstants';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useMenuContainer } from '../../contexts/MenuContext';
import { useOverlayContainer } from '../../contexts/OverlayContext';
import { useToast } from '../../contexts/ToastContext';
import { fetchQuery, useQuery } from '../../graphql/client';
import {
  ArtistByHandleDocument,
  MediaType,
  VisualVaultContentDocument,
} from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { useReferralLinks } from '../../hooks/useReferralLinks';
import { useStableCallback } from '../../hooks/useStableCallback';
import { useVaultTheme } from '../../hooks/useVaultTheme';
import { useWindow } from '../../hooks/useWindow';
import { useSelectVaultContent } from '../../hooks/vault/useSelectVaultContent';
import { LoginStatus } from '../../types/authTypes';
import { EVENTS } from '../../types/eventTypes';
import { generateShareLink } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { usePageChecks } from '../../utils/pathUtils';
import { constructQueryParams } from '../../utils/stringUtils';
import { TopNavButton } from './TopNavButton';

gql(/* GraphQL */ `
  query ArtistByHandle($link: String!) {
    artistLink(link: $link) {
      artist {
        id
        name
        linkValue
        mainVaultId
        mainVault {
          id
          messageChannelId
          type
          tiers {
            __typename
            enabledFeatures {
              feature {
                __typename
              }
            }
          }
          activeSubscription {
            id
            status
            ...ActiveSubscriptionFeatures
          }
          isUserArtistAdmin
          welcomeMessage
        }
        profileImage {
          id
          artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
          artistMediumProfileImageUrl: imageOptimizedUrl(input: { width: 600, height: 600 })
          dominantColor
        }
        phoneNumbers {
          phoneNumber
          phoneCountryCode
        }
        membershipCardImage {
          id
          membershipCardImageUrl: imageOptimizedUrl
        }
        ...artistLayout
        ...artistMainVaultView
        ...artistLanding
      }
    }
  }

  query VisualVaultContent($vaultContentId: UUID!) {
    vaultContentById(vaultContentId: $vaultContentId) {
      __typename
      id
      title
      linkValue
      ... on VaultImage {
        id
        blurredMediaUrl
        uploadedMedia {
          id
          imageUrl: imageOptimizedUrl
          mediaType
        }
      }
      ... on VaultVideo {
        id
        blurredMediaUrl
        uploadedMedia {
          id
          url: cdnUrl
          mediaType
        }
      }
    }
  }
`);

export const VaultPage = () => {
  const [searchParams] = useSearchParams();
  const { loginStatus } = useAuthContext();

  const { isLurking } = useSnapshot(lurkState);
  const code = searchParams.get('code'); // referral link code
  useVaultTheme();

  // Show the generic Full Page Loading cause we don't know if the user is logged in or not
  if (loginStatus === LoginStatus.LOADING) {
    return <FullPageLoading withVaultTheme />;
  }

  // If the user is logged out, not lurking and not referral page, show the landing page
  if (loginStatus === LoginStatus.LOGGED_OUT && !isLurking && !code) {
    return <LandingPage />;
  }

  // If the user is logged in or lurking, show the content page
  return <Page />;
};

function Page() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { artistHandle } = useArtistHandle();
  const { loggedInUser, loginStatus } = useAuthContext();
  const { openToast } = useToast();
  const { openListenNowModal } = useListenNowModal();

  const { openVaultCustomization, isVaultCustomizeOpen } = useMenuContainer();

  const code = searchParams.get('code'); // referral link code
  const trackId = searchParams.get('trackId');
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const bottomSheetType = searchParams.get('openBottomSheet');

  const {
    referralCodeData,
    isLoadingReferralCode,
    error: referralCodeError,
    refetch: refetchReferralCode,
  } = useReferralLinks(code);

  const scrollRef = useRef<HTMLDivElement>(null);
  const [showProfileImage, setShowProfileImage] = useState(false);
  const { openBottomsheet, closeBottomsheet } = useBottomsheetContainer();
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { isDesktop } = useWindow();

  const isOwner =
    artistHandle != null &&
    (loggedInUser?.adminArtists?.some(({ artistLinks }) => artistLinks.includes(artistHandle)) ??
      false);

  const { isError, isLoading, data, refetch } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: artistHandle != null,
  });

  const vaultId = data?.data.artistLink?.artist.mainVaultId;

  const hasActiveSubscription = !!data?.data.artistLink?.artist.mainVault.activeSubscription?.id;
  const showInvitePageIcon = false;

  useEffect(() => {
    if (isLoading) return;

    if (trackId != null && isUUID4(trackId)) {
      fetchQuery(VisualVaultContentDocument, { variables: { vaultContentId: trackId } }).then(
        data => {
          if (data.data.vaultContentById == null) return;

          const { __typename, title, linkValue } = data.data.vaultContentById;

          if (__typename === 'VaultTrack' && vaultId) {
            if (hasActiveSubscription || isOwner) {
              if (isDesktop) {
                openListenNowModal({
                  trackId,
                  vaultId,
                });
              } else {
                loadTrack({
                  trackId,
                  vaultId,
                  folderId: null,
                  // without user interaction, autoplay is not allowed
                  autoplay: false,
                  component: 'track_landing_page',
                }).then(() => {
                  openFullscreenPlayer();
                });
              }
            } else {
              navigate(
                artistNavigationPath(artistHandle, linkValue ? `/t/${linkValue}` : `/${trackId}`),
              );
            }
          } else if (__typename === 'VaultFolder') {
            navigate(
              artistNavigationPath(
                artistHandle,
                linkValue ? `/f/${linkValue}` : `/folder/${trackId}`,
              ),
            );
          } else if (__typename === 'VaultImage' || __typename === 'VaultVideo') {
            if (!hasActiveSubscription && !isOwner) {
              const typeName = __typename === 'VaultImage' ? 'i' : 'v';

              return navigate(
                artistNavigationPath(
                  artistHandle,
                  linkValue ? `/${typeName}/${linkValue}` : `/${typeName}/${trackId}`,
                ),
              );
            }
            const { uploadedMedia, blurredMediaUrl } = data.data.vaultContentById;

            const mediaUrl =
              uploadedMedia && 'imageUrl' in uploadedMedia
                ? uploadedMedia.imageUrl
                : uploadedMedia?.url;

            if (mediaUrl != null && uploadedMedia != null) {
              const { id, mediaType } = uploadedMedia;
              openOverlay(
                <MediaViewer
                  title={title ?? ''}
                  medias={[{ id, url: mediaUrl, type: mediaType }]}
                  onClose={closeOverlay}
                />,
              );
            } else if (blurredMediaUrl != null) {
              openOverlay(
                <MediaViewer
                  title={title ?? ''}
                  medias={[{ id: trackId, url: blurredMediaUrl, type: MediaType.Image }]}
                  onClose={closeOverlay}
                />,
              );
            }
          }
        },
      );
    }
  }, [
    artistHandle,
    closeOverlay,
    hasActiveSubscription,
    isDesktop,
    isLoading,
    isOwner,
    navigate,
    openListenNowModal,
    openOverlay,
    trackId,
    vaultId,
  ]);

  useEffect(() => {
    if (code && referralCodeData === null) {
      navigate(window.location.pathname, { replace: true });
      openToast({
        text: 'Referral code does not exist',
        variant: 'error',
      });
    }
  }, [code, referralCodeData, navigate, openToast]);

  const onShareClick = useStableCallback(() => {
    const link = generateShareLink({
      artistLinkValue: data?.data.artistLink?.artist.linkValue,
      inviteCode: loggedInUser?.adminArtists?.some(
        adminArtist => adminArtist.artistMainVaultId === vaultId,
      )
        ? null
        : loggedInUser?.inviteCode,
      path: null,
    });

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.SHARE,
      shared: {
        withVaultTheme: true,
      },
      shareBottomsheetProps: {
        link,
        artistName: data?.data.artistLink?.artist.name ?? 'vault',
        withVaultTheme: true,
      },
    });
  });

  useSetMetaHeaders({
    title: data?.data.artistLink ? `${data.data.artistLink?.artist.name}'s Vault` : null,
    imageUrl: data?.data.artistLink?.artist.profileImage?.artistSmallProfileImageUrl ?? undefined,
  });

  useEffect(() => {
    if (loginStatus !== LoginStatus.LOGGED_IN || !loggedInUser || !!code) return;

    if (loggedInUser?.username == null) {
      const queryParams = constructQueryParams({
        artistHandle,
        invite,
        smsCampaignResponseShortcode,
        openBottomSheet: bottomSheetType,
      });
      navigate(`${ROUTES.ONBOARDING_USERNAME}${queryParams ? `?${queryParams}` : ''}`);
    }
  }, [
    artistHandle,
    bottomSheetType,
    code,
    invite,
    loggedInUser,
    loggedInUser?.username,
    loginStatus,
    navigate,
    smsCampaignResponseShortcode,
  ]);

  const { setIsSelecting, isSelecting } = useSelectVaultContent();

  useEffect(() => {
    if (!isOwner) {
      setIsSelecting(false);
    }
  }, [isOwner, setIsSelecting]);

  const onVaultInvitesClick = useStableCallback(() => {
    navigate(artistNavigationPath(artistHandle, '/invites'));
  });

  const [isVaultPage] = usePageChecks({
    pages: ['vault'],
  });

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (!isOwner && isVaultPage && loginStatus !== LoginStatus.LOADING) {
    return <Navigate to={artistNavigationPath(artistHandle, '/')} replace />;
  }

  if (isOwner && !isVaultPage && !trackId) {
    return <Navigate to={artistNavigationPath(artistHandle, '/vault')} replace />;
  }

  if (isError || referralCodeError) {
    return (
      <DefaultLayout
        ref={scrollRef}
        showBorder
        showRoundedTop
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        headerLeft={!isDesktop ? <MenuButton className="h-12" withVaultTheme /> : null}
        isHeaderTransparent={!showProfileImage}
        shouldSkipMargin
        withVaultTheme
        headerClassName={
          showProfileImage && !isDesktop
            ? 'border-0 border-solid border-b border-vault_text/5 bg-vault_background'
            : 'bg-transparent'
        }
        nonScrollingChildren={
          <ErrorView
            className="flex-grow"
            onRetryClick={isError ? refetch : refetchReferralCode}
            loggingType="vault_page"
            withVaultTheme
          />
        }
      />
    );
  }

  if (isLoading || data == null || isLoadingReferralCode) {
    return (
      <DefaultLayout
        ref={scrollRef}
        showBorder
        showRoundedTop
        headerLeft={!isDesktop ? <MenuButton className="h-12" withVaultTheme /> : null}
        isHeaderTransparent={!showProfileImage}
        hasChatReadAccess={false}
        messageChannelId={undefined}
        vaultId={undefined}
        withBottomNavigator={false}
        withVaultTheme
        shouldSkipMargin
        headerClassName={
          showProfileImage && !isDesktop
            ? 'border-0 border-solid border-b border-vault_text/5 bg-vault_background'
            : 'bg-transparent'
        }
      >
        <VaultContentSkeleton />
      </DefaultLayout>
    );
  }

  if (data.data.artistLink == null) {
    // TODO: Handle artist is null
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (code && referralCodeData) {
    return (
      <ReferralLinkView
        code={code}
        referralCodeInfo={referralCodeData}
        artistHandle={artistHandle}
      />
    );
  }

  return (
    <ArtistLayout
      artist={data.data.artistLink.artist}
      showProfileImage={showProfileImage}
      ref={scrollRef}
      rightHeader={
        <View className="relative flex max-h-12 min-h-12 items-center gap-4 md2:max-h-5 md2:min-h-5">
          {isSelecting && isOwner ? (
            <View className="absolute right-0">
              <Button
                label="Cancel"
                type="secondary"
                onClick={() => {
                  setIsSelecting(false);
                }}
                className="rounded-full bg-base800 px-4 py-3 text-[14px]/[18px] font-medium text-white"
              />
            </View>
          ) : (
            <>
              {showInvitePageIcon && (
                <TopNavButton
                  showBlur={showProfileImage}
                  leadingIcon={faTrophy}
                  disabled={isVaultCustomizeOpen}
                  onClick={e => {
                    e.stopPropagation();
                    onVaultInvitesClick();
                  }}
                  event={{
                    type: EVENTS.VAULT_INVITES,
                    properties: {
                      artistHandle,
                    },
                  }}
                />
              )}

              <View className="flex gap-2">
                {isOwner && !!vaultId && (
                  <Button
                    label=""
                    iconOnly
                    className="rounded-full bg-vault_background/70 p-2 text-[16px] text-vault_text no-underline"
                    leadingIcon={faPalette}
                    leadingIconClassName="p-1"
                    onClick={() => {
                      closeBottomsheet();
                      setTimeout(() => openVaultCustomization({ vaultId }), 300);
                    }}
                  />
                )}
                <Button
                  label=""
                  iconOnly
                  className="rounded-full bg-vault_background/70 p-2 text-[16px] text-vault_text no-underline"
                  leadingIcon={faArrowUpFromBracket}
                  leadingIconClassName="p-1"
                  onClick={e => {
                    e.stopPropagation();
                    onShareClick();
                  }}
                  event={{
                    type: EVENTS.OPEN_BOTTOMSHEET,
                    properties: {
                      bottomsheetType: BOTTOMSHEET_TYPES.SHARE,
                      entity: 'vault',
                      artistId: data.data.artistLink.artist.id,
                    },
                  }}
                />
                <Button
                  label=""
                  iconOnly
                  href={`/settings/artist-notification/${artistHandle}`}
                  className="rounded-full bg-vault_background/70 p-2 text-[16px] text-vault_text no-underline"
                  leadingIcon={faBell}
                  leadingIconClassName="p-1"
                />
              </View>
            </>
          )}
        </View>
      }
    >
      <VaultView
        artist={data.data.artistLink.artist}
        setShowProfileImage={setShowProfileImage}
        scrollRef={scrollRef}
      />
      <PreviewToast />
    </ArtistLayout>
  );
}

function LandingPage() {
  const { artistHandle } = useArtistHandle();
  const { setShowMenu } = useMenuContainer();

  const { isError, isLoading, data, refetch } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      artistHandle,
    },
    keepPreviousData: true,
    enabled: artistHandle != null,
  });

  useEffect(() => {
    setShowMenu(false);

    return () => setShowMenu(true);
  }, [setShowMenu]);

  if (artistHandle == null) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (isError) {
    return <VaultLandingError refetch={refetch} />;
  }

  if (isLoading || data == null) {
    return <VaultLandingLoading />;
  }

  if (data.data.artistLink == null) {
    // TODO: Handle artist is null
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return <VaultLandingView artistFrag={data.data.artistLink.artist} />;
}
