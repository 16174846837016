import { useEffect } from 'react';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useAdminArtist } from '../../hooks/useAdminArtist';
import type { ShareDropBottomsheetProps } from '../../types/bottomsheetTypes';
import { View } from '../common/View';
import { ShareDropView } from '../vault/share/ShareDropView';

export function ShareDropBottomsheet({ dropSlug, artistHandle, type }: ShareDropBottomsheetProps) {
  const { closeBottomsheet } = useBottomsheetContainer();
  const adminArtist = useAdminArtist({ artistHandle });

  useEffect(() => {
    if (adminArtist == null) {
      closeBottomsheet();
    }
  }, [adminArtist, closeBottomsheet]);

  return (
    <View className="min-h-[470px] pb-5">
      <ShareDropView dropSlug={dropSlug} artistHandle={artistHandle} type={type} />
    </View>
  );
}
