import { type FC, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { gql } from '@soundxyz/gql-string';
import { FullPageLoading } from '../../components/views/FullPageLoading';
import { MembershipConfirmationView } from '../../components/views/MembershipView';
import { ROUTES } from '../../constants/routeConstants';
import { useAuthContext } from '../../contexts/AuthContext';
import { useQuery } from '../../graphql/client';
import { ArtistByHandleDocument } from '../../graphql/generated';
import { fetchReceiptId } from '../../hooks/campaign/fetchReceiptId';
import { useStableCallback } from '../../hooks/useStableCallback';
import { LoginStatus } from '../../types/authTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { constructQueryParams } from '../../utils/stringUtils';

gql(/* GraphQL */ `
  mutation UpdateUserOnboarding($input: MutationUpdateUserInput!) {
    updateUser(input: $input) {
      __typename

      ... on Error {
        message
      }

      ... on MutationUpdateUserSuccess {
        data {
          id
          username
        }
      }
    }
  }
`);

export const OnboardingUsernamePage: FC = () => {
  const { loginStatus, loggedInUser } = useAuthContext();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const artistHandle = searchParams.get('artistHandle');
  const trackId = searchParams.get('trackId');
  const invite = searchParams.get('invite');
  const redirect = searchParams.get('redirect');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const sourceReleaseCampaignId = searchParams.get('sourceReleaseCampaignId');
  const showReceiptModal = searchParams.get('showReceiptModal');
  const releaseCampaignId = searchParams.get('sourceReleaseCampaignId');
  const source = searchParams.get('source');
  const eventType = searchParams.get('receiptType');
  const receiptIdParam = searchParams.get('receiptId');

  const navigationPath = useStableCallback(
    ({
      artistHandle,
      receiptId,
    }: {
      artistHandle?: string | null;
      receiptId?: string | null;
    }): string => {
      if (artistHandle != null) {
        const queryParams = constructQueryParams({
          trackId,
          invite,
          redirect: redirect,
          showReceiptModal,
          sourceReleaseCampaignId: releaseCampaignId,
          source,
          receiptType: eventType,
          receiptId: receiptIdParam ?? receiptId,
        });

        return artistNavigationPath(artistHandle, redirect ? `/${redirect}` : '/', queryParams);
      }

      return ROUTES.VAULTS;
    },
  );

  const navigateTo = useStableCallback((receiptId?: string | null) =>
    navigationPath({
      artistHandle,
      receiptId,
    }),
  );

  const { isLoading: isLoadingArtist, data: artistData } = useQuery(ArtistByHandleDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    filterQueryKey: {
      userId: loggedInUser?.id,
    },
    keepPreviousData: true,
    enabled: artistHandle != null,
  });

  const artistFragment = artistData?.data?.artistLink?.artist;
  const artist = artistFragment
    ? {
        linkValue: artistFragment?.linkValue,
        name: artistFragment?.name,
        membershipImageUrl: artistFragment?.membershipCardImage?.membershipCardImageUrl,
      }
    : undefined;

  useEffect(() => {
    if (loginStatus === LoginStatus.LOGGED_IN && !!loggedInUser?.username) {
      navigate(navigateTo());
    }
  }, [artistHandle, loggedInUser?.username, loginStatus, navigate, navigateTo, receiptIdParam]);

  const onComplete = useStableCallback(async () => {
    /**
     * For new users coming through a campaign flow, receipts are only created after:
     * - User gets a membership
     *
     * We fetch the receipt ID here at onboarding completion to ensure we can
     * redirect to the correct receipt modal state after the user is fully set up.
     */
    const receiptId = await fetchReceiptId({
      artistHandle,
      releaseCampaignId: sourceReleaseCampaignId,
      source: source as 'apple' | 'spotify' | null,
      showReceiptModal,
      receiptIdParam,
      loggedIn: loginStatus === LoginStatus.LOGGED_IN && !!loggedInUser?.id,
      eventType,
    });
    navigate(navigateTo(receiptId));
  });

  if (loginStatus === LoginStatus.LOADING || isLoadingArtist) {
    return <FullPageLoading withVaultTheme />;
  } else if (loggedInUser?.username && !artistData) {
    return <Navigate to={navigateTo()} />;
  } else {
    return (
      <MembershipConfirmationView
        vaultId={artistFragment?.mainVaultId}
        isLoading={false}
        artist={artist}
        loggedInUserUsername={loggedInUser?.username}
        loginStatus={loginStatus}
        inviteCode={invite}
        smsCampaignResponseShortcode={smsCampaignResponseShortcode}
        sourceReleaseCampaignId={sourceReleaseCampaignId}
        onComplete={onComplete}
      />
    );
  }
};
