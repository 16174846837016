import { type LegacyRef, useMemo, useState } from 'react';
import { isIOS, isMobile } from 'react-device-detect';

import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLongPress } from 'use-long-press';
import { faLink } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { useAuthContext } from '../../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../../contexts/BottomsheetContext';
import { useOverlayContainer } from '../../../../contexts/OverlayContext';
import { useMutation } from '../../../../graphql/client';
import type { MediaType } from '../../../../graphql/generated';
import { ImageItemFragmentDoc, UpsertUserContentViewDocument } from '../../../../graphql/generated';
import { type FragmentType, getFragment } from '../../../../graphql/generated';
import { useCopy } from '../../../../hooks/useCopy';
import { useActiveSubscriptionFeatures } from '../../../../hooks/useTierFeatures';
import { useUpsellInterstitials } from '../../../../hooks/useUpsellInterstitials';
import { LoginStatus } from '../../../../types/authTypes';
import { EVENTS } from '../../../../types/eventTypes';
import { trackEvent } from '../../../../utils/analyticsUtils';
import { generateShareLink } from '../../../../utils/linkUtils';
import { artistNavigationPath } from '../../../../utils/navigationUtils';
import { MediaViewer } from '../../../message/MediaViewer';
import { ImageItem } from '../ImageItem';
import { type ContentOption, ContentOptions } from '../artist/ContentOptions';

export const UserImage = ({
  image,
  allAssets,
  containerRef,
  artistHandle,
}: {
  image: FragmentType<ImageItemFragmentDoc>;
  allAssets: Array<{
    id: string;
    type: MediaType;
    url: string;
    title: string;
  }>;
  containerRef?: LegacyRef<HTMLDivElement>;
  artistHandle: string;
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onLongPress = useLongPress(() => {
    setIsOpen(true);
  })();

  const [menuHovered, setMenuHovered] = useState(false);

  const navigate = useNavigate();
  const { mutate: upsertUserContentView } = useMutation(UpsertUserContentViewDocument, {});
  const {
    id: imageId,
    vault,
    title,
    uploadedMedia,
    vaultId,
    linkValue,
  } = getFragment(ImageItemFragmentDoc, image);

  const { loginStatus, loggedInUser } = useAuthContext();
  const path = linkValue ? (`/i/${linkValue}` as const) : (`/${imageId}` as const);
  const link = useMemo(() => {
    return generateShareLink({
      artistLinkValue: artistHandle,
      path,
      inviteCode: loggedInUser?.adminArtists?.some(
        adminArtist => adminArtist.artistMainVaultId === vaultId,
      )
        ? null
        : loggedInUser?.inviteCode,
    });
  }, [artistHandle, path, loggedInUser, vaultId]);

  const { copy } = useCopy({
    text: link,
    successMessage: 'Track link copied to clipboard!',
  });

  const buttons: ContentOption[] = [
    {
      title: 'Share',
      icon: faLink,
      onClick: copy,
    },
  ];

  const [searchParams] = useSearchParams();
  const inviteCode = searchParams.get('code') ?? undefined; // referral link code
  const hasSubscription = vault.activeSubscription != null;

  const activeSubscriptionFeatures = useActiveSubscriptionFeatures({
    subscription: vault.activeSubscription,
    isOwner: vault.isUserArtistAdmin,
  });
  const { openOverlay, closeOverlay } = useOverlayContainer();
  const { openBottomsheet } = useBottomsheetContainer();

  const isOwner = vault.isUserArtistAdmin;
  const isLocked = (!isOwner && activeSubscriptionFeatures?.tier == null) || uploadedMedia == null;

  const { upsellInterstitials, wasShowedInSession } = useUpsellInterstitials();

  const showUpsellInterstitial =
    upsellInterstitials?.firstTrack === false &&
    isMobile &&
    isIOS &&
    loginStatus === LoginStatus.LOGGED_IN &&
    !wasShowedInSession;

  const onClick = () => {
    upsertUserContentView({ input: { vaultContentId: imageId } });
    if (isLocked || showUpsellInterstitial) {
      if (showUpsellInterstitial) {
        setTimeout(() => {
          openBottomsheet({
            type: 'GET_APP',
            getAppBottomsheetProps: {
              vaultId,
              interstitial: 'first_media',
              onContinue: null,
            },
          });

          trackEvent({
            type: EVENTS.OPEN_BOTTOMSHEET,
            properties: {
              bottomsheetType: 'GET_APP',
              vaultId,
              interstitial: 'first_media',
            },
          });
        }, 2000);
      } else {
        trackEvent({
          type: EVENTS.OPEN_BOTTOMSHEET,
          properties: {
            bottomsheetType: 'JOIN_VAULT',
            joinType: hasSubscription ? 'paid' : 'free',
            artistHandle,
          },
        });

        navigate(artistNavigationPath(artistHandle, path, inviteCode));
      }
      return;
    }

    openOverlay(<MediaViewer title={title ?? ''} medias={allAssets} onClose={closeOverlay} />);
  };

  return (
    <ContentOptions
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      isSelecting={false}
      onLongPress={onLongPress}
      onClick={() => {
        if (!isOpen) {
          return onClick();
        }
      }}
      selectedFile={false}
      menuHovered={menuHovered}
      setMenuHovered={setMenuHovered}
      buttons={buttons}
      triggerItem={<ImageItem image={image} containerRef={containerRef} hasEllipsis />}
      disabled={false}
      disableHover
    />
  );
};
