import { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Statsig, useGate } from 'statsig-react';
import { twMerge } from 'tailwind-merge';
import { faTools, faXmark } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { FEATURE_GATES } from '../../constants/flagConstants';
import { FeatureGatesStore } from '../../hooks/useFeatureGate';
import { Text } from '../common/Text';
import { View } from '../common/View';

export const FeatureFlagToggle = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const dragRef = useRef<{ startX: number; startY: number; buttonX: number; buttonY: number }>();

  const isDevPrev =
    import.meta.env.VITE_DEPLOY_ENVIRONMENT === 'development' ||
    import.meta.env.VITE_DEPLOY_ENVIRONMENT === 'preview';

  const handleStart = useCallback(
    (e: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>) => {
      const startTime = Date.now();
      let hasMoved = false;

      const point = 'touches' in e ? e.touches[0] : e.nativeEvent;
      if (!point) return;
      const startX = point.clientX;
      const startY = point.clientY;

      const handleMove = (e: MouseEvent | TouchEvent) => {
        const point = 'touches' in e && e.touches[0] ? e.touches[0] : 'clientX' in e ? e : null;
        if (!point) return;

        hasMoved = true;
        setIsDragging(true);
        dragRef.current = {
          startX,
          startY,
          buttonX: position.x,
          buttonY: position.y,
        };
      };

      const handleEnd = () => {
        const endTime = Date.now();
        const isClick = !hasMoved && endTime - startTime < 200;

        if (isClick) {
          setIsOpen(prev => !prev);
        }

        setIsDragging(false);
        dragRef.current = undefined;
        window.removeEventListener('mousemove', handleMove);
        window.removeEventListener('mouseup', handleEnd);
        window.removeEventListener('touchmove', handleMove);
        window.removeEventListener('touchend', handleEnd);
      };

      window.addEventListener('mousemove', handleMove);
      window.addEventListener('mouseup', handleEnd);
      window.addEventListener('touchmove', handleMove);
      window.addEventListener('touchend', handleEnd);
    },
    [position],
  );

  const handleMove = useCallback(
    (e: MouseEvent | TouchEvent) => {
      if (!isDragging || !dragRef.current) return;

      const point = 'touches' in e && e.touches[0] ? e.touches[0] : 'clientX' in e ? e : null;
      if (!point) return;

      const deltaX = point.clientX - dragRef.current.startX;
      const deltaY = point.clientY - dragRef.current.startY;

      setPosition({
        x: dragRef.current.buttonX + deltaX,
        y: dragRef.current.buttonY + deltaY,
      });
    },
    [isDragging],
  );

  useEffect(() => {
    if (isDragging) {
      window.addEventListener('mousemove', handleMove);
      window.addEventListener('touchmove', handleMove);
    }
    return () => {
      window.removeEventListener('mousemove', handleMove);
      window.removeEventListener('touchmove', handleMove);
    };
  }, [isDragging, handleMove]);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    const handleEsc = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };

    if (isOpen) {
      window.addEventListener('keydown', handleEsc);
    }

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [isOpen, handleClose]);

  if (!isDevPrev) {
    return null;
  }

  const featureGates = Object.values(FEATURE_GATES);
  const gateValues = Object.fromEntries(featureGates.map(gate => [gate, useGate(gate).value]));

  const toggleFeature = (gate: string) => {
    Statsig.overrideGate(gate, !gateValues[gate]);
    FeatureGatesStore.produceExistingState(
      draft => {
        draft[gate] = !gateValues[gate];
      },
      {
        [gate]: !gateValues[gate],
      },
    );
  };

  return (
    <>
      <button
        className={twMerge(
          'fixed z-menu cursor-move rounded-full bg-vault_accent p-3 text-vault_accent_text shadow-lg',
          isDragging && 'pointer-events-none',
        )}
        style={{
          bottom: `calc(1rem - ${position.y}px)`,
          right: `calc(1rem - ${position.x}px)`,
        }}
        onMouseDown={handleStart}
        onTouchStart={handleStart}
        aria-label="Toggle Feature Flags"
      >
        <FontAwesomeIcon icon={faTools} className="h-5 w-5 text-vault_accent_text" />
      </button>

      {isOpen && (
        <View
          className="fixed inset-0 z-modal flex items-center justify-center bg-black/50 backdrop-blur-sm"
          onClick={handleClose}
        >
          <View
            className="w-80 rounded-lg bg-vault_text/10 p-4 shadow-lg backdrop-blur-2xl"
            onClick={e => e.stopPropagation()}
          >
            <View className="mb-4 flex items-center justify-between">
              <Text className="text-lg font-title font-bold">Feature Flags</Text>
              <button
                onClick={handleClose}
                className="transition-colors rounded-full p-1"
                aria-label="Close"
              >
                <FontAwesomeIcon icon={faXmark} className="h-5 w-5 text-vault_text" />
              </button>
            </View>
            <View className="flex flex-col gap-2">
              {featureGates.map(gate => (
                <View key={gate} className="flex items-center justify-between">
                  <Text className="text-sm">{gate}</Text>
                  <button
                    className={twMerge(
                      'rounded px-3 py-1 text-vault_text',
                      gateValues[gate] ? 'bg-green100' : 'bg-destructive100',
                    )}
                    onClick={() => toggleFeature(gate)}
                  >
                    <Text>{gateValues[gate] ? 'ON' : 'OFF'}</Text>
                  </button>
                </View>
              ))}
            </View>
          </View>
        </View>
      )}
    </>
  );
};
