import { type ReactNode, useEffect, useState } from 'react';
import msFn from 'ms';
import { useSnapshot } from 'valtio';
import { PlayerType } from '../audio/AudioController';
import { AppleMusicContext } from '../contexts/AppleMusicContext';
import { useAuthContext } from '../contexts/AuthContext';
import { useQuery } from '../graphql/client';
import { AppleMusicDeveloperTokenDocument } from '../graphql/generated';
import { configureMusicKit, MusicKitReady } from '../hooks/appleMusic/useAppleMusicAuth';
import { useBetterGate } from '../hooks/useFeatureGate';
import { LoginStatus } from '../types/authTypes';
import { IdempotentFunctionCall } from '../utils/idempotent';

const fifteenMinutes = msFn('15 minutes');

export function AppleMusicProvider({ children }: { children: ReactNode }) {
  const { loggedInUser, loginStatus } = useAuthContext();
  const appleMusicEnabled = useBetterGate('VAULT_APPLE_MUSIC_CONNECT') === 'enabled';
  const musickitLoaded = useSnapshot(MusicKitReady).musickitLoaded;
  const instanceLoaded = useSnapshot(MusicKitReady).instanceLoaded;
  const musicKit = musickitLoaded && instanceLoaded ? MusicKit.getInstance() : null;
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTrack, setCurrentTrack] = useState<MusicKit.MediaItem | null>(null);
  const [currentProgress, setCurrentProgress] = useState(0);
  const [campaignId, setCampaignId] = useState<string | null>(null);

  const playerType = useSnapshot(PlayerType).current;

  const hasAppleMusicConnection =
    loggedInUser != null && loggedInUser.appleMusicAuthConnections.length > 0;

  const { data: developerToken = null } = useQuery(AppleMusicDeveloperTokenDocument, {
    variables: {
      origin,
      accessSecret: import.meta.env.VITE_APPLE_MUSIC_SECRET_ACCESS_TOKEN,
    },
    staleTime: fifteenMinutes,
    refetchInterval: fifteenMinutes,
    enabled: appleMusicEnabled && hasAppleMusicConnection && loginStatus !== LoginStatus.LOADING,
    select(data) {
      return data.data.appleMusicDeveloperToken.__typename ===
        'QueryAppleMusicDeveloperTokenSuccess'
        ? data.data.appleMusicDeveloperToken.data
        : null;
    },
  });
  useEffect(() => {
    if (playerType !== 'appleMusic' || musicKit == null) {
      setDuration(0);
      setIsPlaying(false);
      setCurrentTrack(null);
      setCurrentProgress(0);
      return;
    }

    setDuration(musicKit.currentPlaybackDuration);
    setIsPlaying(musicKit.playbackState === MusicKit.PlaybackStates.playing);
    setCurrentTrack(musicKit.nowPlayingItem);
    setCurrentProgress(musicKit.currentPlaybackProgress);
  }, [musicKit, playerType]);

  useEffect(() => {
    if (!musickitLoaded) {
      if (typeof MusicKit !== 'undefined' && !MusicKitReady.musickitLoaded) {
        MusicKitReady.musickitLoaded = true;
      }
    }

    if (!musickitLoaded || !developerToken) return;

    IdempotentFunctionCall(() => configureMusicKit({ developerToken }), {
      key: `music-kit-${developerToken}`,
      clearOnFinish: false,
    });
  }, [developerToken, musickitLoaded]);

  useEffect(() => {
    if (musicKit == null) {
      return;
    }
  }, [musicKit]);

  useEffect(() => {
    if (musicKit == null) {
      return;
    }

    const playbackDurationDidChange = () => {
      setDuration(musicKit.currentPlaybackDuration);
    };
    const playbackStateDidChange = ({ state }: { state: MusicKit.PlaybackStates }) => {
      setIsPlaying(state === MusicKit.PlaybackStates.playing);
    };
    const nowPlayingItemDidChange = ({ item }: { item: MusicKit.MediaItem }) => {
      setCurrentTrack(item);
    };
    const playbackProgressDidChange = ({ progress }: { progress: number }) => {
      setCurrentProgress(progress);
    };

    musicKit.addEventListener('playbackDurationDidChange', playbackDurationDidChange);
    musicKit.addEventListener('playbackStateDidChange', playbackStateDidChange);
    musicKit.addEventListener('nowPlayingItemDidChange', nowPlayingItemDidChange);
    musicKit.addEventListener('playbackProgressDidChange', playbackProgressDidChange);

    return () => {
      musicKit.removeEventListener('playbackDurationDidChange', playbackDurationDidChange);
      musicKit.removeEventListener('playbackStateDidChange', playbackStateDidChange);
      musicKit.removeEventListener('nowPlayingItemDidChange', nowPlayingItemDidChange);
      musicKit.removeEventListener('playbackProgressDidChange', playbackProgressDidChange);
    };
  }, [musicKit]);

  return (
    <AppleMusicContext.Provider
      value={{
        duration,
        isPlaying,
        currentTrack,
        currentTime: currentProgress * duration,
        musicKit,
        campaignId,
        setCampaignId,
      }}
    >
      {children}
    </AppleMusicContext.Provider>
  );
}
