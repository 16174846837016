import { useEffect } from 'react';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useAdminArtist } from '../../hooks/useAdminArtist';
import type { ShareContentBottomsheetProps } from '../../types/bottomsheetTypes';
import { View } from '../common/View';
import { ShareContentView } from '../vault/share/ShareContentView';

export function ShareContentBottomsheet({ linkValue, artistHandle }: ShareContentBottomsheetProps) {
  const { closeBottomsheet } = useBottomsheetContainer();
  const adminArtist = useAdminArtist({ artistHandle });

  useEffect(() => {
    if (adminArtist == null) {
      closeBottomsheet();
    }
  }, [adminArtist, closeBottomsheet]);

  return (
    <View className="min-h-[470px] pb-5">
      <ShareContentView
        linkValue={linkValue}
        artistHandle={artistHandle}
        artistName={adminArtist?.artistName ?? ''}
      />
    </View>
  );
}
