import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faChevronRight } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { useAuthContext } from '../../contexts/AuthContext';
import { useUserArtistReceipts } from '../../hooks/membership/useUserArtistReceipts';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { LoginStatus } from '../../types/authTypes';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { ReceiptItem, ReceiptItemSkeleton } from './ReceiptItem';
import { EmptyReceipts, Item } from './shared';

export const ReceiptsSection = () => {
  const { loginStatus } = useAuthContext();
  const { artistHandle } = useArtistHandle();

  const {
    userArtistReceipts: receipts = [],
    isInitialLoading,
    hasNextPage: hasMoreReceipts,
  } = useUserArtistReceipts({
    artistHandle,
    limit: 3,
    source: 'membership_page',
    receiptTypes: null,
    enabled: true,
  });

  const hasReceipts = receipts.length > 0;

  const header = 'Your RSVPs';

  if (isInitialLoading) {
    return (
      <Item header={header} className="mb-[-1px] gap-3">
        <View className="h-[1px]" />
        {Array.from({ length: 3 }).map((_, index) => (
          <ReceiptItemSkeleton key={index} />
        ))}
      </Item>
    );
  }

  if (!hasReceipts || loginStatus === LoginStatus.LOGGED_OUT) {
    return (
      <Item header={header}>
        <EmptyReceipts artistHandle={artistHandle} />
      </Item>
    );
  }

  const hasMoreReceiptsHref = hasMoreReceipts
    ? artistNavigationPath(artistHandle, '/drops/receipts')
    : null;

  return (
    <Item
      header={
        hasMoreReceiptsHref ? (
          <Link to={hasMoreReceiptsHref} className="no-underline">
            <Text className="text-nowrap font-title text-[24px]/[24px] font-medium text-vault_text lg:text-[24px]/[26px]">
              {header}
            </Text>
          </Link>
        ) : (
          header
        )
      }
      headerLink={
        hasMoreReceiptsHref ? (
          <Link
            to={hasMoreReceiptsHref}
            className="text-base text-base-l font-black text-vault_text no-underline transition-all duration-200 ease-in hover:text-vault_text/80"
          >
            <FontAwesomeIcon icon={faChevronRight} />
          </Link>
        ) : null
      }
      className="gap-6"
    >
      {receipts.map(item => (
        <ReceiptItem key={item.id} receiptData={item} />
      ))}
    </Item>
  );
};
