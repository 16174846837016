import type { FC } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string';
import { useQuery } from '../../graphql/client';
import { VaultMessageAttachmentFragmentDoc } from '../../graphql/generated';
import { type FragmentType, getFragment, VaultTrackByIdDocument } from '../../graphql/generated';
import { useArtistHandle } from '../../hooks/useArtistHandle';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { TrackRow, TrackRowSkeleton } from '../track/TrackRow';
import { LockedImageItem } from '../vault/items/ImageItem';
import { LockedVideoItem } from '../vault/items/VideoItem';
import { MediaStack } from './MediaStack';
import type { MediaViewerMedias } from './MediaViewer';
import { PinnedMessageAttachment } from './PinnedMessageAttachment';

type Props = {
  messageContent: FragmentType<VaultMessageAttachmentFragmentDoc>;
  type: 'full' | 'pinned';
  className?: string;
  isAuthor?: boolean;
  onViewMedia?: (medias: MediaViewerMedias, startAt?: number) => void;
};

gql(/* GraphQL */ `
  query VaultTrackById($vaultContentId: UUID!) {
    vaultContentById(vaultContentId: $vaultContentId) {
      __typename
      id
      linkValue
      isFullVersionAvailable
      ... on VaultTrack {
        id
        ...TrackRowInfo
      }
      ... on VaultImage {
        media: uploadedMedia {
          id
          mediaType
          cdnUrl
          fullImageUrl: imageOptimizedUrl
          mediumImageUrl: imageOptimizedUrl(input: { width: 600, height: 600 })
        }
        blurredMediaUrl
      }
      ... on VaultVideo {
        media: uploadedMedia {
          id
          mediaType
          cdnUrl
          fullImageUrl: imageOptimizedUrl
          mediumImageUrl: imageOptimizedUrl(input: { width: 600, height: 600 })
        }
        blurredMediaUrl
      }
    }
  }

  fragment vaultMessageAttachment on MessageContent {
    id
    vaultContent {
      id
      title
    }
  }
`);

export const MessageAttachment: FC<Props> = ({
  messageContent,
  type = 'full',
  className,
  isAuthor,
  onViewMedia,
}) => {
  const messageContentFrag = getFragment(VaultMessageAttachmentFragmentDoc, messageContent);
  const { data: vaultContent, isLoading } = useQuery(VaultTrackByIdDocument, {
    variables: !!messageContentFrag.vaultContent && {
      vaultContentId: messageContentFrag.vaultContent.id,
    },
    staleTime: 0,
  });
  const navigate = useNavigate();

  const artistHandle = useArtistHandle().artistHandle?.toLowerCase();

  if (messageContentFrag.vaultContent != null) {
    if (vaultContent?.data.vaultContentById?.__typename === 'VaultTrack') {
      if (type === 'pinned') {
        return <PinnedMessageAttachment track={vaultContent.data.vaultContentById} />;
      }
      return (
        <TrackRow
          track={vaultContent.data.vaultContentById}
          waveFormClass="w-full"
          className={twMerge('mb-0 mt-[12px] box-border min-h-[110px] w-full', className)}
          lastViewedTime={null}
          isLocked={false}
          isAuthor={isAuthor}
        />
      );
    } else if (
      vaultContent?.data.vaultContentById?.__typename === 'VaultImage' ||
      vaultContent?.data.vaultContentById?.__typename === 'VaultVideo'
    ) {
      if (
        vaultContent.data.vaultContentById.media == null &&
        vaultContent?.data.vaultContentById?.__typename === 'VaultImage'
      ) {
        return (
          <LockedImageItem
            blurredMediaUrl={vaultContent.data.vaultContentById.blurredMediaUrl}
            onClick={() => {
              navigate(
                artistNavigationPath(
                  artistHandle,
                  `/i/${vaultContent.data.vaultContentById?.linkValue}`,
                ),
              );
              return;
            }}
          />
        );
      }

      if (
        vaultContent.data.vaultContentById.media == null &&
        vaultContent?.data.vaultContentById?.__typename === 'VaultVideo'
      ) {
        return (
          <LockedVideoItem
            blurredMediaUrl={vaultContent.data.vaultContentById.blurredMediaUrl}
            onClick={() => {
              navigate(
                artistNavigationPath(
                  artistHandle,
                  `/v/${vaultContent.data.vaultContentById?.linkValue}`,
                ),
              );
              return;
            }}
          />
        );
      }
      if (vaultContent.data.vaultContentById.media != null) {
        const media = {
          id: vaultContent.data.vaultContentById.media.id,
          url:
            vaultContent.data.vaultContentById.media.fullImageUrl ||
            vaultContent.data.vaultContentById.media.cdnUrl,
          type: vaultContent.data.vaultContentById.media.mediaType,
        };

        return (
          <MediaStack
            isAuthor={isAuthor}
            medias={[
              {
                ...vaultContent.data.vaultContentById.media,
                type: vaultContent.data.vaultContentById.media.mediaType,
                lockedMedia: vaultContent.data.vaultContentById.isFullVersionAvailable
                  ? null
                  : {
                      blurredMediaUrl: vaultContent.data.vaultContentById.blurredMediaUrl,
                      linkValue: vaultContent.data.vaultContentById.linkValue,
                    },
              },
            ]}
            onView={startAt => {
              if (onViewMedia) onViewMedia([media], startAt);
            }}
          />
        );
      }
    } else if (isLoading) {
      return type === 'pinned' ? <></> : <TrackRowSkeleton />;
    } else {
      return (
        <View className="mb-[20px] box-border flex h-[112px] w-full flex-1 flex-col items-center bg-vault_text/30" />
      );
    }
  }

  // No message attachment was found
  return (
    <View
      className={twMerge(
        'my-1 box-border flex flex-col rounded-xl bg-[#1f1f1f] p-4',
        isAuthor ? 'bg-vault_accent_text/20' : 'bg-vault_accent_text/20',
      )}
    >
      <Text
        className={twMerge(
          'text-center !text-base-m italic opacity-50',
          isAuthor ? 'text-vault_accent_text' : 'text-vault_text',
        )}
      >
        This content is no longer available
      </Text>
    </View>
  );
};
