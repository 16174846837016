import React from 'react';

const EMPTY: unique symbol = Symbol();

interface ContainerProviderProps<State = void> {
  initialState?: State;
  children: React.ReactNode;
}

export interface Container<Value, State = void> {
  Provider: React.ComponentType<ContainerProviderProps<State>>;
  useContainer: () => Value;
  Context: React.Context<Value | typeof EMPTY>;
}

export function createContainer<Value, State = void>(
  useHook: (initialState?: State) => Value,
): Container<Value, State> {
  const Context = React.createContext<Value | typeof EMPTY>(EMPTY);

  function Provider(props: ContainerProviderProps<State>) {
    const value = useHook(props.initialState);

    if (import.meta.env.DEV) {
      // @ts-expect-error - Prevent hot reloading issues
      if (!React.__containerCache) React.__containerCache = {};

      // @ts-expect-error - Prevent hot reloading issues
      React.__containerCache[useHook.name] = value;
    }

    return <Context.Provider value={value}>{props.children}</Context.Provider>;
  }

  function useContainer(): Value {
    let value = React.useContext(Context);

    if (import.meta.env.DEV) {
      if (value == null || value === EMPTY) {
        // @ts-expect-error - Prevent hot reloading issues
        value = React.__containerCache[useHook.name] ?? EMPTY;
      }
    }

    if (value === EMPTY) {
      throw new Error('Component must be wrapped with <Container.Provider>');
    }
    return value;
  }

  return { Provider, useContainer, Context };
}
