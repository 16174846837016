import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import { proxy, useSnapshot } from 'valtio';
import type { ModalType } from '../constants/modalConstants';
import { createContainer } from '../utils/unstated';

type ModalType = (typeof ModalType)[keyof typeof ModalType];

type ModalContentState = {
  body: React.ReactNode;
};

const globalModalState = proxy({
  activeModal: null as ModalType | null,
  nextModal: null as ModalType | null,
  modalContent: null as ModalContentState | null,
  setNextModal: (nextModal: ModalType | null) => {
    globalModalState.nextModal = nextModal;
  },
  openModal: (newModalType: ModalType) => {
    globalModalState.activeModal = newModalType;
  },
  onCancelModal: null as (() => void) | null,
  setOnCancelModal: (onCancelModal: () => void | null) => {
    globalModalState.setOnCancelModal = onCancelModal;
  },
  closeModal: () => {
    globalModalState.activeModal = null;
    globalModalState.onCancelModal = null;
    globalModalState.modalContent = null;
  },
  closeModalByType: (modalType: ModalType) => {
    if (globalModalState.activeModal === modalType) {
      globalModalState.closeModal();
    }
  },
  closeCancelModal: () => {
    if (globalModalState.onCancelModal !== null) {
      globalModalState.onCancelModal();
    }
    globalModalState.closeModal();
  },
});

export const GlobalModal = {
  get activeModal() {
    return globalModalState.activeModal;
  },
};

export const ModalContext = createContainer(function ModalContext() {
  const location = useLocation();
  useEffect(() => {
    globalModalState.closeModal();
  }, [location]);

  const openModal = useCallback((modalType: ModalType, modalContent: ModalContentState | null) => {
    setTimeout(() => {
      /**
       * When you trigger opening another modal and the trigger is inside a modal
       * radix adds another 15px to the removed body scroll bar size
       * this is to make sure its always 15px
       */
      document.body.style.setProperty('--removed-body-scroll-bar-size', '15px !important');
      globalModalState.modalContent = modalContent;

      globalModalState.activeModal = modalType;
    }, 100);
  }, []);

  // Used for directing to buy modal after a successful login triggered from attempting to buy (for example)
  React.useEffect(() => {
    if (globalModalState.nextModal && globalModalState.activeModal === null) {
      globalModalState.activeModal = globalModalState.nextModal;
      globalModalState.setNextModal(null);
    }
  }, []);

  const { activeModal, onCancelModal, modalContent: _modalContent } = useSnapshot(globalModalState);

  return {
    activeModal,
    onCancelModal,
    modalContent: globalModalState.modalContent,
    openModal,
    closeCancelModal: globalModalState.closeCancelModal,
    setOnCancelModal: globalModalState.setOnCancelModal,
    closeModal: globalModalState.closeModal,
    closeModalByType: globalModalState.closeModalByType,
    setNextModal: globalModalState.setNextModal,
  };
});

export const useModal = () => ModalContext.useContainer();
