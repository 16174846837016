import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isFuture } from 'date-fns';
import { Link } from 'react-router-dom';
import {
  faCheckCircle,
  faFloppyDisk,
  faWaveform,
  faWaveformLines,
} from '@soundxyz/font-awesome/pro-light-svg-icons';
import { gql } from '@soundxyz/gql-string';
import {
  type ArtistEventFragment,
  ArtistMembershipReceiptTypenames,
  PrivateReleaseCampaignEventItemFragmentDoc,
  PublicReleaseCampaignEventItemFragmentDoc,
  ReleaseCampaignEventItemFragmentDoc,
} from '../../../graphql/generated';
import { type FragmentType, getFragment } from '../../../graphql/generated';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { Image } from '../../common/Image';
import { Text } from '../../common/Text';
import { View } from '../../common/View';

gql(/* GraphQL */ `
  fragment ReleaseCampaignEventItem on ReleaseCampaign {
    __typename
    ...PublicReleaseCampaignEventItem
    ...PrivateReleaseCampaignEventItem
  }

  fragment PublicReleaseCampaignEventItem on ReleaseCampaignPublicInfo {
    id
    __typename
    title
    releaseDate

    linkValue
    status
    currentState
    initialReleaseImageUrl
    coverImage {
      id
      campaignCoverImageUrl: imageOptimizedUrl
    }
    artist {
      id
      isAuthUserAdmin
      linkValue
      profileImage {
        id
        artistFullProfileImageUrl: imageOptimizedUrl
      }
    }
  }

  fragment PrivateReleaseCampaignEventItem on ReleaseCampaignPrivateInfo {
    id
    __typename
    title
    releaseDate

    linkValue
    status
    currentState
    initialReleaseImageUrl
    receiptCount
    announcement {
      id
      content
    }
    coverImage {
      id
      campaignCoverImageUrl: imageOptimizedUrl
    }
    artist {
      id
      isAuthUserAdmin
      linkValue
      profileImage {
        id
        artistFullProfileImageUrl: imageOptimizedUrl
      }
    }
  }
`);
/**
 * ReleaseCampaignEventItem
 *
 * The "ReleaseCampaignEventItem" component is a React component that displays a single release campaign in a list of drops.
 *
 * (Docs as of 2025-01-12 by @emilee)
 * The concept of a "Release Campaign" is a subset of "Drop" (also known as "ArtistEvent") which is composed
 * of one of two different types of drops in our product:
 *     1. Presave
 *     2. Stream
 *
 * Thus, in the logic below, since we assume a release campaign is either a presave or a stream, we check `isPresave` to determine if
 * the campaign is a presave or a stream.
 */
export const ReleaseCampaignEventItem = React.memo(
  ({
    eventId,
    releaseCampaign,
    actions,
  }: {
    eventId: string;
    releaseCampaign: FragmentType<ReleaseCampaignEventItemFragmentDoc>;
    actions: ArtistEventFragment['actions'];
  }) => {
    useVaultTheme();
    const fragment = getFragment(ReleaseCampaignEventItemFragmentDoc, releaseCampaign);

    const publicInfo =
      fragment.__typename === 'ReleaseCampaignPublicInfo'
        ? getFragment(PublicReleaseCampaignEventItemFragmentDoc, fragment)
        : null;

    const privateInfo =
      fragment.__typename === 'ReleaseCampaignPrivateInfo'
        ? getFragment(PrivateReleaseCampaignEventItemFragmentDoc, fragment)
        : null;

    const campaign = publicInfo || privateInfo;
    if (!campaign) return null;

    const {
      title,
      currentState,
      releaseDate,
      coverImage,
      initialReleaseImageUrl,
      linkValue,
      artist: { isAuthUserAdmin, linkValue: artistHandle, profileImage },
    } = campaign;

    const releaseImage =
      coverImage?.campaignCoverImageUrl ??
      initialReleaseImageUrl ??
      profileImage?.artistFullProfileImageUrl;

    const hasPresaved =
      !!actions.find(
        v => v.receiptType === ArtistMembershipReceiptTypenames.ArtistMembershipPresaveReceipt,
      )?.authUserCompleted && !isAuthUserAdmin;
    const hasStreamed =
      !!actions.find(
        v => v.receiptType === ArtistMembershipReceiptTypenames.ArtistMembershipPlayStreamReceipt,
      )?.authUserCompleted && !isAuthUserAdmin;
    const isPresave = currentState === 'PRESAVE' && releaseDate && isFuture(releaseDate);
    const receiptCount = privateInfo?.receiptCount ?? 0;

    return (
      <RowItem
        eventId={eventId}
        title={title}
        releaseImage={releaseImage}
        linkValue={linkValue}
        artistHandle={artistHandle}
        isAuthUserAdmin={isAuthUserAdmin}
        receiptCount={receiptCount}
        subTextDisplay={
          <SubtextDisplay
            isPresave={!!isPresave}
            hasPresaved={hasPresaved}
            hasStreamed={hasStreamed}
          />
        }
      />
    );
  },
);

ReleaseCampaignEventItem.displayName = 'ReleaseCampaignEventItem';

const SubtextDisplay = ({
  isPresave,
  hasPresaved,
  hasStreamed,
}: {
  isPresave: boolean;
  hasPresaved: boolean;
  hasStreamed: boolean;
}) => {
  const presaveIcon = hasPresaved ? faCheckCircle : faFloppyDisk;

  const presaveDisplay = (
    <div
      data-state={hasPresaved ? 'completed' : 'incomplete'}
      className="flex items-center gap-1 text-[14px]/[16px] data-[state=completed]:text-vault_accent data-[state=incomplete]:text-vault_text/60"
    >
      <FontAwesomeIcon icon={presaveIcon} />
      <p>{hasPresaved ? 'Pre-saved' : 'Pre-save'}</p>
    </div>
  );
  if (isPresave) {
    return presaveDisplay;
  }

  const streamIcon = hasStreamed ? faCheckCircle : faWaveform;
  const streamDisplay = (
    <div
      data-state={hasStreamed ? 'completed' : 'incomplete'}
      className="flex items-center gap-1 text-[14px]/[16px] data-[state=completed]:text-vault_accent data-[state=incomplete]:text-vault_text/60"
    >
      <FontAwesomeIcon icon={streamIcon} />
      <p>{hasStreamed ? 'Streamed' : 'Stream'}</p>
    </div>
  );

  if (!hasPresaved) {
    return streamDisplay;
  }

  return (
    <div className="flex items-center gap-1 ">
      {streamDisplay}
      <p className="text-vault_text/60">·</p>
      {presaveDisplay}
    </div>
  );
};

const RowItem = ({
  eventId,
  title,
  releaseImage,
  subTextDisplay,
  linkValue,
  artistHandle,
  isAuthUserAdmin,
}: {
  /* State associated with the event */
  eventId: string;
  title: string;
  releaseImage: string | null | undefined;
  subTextDisplay: React.ReactNode;
  /* Handles rendering different user states */
  receiptCount: number;

  /* Handles rendering for the artist */
  linkValue: string;
  artistHandle: string;
  isAuthUserAdmin: boolean;
}) => {
  return (
    <Link
      to={`${artistNavigationPath(
        artistHandle,
        isAuthUserAdmin ? `/event/insights/${eventId}` : `/s/${linkValue}`,
      )}`}
      className="flex w-full flex-row items-center justify-between gap-4 no-underline transition-all duration-200 ease-in hover:opacity-80"
    >
      <View className="flex h-16 max-w-[80%] justify-start gap-4">
        {releaseImage ? (
          <Image
            src={releaseImage}
            alt={title}
            height={64}
            width={64}
            className="aspect-square h-16 w-16 flex-shrink-0 rounded-md bg-neutral500 object-cover"
          />
        ) : (
          <View className="flex aspect-square w-16 flex-shrink-0 items-center justify-center rounded-md  outline outline-1 outline-vault_text/10">
            <FontAwesomeIcon icon={faWaveformLines} className="text-[20px] text-vault_text" />
          </View>
        )}
        <View className="flex w-full flex-col justify-center gap-2">
          <View className="flex flex-row items-center gap-2">
            <Text className="line-clamp-1 font-title text-[18px]/[20px] font-medium text-vault_text">
              {title}
            </Text>
          </View>

          {subTextDisplay}
        </View>
      </View>
    </Link>
  );
};
