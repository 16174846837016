import { useEffect, useMemo } from 'react';
import { usePrivy } from '@privy-io/react-auth';
import { z } from 'zod';
import { gql } from '@soundxyz/gql-string';
import { fetchGQL, GraphQLReactQuery, useQuery } from '../graphql/client';
import {
  ArtistAdminRole,
  AuthorizedUserProfilesDocument,
  type AuthUserFragment,
  CheckInDocument,
} from '../graphql/generated';
import { useBetterGate } from '../hooks/useFeatureGate';
import { getManyFromList } from '../utils/arrayUtils';
import { logger } from '../utils/logger';
import { PersistenceStorage } from '../utils/storeUtils';

export const UserProfileState = PersistenceStorage({
  schema: z.object({
    currentPrivyId: z.string().nullable(),
    store: z.record(
      z.string(),
      z.object({
        userId: z.string().uuid(),
      }),
    ),
  }),
  key: 'userProfile',
  eager: true,
});

export async function setUserProfileId({ userId }: { userId: string }) {
  if (!UserProfileState.state.value?.currentPrivyId) {
    logger.error(
      {
        userId,
      },
      'Unexpected trying to set user profile id: No current privy id found',
    );
    return;
  }

  await UserProfileState.produceExistingState(
    draft => {
      const currentPrivyId = draft.currentPrivyId;
      if (!currentPrivyId || draft.store[currentPrivyId]?.userId === userId) return;

      draft.store[currentPrivyId] = {
        userId,
      };
    },
    {
      currentPrivyId: null,
      store: {},
    },
  );

  GraphQLReactQuery.removeQueries({
    predicate(query) {
      return !query.isActive();
    },
  });

  GraphQLReactQuery.refetchQueries({
    predicate(query) {
      return query.isActive();
    },
  });

  fetchGQL(CheckInDocument, {
    keepalive: true,
  });
}

export function useChosenUserProfile() {
  const { user: privyUser } = usePrivy();

  const featureGate = useBetterGate('AUTH_ARTIST_USER');

  const privyId = privyUser?.id ?? null;

  const state = UserProfileState.useStore().value;

  const currentPrivyId = state?.currentPrivyId;

  useEffect(() => {
    if (!privyId || featureGate !== 'enabled') return;

    if (currentPrivyId === privyId || UserProfileState.state.value?.currentPrivyId === privyId) {
      return;
    }

    UserProfileState.produceExistingState(
      draft => {
        if (draft.currentPrivyId === privyId) return;
        draft.currentPrivyId = privyId;
      },
      {
        currentPrivyId: privyId,
        store: {},
      },
    );
  }, [privyId, featureGate, currentPrivyId]);

  const userProfileId =
    featureGate === 'enabled' ? state?.store?.[privyId ?? '_']?.userId ?? null : null;

  /**
   * Reset if feature gate is disabled
   */
  useEffect(() => {
    if (!userProfileId || featureGate !== 'disabled') return;

    UserProfileState.produceExistingState(
      draft => {
        draft.currentPrivyId = null;
        draft.store = {};
      },
      {
        currentPrivyId: null,
        store: {},
      },
    );
  }, [userProfileId, featureGate]);

  return {
    userProfileId,
  };
}

gql(/* GraphQL */ `
  query AuthorizedUserProfiles {
    authorizedUserProfiles {
      user {
        __typename
        id
        username

        ... on PrivateUser {
          phone
        }

        artist {
          id
          name
          profileImage {
            id
            artistSmallProfileImageUrl: imageOptimizedUrl(input: { width: 200, height: 200 })
            artistProfileImagedUrl: imageOptimizedUrl
            dominantColor
          }
          mainVaultId
          mainVault {
            id
            type
          }
          userId
          linkValue
          links {
            value
          }
        }
      }
    }
  }
`);

export function useAuthorizedUserProfiles() {
  const authArtistUserFeatureGate = useBetterGate('AUTH_ARTIST_USER');

  const { user: privyUser } = usePrivy();

  const { data: authorizedUserProfiles } = useQuery(AuthorizedUserProfilesDocument, {
    staleTime: 0,
    enabled: authArtistUserFeatureGate === 'enabled' && privyUser != null,
    filterQueryKey: {
      privyId: privyUser?.id,
    },
    select: data => data.data.authorizedUserProfiles,
  });

  const artistsUserProfiles = useMemo<Array<NonNullable<AuthUserFragment['adminArtists']>[number]>>(
    () =>
      getManyFromList(
        authorizedUserProfiles,
        user =>
          user.user.artist && {
            artistId: user.user.artist.id,
            artistName: user.user.artist.name,
            artistMainLinkValue: user.user.artist.linkValue,
            artistLinks: user.user.artist.links.map(link => link.value),
            artistMainVaultId: user.user.artist.mainVaultId,
            artistMainVaultType: user.user.artist.mainVault.type,
            role: ArtistAdminRole.Artist,
            artistUserId: user.user.id,
            artistProfileImage: user.user.artist.profileImage,
          },
      ),
    [authorizedUserProfiles],
  );

  return { authorizedUserProfiles, authArtistUserFeatureGate, artistsUserProfiles };
}
