import { useMemo } from 'react';
import { useNavigate } from 'react-router';
import { faMessage } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { getFragment, RsvpEventCreatedFragmentDoc } from '../../graphql/generated';
import { useMassMessageForm } from '../../hooks/message/useMassMessageForm';
import { useCopy } from '../../hooks/useCopy';
import type { RsvpCreateSuccessBottomsheetProps } from '../../types/bottomsheetTypes';
import { generateShareLink } from '../../utils/linkUtils';
import { artistNavigationPath } from '../../utils/navigationUtils';
import { Button } from '../buttons/Button';
import { Image } from '../common/Image';
import { Text } from '../common/Text';
import { View } from '../common/View';
import { LoadingSkeleton } from '../loading/LoadingSkeleton';
import { UserPlaceholderImage } from '../user/UserPlaceholderImage';

gql(/* GraphQL */ `
  fragment RsvpEventCreated on RsvpEventPrivateInfo {
    id
    title
    coverImage {
      id
      rsvpCoverImageUrl: imageOptimizedUrl
    }
    linkValue
    artist {
      id
      linkValue
      mainVaultId
      profileImage {
        id
        artistFullProfileImageUrl: imageOptimizedUrl
      }
    }
    releaseAnnouncement {
      id
      content
      scheduledAt
      rsvpTarget
    }
  }
`);

export function RsvpCreateSuccessBottomsheet({ eventData }: RsvpCreateSuccessBottomsheetProps) {
  const {
    id: dropId,
    coverImage,
    linkValue,
    artist,
  } = getFragment(RsvpEventCreatedFragmentDoc, eventData);

  const navigate = useNavigate();
  const { closeBottomsheet } = useBottomsheetContainer();

  const { setField } = useMassMessageForm({
    vaultId: artist.mainVaultId,
  });

  const link = useMemo(() => {
    const path = !!linkValue ? `/d/${linkValue}` : `/d/${dropId}`;

    return generateShareLink({
      artistLinkValue: artist.linkValue,
      path,
      inviteCode: null,
    });
  }, [artist.linkValue, dropId, linkValue]);

  const { copy: onCopyLinkClick } = useCopy({
    text: link,
    successMessage: 'RSVP link copied to clipboard!',
  });

  const imageToShow =
    coverImage?.rsvpCoverImageUrl ?? artist.profileImage?.artistFullProfileImageUrl;

  return (
    <View className="flex flex-col items-center justify-center">
      {imageToShow ? (
        <Image
          className="mt-5 aspect-square w-24 rounded-xl object-cover"
          src={imageToShow}
          alt="campaign"
        />
      ) : (
        <UserPlaceholderImage
          id={crypto.randomUUID()}
          className="h-full w-full object-cover opacity-75 blur-2xl filter"
        />
      )}
      <Text className="mt-7 text-center text-title-l font-medium text-vault_text">
        You're live!
      </Text>
      <Text className="mt-3 text-center text-base-l font-normal text-vault_text/50">
        Share this page so fans can RSVP to your drop!
      </Text>

      <Button
        className="mt-6 w-full"
        type="primary-themed"
        label="Text members"
        leadingIcon={faMessage}
        onClick={() => {
          closeBottomsheet();
          setField('message', `RSVP here: ${link}`);
          navigate(artistNavigationPath(artist.linkValue, '/messages/create'));
        }}
      />
      <Text
        className="my-6 cursor-pointer text-center text-base-m font-medium text-vault_text"
        onClick={onCopyLinkClick}
      >
        Copy URL
      </Text>
    </View>
  );
}

export function EventCreateSuccessBottomsheetSkeleton() {
  return (
    <View className="flex flex-col items-center justify-center">
      <LoadingSkeleton className="mt-5 aspect-square w-24 rounded-xl" />
      <LoadingSkeleton className="mt-7 h-8 w-48" />
      <LoadingSkeleton className="mt-3 h-16 w-[300px]" />

      <LoadingSkeleton className="rounded mt-6 h-10 w-full rounded-full" />
      <LoadingSkeleton className="my-6 h-6 w-16 rounded-xl" />
    </View>
  );
}
