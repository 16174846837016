import { gql } from '@soundxyz/gql-string';
import { useAuthContext } from '../../contexts/AuthContext';
import { useInfiniteQuery } from '../../graphql/client';
import type { ArtistMembershipReceiptTypenames } from '../../graphql/generated';
import {
  ArtistAdminReceiptsDocument,
  UserArtistReceiptsDocument,
} from '../../graphql/generated/documents';
import { LoginStatus } from '../../types/authTypes';
import { useAdminArtist } from '../useAdminArtist';

gql(/* GraphQL */ `
  query ArtistAdminReceipts(
    $artistHandle: String!
    $after: String
    $first: Int!
    $receiptTypes: [ArtistMembershipReceiptTypenames!]
  ) {
    receipts: allArtistMembershipReceipts(
      artistHandle: $artistHandle
      first: $first
      after: $after
      receiptTypes: $receiptTypes
    ) {
      edges {
        node {
          id
          __typename
          ...CampaignReceiptItem
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }

  query UserArtistReceipts(
    $artistHandle: String!
    $after: String
    $first: Int!
    $receiptTypes: [ArtistMembershipReceiptTypenames!]
  ) {
    receipts: userArtistMembershipReceipts(
      artistHandle: $artistHandle
      first: $first
      after: $after
      receiptTypes: $receiptTypes
    ) {
      edges {
        node {
          id
          __typename
          ...CampaignReceiptItem
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`);

export const useUserArtistReceipts = ({
  artistHandle,
  limit = 15,
  source,
  receiptTypes,
  enabled,
}: {
  artistHandle?: string;
  limit?: number;
  source: 'receipts_page' | 'membership_page';
  receiptTypes: ArtistMembershipReceiptTypenames[] | null;
  enabled: boolean;
}) => {
  const { loginStatus } = useAuthContext();

  const adminArtistId = useAdminArtist({
    artistHandle,
  })?.artistId;

  const {
    orderedList: userArtistReceipts,
    isInitialLoading,
    isError,
    refetch,
    hasNextPage,
    loadMoreNextPage,
    isLoadingNewPage,
    isRefetching,
  } = useInfiniteQuery(adminArtistId ? ArtistAdminReceiptsDocument : UserArtistReceiptsDocument, {
    filterQueryKey: { artistHandle, source, adminArtistId, receiptTypes },
    staleTime: 0,
    enabled: !!artistHandle && loginStatus === LoginStatus.LOGGED_IN && enabled,
    getNextPageParam: ({ data }) => {
      return (
        data.receipts.pageInfo.hasNextPage && {
          after: data.receipts.pageInfo.endCursor,
        }
      );
    },
    variables:
      !!artistHandle &&
      (({ pageParam }) => {
        return {
          artistHandle,
          after: pageParam?.after ?? null,
          first: limit,
          receiptTypes,
        };
      }),

    list: ({ receipts }) => {
      return receipts.edges.map(edge => edge.node);
    },
    uniq: ({ id }) => id,
  });

  return {
    userArtistReceipts,
    isInitialLoading,
    isError,
    refetch,
    hasNextPage,
    loadMoreNextPage,
    isLoadingNewPage,
    isRefetching,
  };
};
