import type { FC } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { FormProvider, useForm } from 'react-hook-form';
import { Navigate } from 'react-router';

import * as z from 'zod';

import { View } from '../../../components/common/View';
import { HookAutoSave } from '../../../components/forms/hook-form/Autosave';
import { HookToggle } from '../../../components/forms/hook-form/Toggle';
import { SettingsLayout } from '../../../components/layouts/SettingsLayout';
import { ROUTES } from '../../../constants/routeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { setQueryData } from '../../../graphql/client';
import {
  type FragmentType,
  getFragment,
  UserNotificationSettingsDocument,
  UserNotificationSettingsFragmentDoc,
} from '../../../graphql/generated';
import { useNotificationSettings } from '../../../hooks/useNotificationSettings';
import { LoginStatus } from '../../../types/authTypes';

export const PushNotificationSettings: FC = () => {
  const { loginStatus } = useAuthContext();

  if (loginStatus === LoginStatus.LOGGED_OUT) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  return <SettingsLoader />;
};

const SettingsLoader = () => {
  const { data, isLoading } = useNotificationSettings();

  if (isLoading || data?.currentUser.__typename !== 'QueryCurrentUserSuccess') {
    return null;
  }

  const settings = data?.currentUser.data;
  return (
    <SettingsLayout title="Push notifications">
      <SettingsForm notificationSettings={settings} />
    </SettingsLayout>
  );
};

const validationSchema = z.object({
  isPushEnabled: z.boolean(),
});

type ValidationSchema = z.infer<typeof validationSchema>;

const SettingsForm = ({
  notificationSettings,
}: {
  notificationSettings: FragmentType<UserNotificationSettingsFragmentDoc>;
}) => {
  const { updatePushSettings } = useNotificationSettings();
  const user = getFragment(UserNotificationSettingsFragmentDoc, notificationSettings);

  const hookForm = useForm<ValidationSchema>({
    defaultValues: {
      isPushEnabled: user.notificationSettings.pushNotificationsEnabled,
    },
    resolver: zodResolver(validationSchema),
  });

  const { control } = hookForm;

  const onSubmit = async (data: ValidationSchema) => {
    const resp = await updatePushSettings({
      input: {
        notificationsEnabled: data.isPushEnabled,
      },
    });

    setQueryData(
      {
        query: UserNotificationSettingsDocument,
      },
      {
        data: {
          currentUser: {
            __typename: 'QueryCurrentUserSuccess',
            data: {
              ...getFragment(
                UserNotificationSettingsFragmentDoc,
                resp.data.updatePushNotificationsSettings,
              ),
            },
          },
        },
      },
    );

    return data;
  };

  return (
    <View className="flex w-full flex-col">
      <FormProvider {...hookForm}>
        <HookAutoSave onSubmit={onSubmit} />
        <HookToggle label="All push notifications" control={control} name="isPushEnabled" />
      </FormProvider>
    </View>
  );
};
