import { useEffect, useMemo, useState } from 'react';
import { useLoginWithSms } from '@privy-io/react-auth';
import parsePhoneNumberFromString, {
  type CountryCode,
  isValidPhoneNumber,
} from 'libphonenumber-js/min';
import { AUTH_ERROR_ACTIONS, ERROR_TYPE, PILLARS } from '@soundxyz/vault-logs-utils/dist/constants';
import { COUNTRY_CODES } from '../constants/phoneConstants';
import { SignInStore, THIRTY_SECONDS_IN_MS } from '../screens/auth/store';
import { EVENTS } from '../types/eventTypes';
import { trackEvent } from '../utils/analyticsUtils';
import { wrapSendCode } from '../utils/privy';
import { useLogError } from './logger/useLogError';
import { useStableCallback } from './useStableCallback';

export function useSignIn() {
  const signInState = SignInStore.useStore().value;
  const logError = useLogError();
  const [phone, setPhone] = useState<string>('');
  const { sendCode } = useLoginWithSms();

  const [countryCode, setCountryCode] = useState<string>(() => {
    if (signInState?.lastCountryCode != null) return signInState.lastCountryCode;

    const intlCountry = Intl.DateTimeFormat().resolvedOptions().locale.split('-')[1];

    if (!intlCountry) return 'US';

    return COUNTRY_CODES.find(({ code }) => code === intlCountry)?.code ?? 'US';
  });
  const [errorText, setErrorText] = useState<string | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (countryCode === SignInStore.state.value?.lastCountryCode) return;

    SignInStore.produceExistingState(
      draft => {
        draft.lastCountryCode = countryCode;
      },
      {
        codeRenabled: {},
        lastActivePhoneNumber: null,
        lastCountryCode: countryCode,
      },
    );
  }, [countryCode]);

  const validPhoneNumber = useMemo(() => {
    const phoneNumber = parsePhoneNumberFromString(phone, countryCode as CountryCode)?.number;

    if (
      phoneNumber == null ||
      (!isValidPhoneNumber(phone, countryCode as CountryCode) && phoneNumber !== '+15555555555')
    ) {
      return null;
    }

    return phoneNumber;
  }, [phone, countryCode]);

  const codeRenabled = signInState?.codeRenabled[validPhoneNumber ?? '_'] ?? 1;
  const lastActivePhoneNumber = signInState?.lastActivePhoneNumber;

  const onSignInClick = useStableCallback(
    async ({ onSuccess, source }: { onSuccess?: () => void; source: string }) => {
      if (!validPhoneNumber) {
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: new Error('Invalid phone number'),
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'log',
          message: 'Invalid phone number',
          pillar: PILLARS.AUTH,
          indexedTags: {
            phoneNumber: phone,
          },
          unindexedExtra: {
            source,
          },
        });
        setErrorText('This phone number cannot be used for verification');
        return;
      }

      try {
        setErrorText(null);
        await wrapSendCode(() => sendCode({ phoneNumber: validPhoneNumber }));

        const codeRenabled = Date.now() + THIRTY_SECONDS_IN_MS;

        SignInStore.produceExistingState(
          draft => {
            draft.lastActivePhoneNumber = validPhoneNumber;
            draft.codeRenabled[validPhoneNumber] = codeRenabled;
          },
          {
            codeRenabled: {
              [validPhoneNumber]: codeRenabled,
            },
            lastActivePhoneNumber: validPhoneNumber,
          },
        );

        onSuccess?.();
      } catch (e) {
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: e,
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'warning',
          message: 'We encountered an error sending your verification code',
          pillar: PILLARS.AUTH,
          indexedTags: {
            phoneNumber: validPhoneNumber,
          },
          unindexedExtra: {
            source,
          },
        });

        setErrorText('We encountered an error sending your verification code');
        return;
      }
    },
  );

  const onResendCodeClick = useStableCallback(
    async ({
      source,
      onSuccess,
      codeSentDisabledSecondsRemaining = 0,
      pathname,
    }: {
      codeSentDisabledSecondsRemaining: number;
      source: string;
      onSuccess?: () => void;
      pathname?: string;
    }) => {
      trackEvent({
        type: EVENTS.RESEND_CODE,
        properties: null,
        pathname,
      });
      if (!validPhoneNumber) {
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: new Error('Invalid phone number'),
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'log',
          message: 'Invalid phone number',
          pillar: PILLARS.AUTH,
          indexedTags: {
            phoneNumber: phone,
          },
          unindexedExtra: {
            source,
          },
        });
        setErrorText('This phone number cannot be used for verification');
        return;
      }

      if (codeSentDisabledSecondsRemaining > 0) return;

      try {
        await wrapSendCode(() => sendCode({ phoneNumber: `+${validPhoneNumber}` }));

        const codeRenabled = Date.now() + THIRTY_SECONDS_IN_MS;
        SignInStore.produceExistingState(
          draft => {
            draft.codeRenabled[validPhoneNumber] = codeRenabled;
            draft.lastActivePhoneNumber = validPhoneNumber;
          },
          {
            codeRenabled: { [validPhoneNumber]: codeRenabled },
            lastActivePhoneNumber: validPhoneNumber,
          },
        );
        onSuccess?.();
      } catch (e) {
        logError({
          action: AUTH_ERROR_ACTIONS.LOG_IN_ERROR,
          error: e,
          errorType: ERROR_TYPE.AUTH_ERROR,
          level: 'warning',
          message: 'We encountered an error re-sending your verification code',
          pillar: PILLARS.AUTH,
          indexedTags: {
            phoneNumber: validPhoneNumber,
          },
          unindexedExtra: {
            source,
          },
        });
        setErrorText('We encountered an error re-sending your verification code');

        return;
      }
    },
  );

  return {
    countryCode,
    errorText,
    isOpen,
    phone,
    setCountryCode,
    setErrorText,
    setIsOpen,
    setPhone,
    validPhoneNumber,
    codeRenabled,
    lastActivePhoneNumber,
    onSignInClick,
    onResendCodeClick,
  };
}
