import { forwardRef, type MouseEventHandler } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { twMerge } from 'tailwind-merge';
import { faCalendar } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { Text } from '../common/Text';

export const DateInput = forwardRef<
  HTMLButtonElement,
  {
    className?: string;
    value?: string | null;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    isThemeEnabled: boolean;
  }
>(({ className, value, onClick, isThemeEnabled }, ref) => (
  <button
    className={twMerge(
      'flex w-full cursor-pointer appearance-none items-center justify-between border-none bg-transparent py-5 outline-none',
      isThemeEnabled
        ? 'text-vault_text/50 hover:text-vault_text/60'
        : 'text-base500 hover:text-base400',
      className,
    )}
    ref={ref}
    onClick={onClick}
  >
    <Text className={twMerge('font-base !text-base-l font-normal')}>{value ? value : 'Now'}</Text>
    <FontAwesomeIcon icon={faCalendar} fontSize={16} />
  </button>
));
