import { View } from '../../components/common/View';
import { ArtistEventFragmentDoc } from '../../graphql/generated';
import { type FragmentType, getFragment } from '../../graphql/generated';
import { ReleaseCampaignEventItem } from './items/ReleaseCampaignEventItem';
import { RsvpEventItem } from './items/RsvpEventItem';

export const EventItem = ({ eventData }: { eventData: FragmentType<ArtistEventFragmentDoc> }) => {
  const fragment = getFragment(ArtistEventFragmentDoc, eventData);

  if (fragment.__typename === 'ArtistReleaseCampaignEvent') {
    const { releaseCampaign } = fragment;

    return (
      <ReleaseCampaignEventItem
        eventId={fragment.id}
        releaseCampaign={releaseCampaign}
        actions={fragment.actions}
      />
    );
  }

  const { rsvpEvent } = fragment;
  return <RsvpEventItem eventId={fragment.id} actions={fragment.actions} rsvpEvent={rsvpEvent} />;
};

export const EventItemSkeleton = () => (
  <View className="flex h-[72px] w-full items-center justify-between gap-3">
    <View className="flex h-16 w-full justify-between gap-4">
      <View className="flex aspect-square w-16 flex-shrink-0 animate-pulse items-center justify-center rounded-md bg-vault_text/10" />
      <View className="flex w-full flex-col justify-center gap-2 ">
        <View className="h-5 w-[38%] animate-pulse rounded-sm bg-vault_text/10" />
        <View className="flex flex-col gap-1">
          <View className="h-3 w-[20%] animate-pulse rounded-sm bg-vault_text/10" />
        </View>
      </View>
    </View>
  </View>
);
