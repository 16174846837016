import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Virtuoso } from 'react-virtuoso';
import { faSpinner } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { BackButton } from '../../../components/buttons/BackButton';
import { Text } from '../../../components/common/Text';
import { ErrorView } from '../../../components/error/ErrorView';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { ReceiptItem, ReceiptItemSkeleton } from '../../../components/membership/ReceiptItem';
import { EmptyReceipts } from '../../../components/membership/shared';
import { useAuthContext } from '../../../contexts/AuthContext';
import type { CampaignReceiptItemFragmentDoc } from '../../../graphql/generated';
import { type FragmentType } from '../../../graphql/generated';
import { useUserArtistReceipts } from '../../../hooks/membership/useUserArtistReceipts';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useStableCallback } from '../../../hooks/useStableCallback';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { LoginStatus } from '../../../types/authTypes';
import { artistNavigationPath } from '../../../utils/navigationUtils';

export const ReceiptsPage = () => {
  const { artistHandle } = useArtistHandle();
  const { loginStatus } = useAuthContext();

  const { userArtistReceipts, hasNextPage, isInitialLoading, isError, loadMoreNextPage, refetch } =
    useUserArtistReceipts({
      artistHandle,
      source: 'receipts_page',
      receiptTypes: null,
      limit: 50,
      enabled: loginStatus === LoginStatus.LOGGED_IN,
    });

  const renderItems = useStableCallback(
    (
      _i: number,
      receiptData: FragmentType<CampaignReceiptItemFragmentDoc> & {
        id: string;
      },
    ) => {
      return (
        <div
          key={`${_i}-${receiptData.id}-receipts_page`}
          className="flex h-20 w-full items-center "
        >
          <ReceiptItem receiptData={receiptData} />
        </div>
      );
    },
  );
  useVaultTheme();

  const memoizedFooter = React.useMemo(
    () =>
      hasNextPage ? (
        <div className="flex w-full items-center justify-center py-5">
          <FontAwesomeIcon
            icon={faSpinner}
            size="2xl"
            className="ml-2 inline-block animate-spin rounded-full font-medium text-vault_text/50"
          />
        </div>
      ) : (
        <div className="h-4" />
      ),
    [hasNextPage],
  );

  return (
    <DefaultLayout
      withVaultTheme
      showRoundedTop
      showBorder
      withBottomNavigator={false}
      headerLeft={
        <BackButton
          className="text-vault_text"
          href={artistNavigationPath(artistHandle, '/drops')}
        />
      }
      hasChatReadAccess={false}
      isHeaderTransparent={false}
      headerClassName="bg-vault_background"
      vaultId=""
      messageChannelId=""
      headerCenter={
        <Text className="font-title !text-title-m font-medium text-vault_text">Your RSVPs</Text>
      }
      extend
    >
      <div className="box-border flex h-full min-h-[calc(100vh-85px)] w-full flex-col ">
        {isInitialLoading || loginStatus === LoginStatus.LOADING ? (
          <div className="flex flex-col">
            {Array.from({ length: 3 }).map((_, index) => (
              <div key={index} className="flex h-20 w-full items-center ">
                <ReceiptItemSkeleton />
              </div>
            ))}
          </div>
        ) : isError ? (
          <div className="flex h-[calc(100vh-400px)] w-full flex-col items-center justify-center gap-6 ">
            <ErrorView
              className="flex-grow"
              onRetryClick={refetch}
              loggingType="events_page"
              withVaultTheme={false}
            />
          </div>
        ) : !userArtistReceipts || userArtistReceipts.length === 0 ? (
          <EmptyReceipts artistHandle={artistHandle} />
        ) : (
          <Virtuoso
            className="no-scrollbar h-full w-full"
            data={userArtistReceipts}
            itemContent={renderItems}
            components={{ Footer: () => memoizedFooter }}
            increaseViewportBy={700}
            endReached={loadMoreNextPage}
          />
        )}
      </div>
    </DefaultLayout>
  );
};
