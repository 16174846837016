import React, { useCallback, useEffect, useLayoutEffect, useMemo } from 'react';
import { Navigate, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { gql } from '@soundxyz/gql-string/gql';
import { View } from '../../../components/common/View';
import { DefaultLayout } from '../../../components/layouts/DefaultLayout';
import { EventsSection } from '../../../components/membership/EventsSection';
import { ReceiptsSection } from '../../../components/membership/ReceiptsSection';
import { AllAccessPassWithForm } from '../../../components/vault/AllAccessPass';
import { FullPageLoading } from '../../../components/views/FullPageLoading';
import { MembershipView } from '../../../components/views/MembershipView';
import { BOTTOMSHEET_TYPES } from '../../../constants/bottomsheetConstants';
import { ROUTES } from '../../../constants/routeConstants';
import { DEFAULT_PRICE } from '../../../constants/stripeConstants';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useBottomsheetContainer } from '../../../contexts/BottomsheetContext';
import { useQuery } from '../../../graphql/client';
import {
  FeatureTypename,
  type MembershipArtistQuery,
  TierTypename,
} from '../../../graphql/generated';
import { MembershipArtistDocument } from '../../../graphql/generated/documents';
import { useMembership } from '../../../hooks/membership/useMembership';
import { useAdminArtist } from '../../../hooks/useAdminArtist';
import { useArtistHandle } from '../../../hooks/useArtistHandle';
import { useFreeTier } from '../../../hooks/useFreeTier';
import { useVaultTheme } from '../../../hooks/useVaultTheme';
import { LoginStatus } from '../../../types/authTypes';
import { constructQueryParams } from '../../../utils/stringUtils';

gql(/* GraphQL */ `
  query MembershipArtist($link: String!) {
    artistLink(link: $link) {
      artist {
        id
        name
        linkValue
        profileImage {
          id
          cardImageOptimizedUrl: imageOptimizedUrl
        }
        membershipCardImage {
          id
          membershipCardImageOptimizedUrl: imageOptimizedUrl
        }
        mainVaultId
        mainVault {
          id
          price
          activeSubscription {
            id
          }
          tiers {
            __typename
            enabledFeatures {
              feature {
                __typename
              }
            }
          }
        }
        createdAt
      }
    }
  }
`);

export const MembershipPage = () => {
  useVaultTheme();

  const { loginStatus, loggedInUser } = useAuthContext();
  const { artistHandle } = useArtistHandle();

  const adminArtist = useAdminArtist({
    artistHandle,
  });
  const isOwner = !!artistHandle && !!adminArtist;
  const { membership, isLoading: isLoadingMembership } = useMembership({
    artistHandle,
    hasActiveSubscription: !isOwner,
  });

  const randomSerialId = useMemo(() => Math.floor(Math.random() * 1000), []);

  const [searchParams, setSearchParams] = useSearchParams();
  const code = searchParams.get('code'); // referral link code
  const invite = searchParams.get('invite');
  const smsCampaignResponseShortcode = searchParams.get('c');
  const bottomSheetType = searchParams.get('openBottomSheet');
  const showReceiptModal = searchParams.get('showReceiptModal');

  const receiptId = searchParams.get('receiptId');

  const navigate = useNavigate();

  const { openBottomsheet } = useBottomsheetContainer();

  const now = useMemo(() => new Date(), []);

  const { data, isLoading: isLoadingArtist } = useQuery(MembershipArtistDocument, {
    staleTime: 0,
    variables: !!artistHandle && {
      link: artistHandle.toLowerCase(),
    },
    keepPreviousData: true,
  });

  const artist = data?.data.artistLink?.artist || null;

  const { user, serialNumber, createdAt, receipts } = membership || {};

  const artistName = artist?.name ?? artistHandle;
  const artistMembershipCardImage = artist?.profileImage?.cardImageOptimizedUrl;
  const vaultId = artist?.mainVaultId;
  const linkValue = artist?.linkValue;

  const isLoading = isLoadingArtist || isLoadingMembership;

  const username =
    user?.displayName ??
    user?.username ??
    loggedInUser?.displayName ??
    loggedInUser?.username ??
    'username';
  const createdAtDate = adminArtist
    ? data?.data.artistLink?.artist.createdAt
    : createdAt ?? now.toISOString();

  const onJoinFreeClick = useCallback(async () => {
    if (!vaultId || !linkValue) return;

    if (loginStatus === LoginStatus.LOGGED_IN) {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
        membershipConfirmationBottomsheetProps: {
          vaultId,
          isLoading: false,
          artistHandle: artistHandle,
          artistName: artistName,
          imageUrl: artistMembershipCardImage,
          loggedInUserUsername: loggedInUser?.username,
          loginStatus,
          inviteCode: null,
          smsCampaignResponseShortcode,
          sourceReleaseCampaignId: null,
        },
        shared: {
          hideCloseBottomsheetButton: false,
          preventSwipeToDismiss: false,
          preventOutsideAutoClose: true,
          hidePulleyBar: true,
          withVaultTheme: true,
          isUnclosable: true,
          onClose: () => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.delete('openBottomSheet');
            setSearchParams(newSearchParams, { replace: true });
          },
        },
      });
    } else {
      const queryParams = constructQueryParams({
        artistHandle: linkValue,
        openBottomSheet: 'freeTierModal',
        c: smsCampaignResponseShortcode,
      });

      navigate(`${ROUTES.SIGN_IN}${queryParams ? `?${queryParams}` : ''}`);
      return;
    }
  }, [
    vaultId,
    linkValue,
    loginStatus,
    openBottomsheet,
    artistHandle,
    artistName,
    artistMembershipCardImage,
    loggedInUser?.username,
    smsCampaignResponseShortcode,
    searchParams,
    setSearchParams,
    navigate,
  ]);

  const handleOnboardingFlow = useCallback(() => {
    if (loginStatus !== LoginStatus.LOGGED_IN || !loggedInUser || !!code) return;

    if (loggedInUser?.username == null) {
      const queryParams = constructQueryParams({
        artistHandle,
        invite,
        smsCampaignResponseShortcode,
        openBottomSheet: bottomSheetType,
        redirect: 'drops',
      });
      navigate(`${ROUTES.ONBOARDING_USERNAME}${queryParams ? `?${queryParams}` : ''}`);
    }
  }, [
    artistHandle,
    bottomSheetType,
    code,
    invite,
    loggedInUser,
    loginStatus,
    navigate,
    smsCampaignResponseShortcode,
  ]);

  useEffect(() => {
    handleOnboardingFlow();
  }, [handleOnboardingFlow]);

  const hasMembership =
    data?.data.artistLink?.artist.mainVault.activeSubscription != null ||
    !!membership?.artist.mainVault.activeSubscription;

  const handleMembershipConfirmation = useCallback(() => {
    if (isLoadingMembership) return;
    if (
      loginStatus === LoginStatus.LOGGED_IN &&
      !!loggedInUser?.id &&
      !hasMembership &&
      bottomSheetType === 'freeTierModal' &&
      !!vaultId
    ) {
      openBottomsheet({
        type: BOTTOMSHEET_TYPES.MEMBERSHIP_CONFIRMATION,
        membershipConfirmationBottomsheetProps: {
          vaultId,
          isLoading: false,
          artistHandle: artistHandle,
          artistName: artistName,
          imageUrl: artistMembershipCardImage,
          loggedInUserUsername: loggedInUser?.username,
          loginStatus,
          inviteCode: null,
          smsCampaignResponseShortcode,
          sourceReleaseCampaignId: null,
        },
        shared: {
          hideCloseBottomsheetButton: false,
          preventSwipeToDismiss: false,
          preventOutsideAutoClose: true,
          hidePulleyBar: true,
          withVaultTheme: true,
          isUnclosable: true,
          onClose: () => {
            const newSearchParams = new URLSearchParams(searchParams);
            newSearchParams.delete('openBottomSheet');
            setSearchParams(newSearchParams, { replace: true });
          },
        },
      });
    }
  }, [
    artistHandle,
    artistMembershipCardImage,
    artistName,
    bottomSheetType,
    hasMembership,
    isLoadingMembership,
    loggedInUser?.id,
    loggedInUser?.username,
    loginStatus,
    openBottomsheet,
    searchParams,
    setSearchParams,
    smsCampaignResponseShortcode,
    vaultId,
  ]);

  useEffect(() => {
    handleMembershipConfirmation();
  }, [handleMembershipConfirmation]);

  const handleOpenReceiptModal = useCallback(() => {
    if (!receiptId || showReceiptModal !== 'true') return;

    openBottomsheet({
      type: BOTTOMSHEET_TYPES.RECEIPT,
      shared: {
        onClose: () => {
          setSearchParams(new URLSearchParams(), { replace: true });
        },
      },

      receiptBottomSheetProps: {
        id: receiptId,
      },
    });
  }, [openBottomsheet, receiptId, setSearchParams, showReceiptModal]);

  useLayoutEffect(() => {
    handleOpenReceiptModal();
  }, [handleOpenReceiptModal]);

  if (artistHandle == null || (!isLoading && !artist)) {
    return <Navigate to={ROUTES.NOT_FOUND} />;
  }

  if (loginStatus === LoginStatus.LOADING) {
    return <FullPageLoading withVaultTheme />;
  }

  return (
    <DefaultLayout
      messageChannelId={undefined}
      withBottomNavigator
      vaultId={vaultId}
      hasChatReadAccess={false}
      shouldSkipMargin={false}
      headerClassName="md2:bg-vault_text/3 pt-4"
      contentClassName="md2:bg-vault_text/3 relative"
      showBorder
      showRoundedTop
      withVaultTheme
      isHeaderTransparent
    >
      <MembershipV2Page
        isLoading={isLoading}
        serialNumber={serialNumber || randomSerialId}
        imageUrl={artistMembershipCardImage}
        displayName={username}
        createdAt={createdAtDate}
        receiptCount={receipts}
        hasMembership={hasMembership}
        onJoinFreeClick={onJoinFreeClick}
        artistHandle={artistHandle}
        artist={artist}
        hasActiveSubscription={membership?.artist.mainVault.activeSubscription != null}
      />
    </DefaultLayout>
  );
};

const MembershipV2Page = ({
  isLoading,
  serialNumber,
  imageUrl,
  displayName,
  createdAt,
  receiptCount,
  artist,
  onJoinFreeClick,
  artistHandle,
  hasActiveSubscription,
}: {
  className?: string;
  isLoading: boolean;
  serialNumber: number | null | undefined;
  imageUrl: string | null | undefined;
  displayName: string | null | undefined;
  createdAt: string | undefined;
  receiptCount: number | undefined;
  hasMembership: boolean;
  onJoinFreeClick: () => void;
  artistHandle: string;
  artist: NonNullable<MembershipArtistQuery['artistLink']>['artist'] | null;
  hasActiveSubscription: boolean;
}) => {
  const { isSubscribingFreeTier } = useFreeTier();

  const monthlySubPrice = artist?.mainVault?.price ?? DEFAULT_PRICE;

  const chatAvailableForFreeUsers =
    artist?.mainVault.tiers
      ?.find(tier => tier.__typename === TierTypename.FreeTier)
      ?.enabledFeatures.some(({ feature }) => feature.__typename === FeatureTypename.ChatRead) ??
    false;

  const adminArtist = useAdminArtist({
    artistHandle,
  });

  return (
    <MembershipView
      isLoading={isLoading}
      artistName={artist?.name ?? ''}
      serialNumber={serialNumber}
      imageUrl={imageUrl}
      displayName={displayName}
      createdAt={createdAt}
      receiptCount={receiptCount}
      artistHandle={artistHandle}
    >
      <View
        className={twMerge(
          'flex w-full flex-col gap-8 pb-10',
          hasActiveSubscription || adminArtist || isLoading ? 'pt-8' : '',
        )}
      >
        {adminArtist || hasActiveSubscription || isLoading ? null : (
          <>
            <AllAccessPassWithForm
              chatAvailableForFreeUsers={chatAvailableForFreeUsers}
              isSubscribingFreeTier={isSubscribingFreeTier}
              linkValue={artistHandle}
              monthlySubPrice={monthlySubPrice}
              onJoinFreeClick={onJoinFreeClick}
            />
          </>
        )}

        <EventsSection artistHandle={artistHandle} />
        {hasActiveSubscription || adminArtist ? <ReceiptsSection /> : null}
      </View>
    </MembershipView>
  );
};
