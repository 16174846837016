import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDropzone } from 'react-dropzone';
import { faVideo } from '@soundxyz/font-awesome/pro-solid-svg-icons';
import { ACCEPTED_VIDEO_TYPES } from '../../constants/fileConstants';
import { useBottomsheetContainer } from '../../contexts/BottomsheetContext';
import { useToast } from '../../contexts/ToastContext';
import { useAdminArtist } from '../../hooks/useAdminArtist';
import { useVideoAttachments } from '../../hooks/useVideoAttachment';
import { Text } from '../common/Text';

const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024; // 2GB

export const VideoMessageUpload = ({
  artistId,
  onVideoSelect,
}: {
  artistId: string | null | undefined;
  onVideoSelect: () => void;
}) => {
  const adminArtist = useAdminArtist({ artistId: artistId ?? undefined });
  const { openToast } = useToast();
  const { closeBottomsheet } = useBottomsheetContainer();
  const { saveVideoAttachment } = useVideoAttachments();

  const { getRootProps, getInputProps } = useDropzone({
    accept: ACCEPTED_VIDEO_TYPES,
    multiple: false,
    maxSize: MAX_FILE_SIZE,
    onDrop: (acceptedFiles, fileRejections) => {
      const sizeError = fileRejections.some(rejection =>
        rejection.errors.some(error => error.code === 'file-too-large'),
      );

      if (sizeError) {
        openToast({
          text: 'The video file is too large. Please upload a file smaller than 2GB.',
          variant: 'error',
        });
        return;
      }
      if (acceptedFiles.length === 1) {
        onVideoSelect();
        saveVideoAttachment(adminArtist?.artistId, acceptedFiles[0] as File);
        closeBottomsheet();
      }
    },
  });

  return (
    <div {...getRootProps()} className="flex cursor-pointer flex-row items-center gap-2">
      <input {...getInputProps()} />
      <FontAwesomeIcon icon={faVideo} size="sm" className="text-vault_text" />
      <Text className="!text-base-l font-medium text-vault_text">Upload a video</Text>
    </div>
  );
};
