import { useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { DEFAULT_PRICE } from '../../constants/stripeConstants';
import type { VaultType } from '../../graphql/generated';
import { useLockedMessages } from '../../hooks/useLockedMessages';
import { View } from '../common/View';
import { MockMessageBubble } from '../message/MockMessageBubble';
import { LockedContent } from '../messages/LockedContent';

export const LockedChatView = ({
  vaultId,
  vaultType,
  linkValue,
  vaultArtistName,
  vaultArtistImage,
  vaultArtistMembershipCardImage,
  monthlySubPrice = DEFAULT_PRICE,
  chatAvailableForFreeUsers,
  showJoinFree,
}: {
  vaultId: string | undefined;
  vaultType: VaultType | undefined;
  linkValue: string | undefined;
  vaultArtistName: string | undefined;
  vaultArtistImage: string | undefined | null;
  vaultArtistMembershipCardImage: string | undefined | null;
  monthlySubPrice: number;
  chatAvailableForFreeUsers: boolean;
  showJoinFree: boolean;
  dominantColor: string | null | undefined;
}) => {
  const messages = useLockedMessages({ vaultArtistName, vaultArtistImage });
  const messagesRef = useRef<HTMLDivElement>(null);
  const [view, setView] = useState<'join' | 'verify'>('join');

  return (
    <View className="relative flex h-full w-full flex-1 overflow-clip overscroll-none">
      <View containerRef={messagesRef} className="flex w-full flex-1 flex-col">
        {messages.map((message, i) => (
          <MockMessageBubble
            key={i}
            className={twMerge(i !== 0 && 'mt-4')}
            author={message.author}
            content={message.content}
            profileImageUrl={message.profileImageUrl}
            reactionSummary={message.reactionSummary}
            isVaultArtist={message.isVaultArtist}
            hasVaultContent={message.hasVaultContent}
          />
        ))}
      </View>
      <View className="pointer-events-none absolute bottom-0 left-0 right-0 h-[70vh] bg-gradient-to-t from-vault_background via-vault_background  via-80% to-transparent md:h-[75vh]" />

      <LockedContent
        showJoinFree={showJoinFree}
        view={view}
        setView={setView}
        vaultId={vaultId}
        linkValue={linkValue}
        artistName={vaultArtistName}
        memberCardImage={vaultArtistMembershipCardImage}
        monthlySubPrice={monthlySubPrice}
        artistImage={vaultArtistImage}
        vaultType={vaultType}
        chatAvailableForFreeUsers={!!chatAvailableForFreeUsers}
        joinFreeContainerClassName="hidden"
      />
    </View>
  );
};
