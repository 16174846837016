import type { FC } from 'react';
import React, { useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toPng } from 'html-to-image';
import { faArrowDownToBracket } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { gql } from '@soundxyz/gql-string';
import { useToast } from '../../contexts/ToastContext';
import { useQuery } from '../../graphql/client';
import { GetArtistMembershipReceiptDocument } from '../../graphql/generated';
import type { ReceiptBottomSheetProps } from '../../types/bottomsheetTypes';
import { View } from '../common/View';
import { Receipt } from '../membership/receipt/Receipt';

gql(/* GraphQL */ `
  query GetArtistMembershipReceipt($id: UUID!) {
    membershipReceipt(input: { receiptId: $id }) {
      id
      __typename
      ... on ArtistMembershipPlayStreamReceipt {
        releaseCampaign {
          id
          title
        }
      }
      ... on ArtistMembershipPresaveReceipt {
        releaseCampaign {
          id
          title
        }
      }
      ... on ArtistMembershipRsvpEventReceipt {
        rsvpEvent {
          id
          title
        }
      }
      ...MembershipReceipt
    }
  }
`);

const ReceiptBottomSheet: FC<ReceiptBottomSheetProps> = ({ id: receiptId, receiptFrag, title }) => {
  const receiptRef = useRef(null);
  const { openToast } = useToast();

  const hasFrag = !!receiptFrag;

  const { data, isLoading } = useQuery(GetArtistMembershipReceiptDocument, {
    variables: {
      id: receiptId,
    },
    enabled: !!receiptId && !hasFrag,
    staleTime: 0,
  });

  const receiptData = data?.data.membershipReceipt;
  const titleFromQuery = receiptData
    ? receiptData.__typename === 'ArtistMembershipRsvpEventReceipt'
      ? receiptData.rsvpEvent.title
      : receiptData.releaseCampaign.title
    : null;

  const fileTitle = titleFromQuery ?? title;

  const htmlToImageConvert = () => {
    if (!receiptRef.current) return;
    toPng(receiptRef.current, { cacheBust: false })
      .then(dataUrl => {
        const link = document.createElement('a');
        link.download = `${fileTitle}.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch(() => {
        openToast({
          text: 'There was an error downloading the receipt. Please try again.',
          variant: 'error',
        });
      });
  };
  return (
    <View className="mb-4 mt-5 flex w-full flex-col items-center gap-10 overflow-hidden rounded-xl lg:h-full">
      <div className="flex h-[408px] w-[300px]">
        <div ref={receiptRef} className="scale-receipt-xl  w-[300px]">
          {hasFrag ? (
            <Receipt receiptFrag={receiptFrag} />
          ) : isLoading ? (
            <></>
          ) : !receiptData ? (
            <></>
          ) : (
            <Receipt receiptFrag={receiptData} />
          )}
        </div>
      </div>

      <div className="mt-auto flex">
        <button
          aria-label="Download receipt"
          className="flex aspect-square h-11 w-11 cursor-pointer items-center justify-center rounded-lg border-none bg-base600 outline-none"
          onClick={htmlToImageConvert}
          disabled={!hasFrag && (!receiptData || isLoading)}
        >
          <FontAwesomeIcon icon={faArrowDownToBracket} className="text-[20px] text-vault_text" />
        </button>
      </div>
    </View>
  );
};

export { ReceiptBottomSheet };
