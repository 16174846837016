import type { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faChevronRight } from '@soundxyz/font-awesome/pro-solid-svg-icons';

export const NotificationRow = ({
  icon,
  label,
  value,
  onClick,
  href,
}: {
  icon: IconProp;
  label: string;
  value?: string;
} & (
  | {
      onClick: () => void;
      href?: never;
    }
  | {
      onClick?: never;
      href: string;
    }
)) => {
  const containerClassname =
    'flex cursor-pointer appearance-none items-center justify-between gap-2 border-none bg-base800 px-4 py-5 transition-all duration-200 ease-in-out hover:bg-base800/80 focus:outline-none focus:ring-2 focus:ring-base800/80 no-underline';

  const content = (
    <div className="flex w-full items-center justify-between">
      <div className="flex items-center gap-2.5">
        <FontAwesomeIcon icon={icon} className="h-5 w-5 text-white" />
        <span className="font-title text-title-s font-normal text-white">{label}</span>
      </div>
      <div className="flex items-center gap-2">
        {!!value && (
          <span className="font-base text-base-l font-normal text-white/40">{value}</span>
        )}
        <FontAwesomeIcon
          icon={faChevronRight}
          className="h-3.5 w-3.5 text-white/40"
          fontSize={14}
        />
      </div>
    </div>
  );

  if (href) {
    return (
      <Link to={href} className="no-underline" aria-label={`View notification for ${label}`}>
        <div className={containerClassname}>{content}</div>
      </Link>
    );
  }
  return (
    <button
      className={twMerge(containerClassname, 'w-full')}
      onClick={onClick}
      aria-label={`View notification for ${label}`}
    >
      {content}
    </button>
  );
};
