import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { type IconDefinition } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { View } from '../../common/View';

export const CarouselItem = ({ icon }: { icon: IconDefinition }) => (
  <div className="relative  box-border flex aspect-[280/378] h-full max-h-[378px] w-full max-w-[280px] flex-col items-center justify-center px-4 md2:h-[425px] md2:max-h-[425px] md2:w-[300px] md2:max-w-[300px]">
    <View className="flex aspect-[260/378] h-full items-center justify-center rounded-lg bg-white/5">
      <FontAwesomeIcon icon={icon} className="text-[88px]" />
    </View>
  </div>
);
