import { useMemo } from 'react';
import { captureException, captureMessage } from '@sentry/react';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { faApple, faSpotify } from '@soundxyz/font-awesome/free-brands-svg-icons';
import { useStableCallback } from '@soundxyz/graphql-react-query/utils';
import { uuidv4 } from '@soundxyz/utils';
import { useAuthContext } from '../../../contexts/AuthContext';
import { useToast } from '../../../contexts/ToastContext';
import { useMutation } from '../../../graphql/client';
import {
  type EarnedReceiptViewFragment,
  type MembershipReceiptFragment,
  type MutationReportPlayStreamReleaseCampaignInput,
  ReleaseCampaignReportPlayStreamDocument,
  ThirdPartyPlatform,
} from '../../../graphql/generated';
import { useAppleMusicAuth } from '../../../hooks/appleMusic/useAppleMusicAuth';
import { usePlayAppleMusic } from '../../../hooks/appleMusic/usePlayAppleMusic';
import { useSpotifyAuth } from '../../../hooks/spotify/useSpotifyAuth';
import { useBetterGate } from '../../../hooks/useFeatureGate';
import { EVENTS } from '../../../types/eventTypes';
import {
  createSpotifyUrl,
  extractAppleMusicResourceId,
  extractSpotifyResourceIdFromUrl,
} from '../../../utils/linkUtils';
import { artistNavigationPath } from '../../../utils/navigationUtils';
import { ArtistProfileImage } from '../../artist/ArtistProfileImage';
import { Button } from '../../buttons/Button';
import { Image } from '../../common/Image';
import { Text } from '../../common/Text';
import { View } from '../../common/View';
import { UserPlaceholderImage } from '../../user/UserPlaceholderImage';

export function PlayView({
  campaign,
  setReceiptData,
  setSource,
}: {
  campaign: EarnedReceiptViewFragment;
  setReceiptData: React.Dispatch<React.SetStateAction<MembershipReceiptFragment | null>>;
  setSource: React.Dispatch<React.SetStateAction<'apple' | 'spotify' | null>>;
}) {
  const [_searchParams, setSearchParams] = useSearchParams();
  const { loggedInUser } = useAuthContext();
  const { openToast } = useToast();

  const spotifyConnectEnabled = useBetterGate('SPOTIFY_CONNECT') === 'enabled';
  const appleMusicConnectEnabled = useBetterGate('VAULT_APPLE_MUSIC_CONNECT') === 'enabled';

  const spotifyAuth = useSpotifyAuth({ enabled: spotifyConnectEnabled });

  const { mutateAsync: reportPlayStream, isLoading } = useMutation(
    ReleaseCampaignReportPlayStreamDocument,
    {
      retry: 3,
    },
  );

  const isSpotifyAccountConnected =
    !!loggedInUser?.spotifyAuthConnection?.spotifyUserId ||
    spotifyAuth.type === 'already-connected';

  const appleMusicAuth = useAppleMusicAuth({
    enabled: appleMusicConnectEnabled,
  });

  const isAppleMusicAccountConnected =
    !!loggedInUser?.appleMusicAuthConnections.length ||
    appleMusicAuth.type === 'apple-music-already-linked' ||
    appleMusicAuth.type === 'apple-music-connected-without-user';

  const randomUUID = useMemo(() => uuidv4(), []);

  const artistLinkValue = campaign.artist.linkValue;
  const artistName = campaign.artist.name || campaign.artist.linkValue;
  const artistImageUrl = campaign.artist.profileImage?.artistSmallProfileImageUrl;
  const coverImageUrl =
    campaign.coverImage?.campaignCoverImageUrl || campaign.initialReleaseImageUrl;
  const title = campaign.title;

  const spotifyLink = campaign.externalLinks.find(
    link => link.platform === ThirdPartyPlatform.Spotify,
  )?.url;

  const appleMusicLink = campaign.externalLinks.find(
    link => link.platform === ThirdPartyPlatform.AppleMusic,
  )?.url;

  const spotifyResourceId =
    campaign.spotifyResourceId ??
    (spotifyLink != null ? extractSpotifyResourceIdFromUrl(spotifyLink) : null);

  const appleMusicResourceId =
    campaign.appleMusicResourceId ??
    (appleMusicLink != null ? extractAppleMusicResourceId(appleMusicLink) : null);

  const contentType = campaign.contentType;

  const spotifyUrl =
    spotifyResourceId != null
      ? createSpotifyUrl({ id: spotifyResourceId, type: contentType })
      : null;

  const {
    onClick: onClickAppleMusicPlay,
    isDisabled: isAppleMusicDisabled,
    isLoading: isAppleMusicLoading,
  } = usePlayAppleMusic({
    resourceId: appleMusicResourceId,
    resourceType: campaign.contentType,
    campaignId: campaign.id,
  });

  const onCampaignPlay = useStableCallback(async (source: 'spotify' | 'apple') => {
    const input: MutationReportPlayStreamReleaseCampaignInput = {
      releaseCampaignId: campaign.id,
    };
    setSource(source);

    if (source === 'spotify') {
      if (
        (!loggedInUser?.spotifyAuthConnection?.spotifyUserId && !spotifyAuth.authCode) ||
        spotifyUrl == null
      ) {
        openToast({
          text: 'Spotify authentication or link value is missing',
          variant: 'error',
        });
        return;
      }

      input.spotifyAuthCode = spotifyAuth.authCode;
      window.open(spotifyUrl, '_blank');
    } else if (source === 'apple') {
      if (loggedInUser?.appleMusicAuthConnections.length == 0 && !appleMusicAuth?.userToken) {
        openToast({
          text: 'Apple music user token is missing',
          variant: 'error',
        });
        return;
      }
      input.appleMusicAuthUserToken = appleMusicAuth.userToken;
      onClickAppleMusicPlay();
    }

    try {
      const { data } = await reportPlayStream({ input });
      if (
        data.reportPlayStreamReleaseCampaign?.__typename ===
          'MutationReportPlayStreamReleaseCampaignSuccess' ||
        data.reportPlayStreamReleaseCampaign === null
      ) {
        setReceiptData(
          data.reportPlayStreamReleaseCampaign?.data as MembershipReceiptFragment | null,
        );
        setSearchParams({ step: 'claim' });
      }

      if (data.reportPlayStreamReleaseCampaign?.__typename === 'NotAuthorizedError') {
        openToast({
          text: data.reportPlayStreamReleaseCampaign.message,
          variant: 'error',
        });
        captureMessage('Release Campaign Report Play Stream - Unauthorized', {
          level: 'error',
        });
      }

      if (data.reportPlayStreamReleaseCampaign?.__typename === 'NotFoundError') {
        openToast({
          text: data.reportPlayStreamReleaseCampaign.message,
          variant: 'error',
        });
        captureMessage('Release Campaign Report Play Stream - Not Found', {
          level: 'error',
        });
      }
    } catch (error) {
      captureException(error, {
        tags: { feature: 'Report Play Release Campaign', campaignId: campaign.id },
      });
      openToast({
        text: 'There was an error reporting play stream',
        variant: 'error',
      });
    }
  });

  // Fix and add back in when we actually do spotify streaming
  //   useEffect(() => {
  //   if (isSpotifyAccountConnected && !isAppleMusicAccountConnected && !isPresave) {
  //     openBottomsheet({
  //       type: BOTTOMSHEET_TYPES.CUSTOM,
  //       shared: {
  //         hideCloseBottomsheetButton: true,
  //         preventOutsideAutoClose: true,
  //         preventSwipeToDismiss: true,
  //       },
  //       customBottomsheetProps: {
  //         body: (
  //           <View className="mx-4 flex flex-col gap-4 text-center">
  //             <Text className="font-title text-title-l">
  //               Sorry, you must have a Spotify Premium account
  //             </Text>

  //             <Text className="text-base-l text-base400">
  //               We only support Spotify Premium accounts. You can still stream on your favorite
  //               platform without connecting.
  //             </Text>
  //             <Button
  //               className="mt-8"
  //               type="primary"
  //               label="Ok"
  //               onClick={() => {
  //                 closeBottomsheet();
  //                 const pathParts = location.pathname.split('/');
  //                 const newPath = pathParts.slice(0, pathParts.indexOf('s') + 2).join('/');
  //                 navigate(`${newPath}?showConnectionModal=true`);
  //               }}
  //             />
  //           </View>
  //         ),
  //       },
  //     });
  //   }
  // }, [
  //   isSpotifyAccountConnected,
  //   isAppleMusicAccountConnected,
  //   openBottomsheet,
  //   closeBottomsheet,
  //   openToast,
  //   spotifyAuth,
  //   navigate,
  //   location,
  //   isPresave,
  // ]);

  return (
    <View className="z-above1 flex h-full flex-col items-center justify-center gap-6 md2:w-2/3 md2:min-w-[420px] md2:max-w-[420px]">
      <View className="relative mx-auto flex w-full flex-shrink-0 flex-col items-center justify-center overflow-hidden">
        {coverImageUrl != null ? (
          <Image
            src={coverImageUrl}
            alt="Track/Album Cover"
            className="aspect-square w-[140px] rounded-xl object-cover md2:w-[240px]"
          />
        ) : (
          <UserPlaceholderImage
            id={randomUUID}
            className="aspect-square w-[140px] rounded-xl md2:w-[240px]"
            svgClassName="rounded-xl"
          />
        )}
      </View>

      <View className="z-above1 flex w-full flex-col items-center">
        <Text className="box-border line-clamp-2 w-full px-6 text-center text-[28px]/[34px] font-semibold text-white md2:px-0 md2:text-[40px]/[46px]">
          {title}
        </Text>

        <Link
          className="mt-3 box-border flex w-full items-center justify-center space-x-2 px-6 no-underline"
          to={artistNavigationPath(artistLinkValue, '/')}
        >
          <ArtistProfileImage
            className="h-6 w-6 md2:h-8 md2:w-8"
            profileImageUrl={artistImageUrl}
            withVaultTheme
          />

          <Text
            className={twMerge(
              'line-clamp-1 text-[14px]/[18px] text-white',
              'md2:text-[16px]/[20px] md2:font-medium',
            )}
          >
            {artistName}
          </Text>
        </Link>
      </View>

      <View className="z-above1 flex w-full flex-col items-center gap-4 px-6 md2:px-0">
        {isSpotifyAccountConnected && spotifyResourceId != null && (
          <Button
            label="Play"
            type="primary"
            leadingIcon={faSpotify}
            loading={isLoading}
            onClick={() => onCampaignPlay('spotify')}
            disabledClassName="opacity-40"
            event={{ type: EVENTS.SPOTIFY_PLAY, properties: { campaignId: campaign.id } }}
          />
        )}

        {isAppleMusicAccountConnected && appleMusicResourceId != null && (
          <Button
            label="Play"
            type="primary"
            leadingIcon={faApple}
            loading={isLoading || isAppleMusicLoading}
            onClick={() => onCampaignPlay('apple')}
            disabled={isAppleMusicDisabled}
            disabledClassName="opacity-40"
            event={
              isAppleMusicDisabled
                ? undefined
                : { type: EVENTS.APPLE_PLAY, properties: { campaignId: campaign.id } }
            }
          />
        )}
      </View>
    </View>
  );
}
