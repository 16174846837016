import { type Maybe, uuidv4 } from '@soundxyz/utils';
import {
  CHAT_ERROR_ACTIONS,
  CHAT_INFO_ACTIONS,
  ERROR_TYPE,
  PILLARS,
} from '@soundxyz/vault-logs-utils/constants';
import { useAuthContext } from '../contexts/AuthContext';
import { useToast } from '../contexts/ToastContext';
import { GraphQLReactQuery, useMutation } from '../graphql/client';
import type { TierTypename } from '../graphql/generated';
import {
  GetBanStatusDocument,
  type GifAttachmentInput,
  type MediaType,
  MessageSource,
  type ReplyToMessageFragment,
  SendMessageDocument,
} from '../graphql/generated';
import { AttachTrackContentTitles } from '../store/trackTitles';
import { removeSearchParam } from '../utils/pathUtils';
import { logError } from './logger/useLogError';
import { logInfo } from './logger/useLogInfo';
import { NewMessagePersistence } from './useNewMessage';
import { useVaultMessageChannel } from './useVaultMessageChannel';

const pillar = PILLARS.CHAT;

export function useSendMessage({
  asArtist,
  messageChannelId,
  activeSubscriptionTier,
}: {
  asArtist: {
    id: string;
    linkValue: string;
    name: string;
    profileImage: { id: string; artistSmallProfileImageUrl: string | null };
  } | null;
  messageChannelId?: string;
  activeSubscriptionTier: TierTypename | null;
}) {
  const { openToast, closeToast } = useToast();
  const { loggedInUser } = useAuthContext();
  const { newMessage } = useVaultMessageChannel();

  const { mutateAsync, isLoading } = useMutation(SendMessageDocument, {
    onError: (_err, { input: { content, vaultContentId } }) => {
      logError({
        action: CHAT_ERROR_ACTIONS.CHAT_SEND_ERROR,
        error: _err,
        level: 'error',
        message: 'Error sending message',
        errorType: ERROR_TYPE.UNKNOWN,
        pillar,
        indexedTags: {
          userId: loggedInUser?.id,
          source: 'useSendMessage',
          messageChannelId,
        },
        openToast,
        toast: {
          variant: 'error',
          text: 'Your message could not be sent.',
          ctaText: 'Try again',
          ctaOnClick: () =>
            sendMessage({
              content,
              vaultContent: vaultContentId
                ? [
                    {
                      id: vaultContentId,
                      title: AttachTrackContentTitles.trackTitlesById[vaultContentId] || null,
                    },
                  ]
                : null,
              activeSubscriptionTier,
            }),
          className: 'bg-destructive700',
        },
      });
    },
    retry: 3,
  });

  const sendMessage = async ({
    content,
    activeSubscriptionTier,
    vaultContent,
    replyToMessage,
    messageAttachments,
    gifAttachment,
  }: {
    content: string;
    activeSubscriptionTier: TierTypename | null;
    vaultContent: Maybe<{ id: string; title: Maybe<string> }[]>;
    replyToMessage?: ReplyToMessageFragment | null | undefined;
    messageAttachments?: Maybe<{ media: { id: string; mediaType: MediaType; url: string } }[]>;
    gifAttachment?: Maybe<GifAttachmentInput>;
  }) => {
    if (!loggedInUser) {
      logError({
        action: CHAT_ERROR_ACTIONS.CHAT_SEND_ERROR,
        error: new Error('User is not logged in'),
        level: 'warning',
        message: 'User is not logged in',
        errorType: ERROR_TYPE.AUTH_ERROR,
        pillar,
        indexedTags: {
          source: 'useSendMessage',
          messageChannelId,
        },
      });
      return;
    }
    closeToast();

    const id = uuidv4();
    const transformedReplyToMessage = replyToMessage
      ? ({
          ...replyToMessage,
          asArtist:
            replyToMessage.creator.__typename === 'MessageActorArtist'
              ? {
                  id: replyToMessage.creator.artist.id,
                  linkValue: replyToMessage.creator.artist.linkValue,
                  name: replyToMessage.creator.artist.name,
                  profileImage: {
                    id: replyToMessage.creator.artist.profileImage?.id ?? '',
                    artistSmallProfileImageUrl:
                      replyToMessage.creator.artist.profileImage?.artistSmallProfileImageUrl ??
                      null,
                  },
                }
              : null,
          creatorUser:
            replyToMessage.creator.__typename === 'MessageActorUser'
              ? {
                  ...replyToMessage.creator.user,
                  avatar: {
                    ...replyToMessage.creator.user.avatar,
                    url: replyToMessage.creator.user.avatar.cdnUrl,
                  },
                }
              : null,
          vaultContent: replyToMessage.vaultContent.map(vaultContentItem => ({
            id: vaultContentItem.id,
            vaultContent: vaultContentItem
              ? {
                  id: vaultContentItem.id,
                }
              : null,
          })),
        } satisfies NonNullable<Parameters<typeof newMessage>[0]['replyTo']>)
      : null;

    type MessageAttachments = Parameters<typeof newMessage>[0]['messageAttachments'];

    const mappedAttachments: MessageAttachments = [
      ...(messageAttachments?.map(media => ({
        id: uuidv4(),
        media: {
          id: media.media.id,
          mediaType: media.media.mediaType,
          cdnUrl: media.media.url,
          fullImageUrl: null,
          mediumImageUrl: null,
        } satisfies MessageAttachments[number]['media'],
        gif: null,
      })) ?? []),
      ...(gifAttachment
        ? [
            {
              id: uuidv4(),
              media: null,
              gif: {
                id: gifAttachment.id,
                url: gifAttachment.url,
                title: gifAttachment.title,
                aspectRatio: gifAttachment.aspectRatio,
              },
            },
          ]
        : []),
    ];

    const { revert, onMutationSuccess } = newMessage({
      content: content.trim(),
      createdAt: new Date().toISOString(),
      id,
      creatorUser: {
        ...loggedInUser,
        avatar: {
          ...loggedInUser.avatar,
          url: loggedInUser.avatar.cdnUrl,
        },
      },
      vaultContent: vaultContent
        ? vaultContent.map(content => {
            return {
              id: uuidv4(),
              vaultContent: {
                id: content.id,
                title: content.title ?? null,
              },
            };
          })
        : [],
      isOptimistic: true,
      replyTo: transformedReplyToMessage,
      messageAttachments: mappedAttachments,
      asArtist: asArtist
        ? {
            id: asArtist.id,
            linkValue: asArtist.linkValue,
            name: asArtist.name,
            profileImage: {
              id: asArtist.profileImage?.id ?? '',
              artistSmallProfileImageUrl: asArtist.profileImage?.artistSmallProfileImageUrl ?? null,
            },
          }
        : null,
      source: MessageSource.VaultChat,
      activeSubscriptionTier,
    });

    try {
      const resp = await mutateAsync({
        input: {
          optimisticId: id,
          content: content.trim(),
          messageChannelId: messageChannelId ?? '',
          vaultContentId: vaultContent?.[0]?.id ?? null,
          replyToId: replyToMessage?.id,
          messageAttachments: messageAttachments?.length
            ? messageAttachments.map(({ media }) => media.id)
            : null,
          gifAttachment,
          asArtistId: asArtist?.id,
        },
        asArtistId: asArtist?.id,
      });

      if (resp.data.createMessage.__typename !== 'MutationCreateMessageSuccess') {
        const errType =
          resp.data.createMessage.__typename === 'NotAuthorizedError'
            ? ERROR_TYPE.AUTH_ERROR
            : resp.data.createMessage.__typename === 'ValidationError'
              ? ERROR_TYPE.VALIDATION_ERROR
              : resp.data.createMessage.__typename === 'RateLimitError'
                ? ERROR_TYPE.RATE_LIMIT_ERROR
                : ERROR_TYPE.UNKNOWN;

        logError({
          action: CHAT_ERROR_ACTIONS.CHAT_SEND_ERROR,
          error: new Error(`Message creation failed: ${resp.data.createMessage.__typename}`),
          level: 'error',
          message: 'Message creation failed',
          errorType: errType,
          pillar,
          indexedTags: {
            source: 'useSendMessage',
            messageChannelId,
            artistId: asArtist?.id,
            userId: loggedInUser.id,
          },
          unindexedExtra: {
            content: content.trim(),
            vaultContent,
          },
          openToast,
          toast: {
            text: (
              <p>
                Your message could not be sent.{' '}
                <span
                  className="cursor-pointer text-base-m font-semibold underline"
                  onClick={() => sendMessage({ content, vaultContent, activeSubscriptionTier })}
                >
                  Try again.
                </span>
              </p>
            ),
            variant: 'error',
          },
        });

        revert();
      } else {
        logInfo({
          action: CHAT_INFO_ACTIONS.CHAT_SEND,
          message: 'Message sent successfully',
          pillar,
          data: {
            messageId: id,
            messageChannelId,
            userId: loggedInUser.id,
            artistId: asArtist?.id,
          },
        });
        onMutationSuccess?.();

        // Clear new message state
        NewMessagePersistence.clear();
        removeSearchParam('newMessage');
      }

      if (resp.data.createMessage.__typename === 'NotAuthorizedError') {
        GraphQLReactQuery.invalidateQueries([GetBanStatusDocument]);
      }
    } catch (error) {
      logError({
        action: CHAT_ERROR_ACTIONS.CHAT_SEND_ERROR,
        error,
        level: 'error',
        message: 'Message creation failed',
        errorType: ERROR_TYPE.UNKNOWN,
        pillar,
        indexedTags: {
          source: 'useSendMessage',
          messageChannelId,
          artistId: asArtist?.id,
          userId: loggedInUser.id,
        },
        unindexedExtra: {
          content: content.trim(),
          vaultContent,
        },
        openToast,
        toast: {
          text: (
            <p>
              Your message could not be sent.{' '}
              <span
                className="cursor-pointer text-base-m font-semibold underline"
                onClick={() => sendMessage({ content, vaultContent, activeSubscriptionTier })}
              >
                Try again.
              </span>
            </p>
          ),
          variant: 'error',
        },
      });

      throw error;
    }
  };

  return { sendMessage, isLoading };
}
