import { type ReactNode, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { faChevronDown } from '@soundxyz/font-awesome/pro-regular-svg-icons';
import { Text } from './Text';
import { View } from './View';

export function CollapsibleView({
  children,
  title,
  iconClassName,
  withVaultTheme = false,
}: {
  children: ReactNode;
  title: string;
  iconClassName?: string;
  withVaultTheme?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <View className="flex flex-col gap-y-7">
      <View
        className="flex flex-row justify-between"
        onClick={() => setIsOpen(_isOpen => !_isOpen)}
      >
        <Text
          className={twMerge(
            'font-title text-[16px]/[20px] font-medium text-white',
            withVaultTheme && 'text-vault_text',
          )}
        >
          {title}
        </Text>
        <motion.div animate={{ rotate: isOpen ? 180 : 0 }} transition={{ bounce: false }}>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={twMerge(
              'text-[16px] text-white',
              withVaultTheme && 'text-vault_text',
              iconClassName,
            )}
          />
        </motion.div>
      </View>
      <motion.div
        animate={{
          height: isOpen ? 'auto' : 0,
          opacity: isOpen ? 1 : 0,
          transform: isOpen ? 'translateY(12px)' : 'translateY(0)',
        }}
        transition={{ bounce: false }}
        className="-mt-3 overflow-hidden"
      >
        {children}
      </motion.div>
    </View>
  );
}
