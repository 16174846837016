import { useMemo } from 'react';
import { compact } from 'lodash-es';
import { HowItWorks, PhoneImageSlide } from '../event/HowItWorks';
import { CampaignType } from './schema';
import { useCampaignForm } from './useCampaignForm';

export const CampaignHowItWorksView = () => {
  const { fields } = useCampaignForm();

  const isPresave = fields.campaignType === CampaignType.Presave;

  const INTRO_CAROUSEL_DATA = useMemo(() => {
    return compact([
      {
        title: isPresave ? 'Pre-release page' : 'Release page',
        description: isPresave
          ? 'Create your pre-save page for fans to save your upcoming release to their library on Spotify and Apple Music.'
          : 'Create your release page for fans to stream your release.',
        component: 'how',
      },
      isPresave && {
        title: 'Notify fans',
        description: 'Notify your fans when the song comes out with your own custom message.',
        component: 'notify',
      },
      isPresave && {
        title: 'Release ready',
        description:
          'On release day, this converts into a release page for fans to go stream your music. You can customize which links appear here.',
        component: 'ready',
      },
      {
        title: 'Show me the receipts!',
        description:
          'Fans get a receipt when they pre-save or stream your release, so you can identify your most loyal and engaged fans.',
        component: 'receipt',
      },
    ]);
  }, [isPresave]);

  const renderCarouselContent = (component: string | undefined) => {
    if (!component) return null;
    switch (component) {
      case 'how':
        return (
          <PhoneImageSlide
            imageUri={
              isPresave
                ? 'https://d3jznoddta6qri.cloudfront.net/public/presave-how-it-works-1.png'
                : 'https://d3jznoddta6qri.cloudfront.net/public/streaming-how-it-works-1.png'
            }
            loading="eager"
          />
        );
      case 'notify':
        return (
          <PhoneImageSlide
            imageUri="https://d3jznoddta6qri.cloudfront.net/public/presave-how-it-works-2.png"
            loading="lazy"
          />
        );
      case 'ready':
        return (
          <PhoneImageSlide
            imageUri="https://d3jznoddta6qri.cloudfront.net/public/presave-how-it-works-3.png"
            loading="lazy"
          />
        );
      case 'receipt':
        return (
          <PhoneImageSlide
            imageUri={
              isPresave
                ? 'https://d3jznoddta6qri.cloudfront.net/public/presave-how-it-works-4.png'
                : 'https://d3jznoddta6qri.cloudfront.net/public/streaming-how-it-works-2.png'
            }
            loading="lazy"
          />
        );
      default:
        return null;
    }
  };

  return (
    <HowItWorks carouselData={INTRO_CAROUSEL_DATA} renderCarouselContent={renderCarouselContent} />
  );
};
